import { FaCaretDown, FaBell } from 'react-icons/fa';
import { useState } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { HiInformationCircle } from 'react-icons/hi'
import { connect, useDispatch } from 'react-redux';
import './TopBar.scss'
import avatar from '../../../images/avatar.jpg'

const TopBar = ( { tour } ) =>
{
  const { user } = useAuth()
  const userImage = user.photoURL

  const [ isProfileOpen, setIsProfileOpen ] = useState( false );
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()

  const navTo = ( path ) =>
  {
    setIsProfileOpen( false );
    history.push( path );
  };

  let pageTitle = location.pathname.split( "/" )[ 1 ].replace( '-', " " ).toUpperCase();

  const createProject = () =>
  {
    history.push( "/create-project" );
  }

  const tourHandler = () =>
  {
    dispatch( { type: "TOUR_UPDATE", payload: { tour: "true" } } )
  }


  return (
    <div className='topbar-wrapper'>
      <div className='page-title'>
        <p>{ pageTitle } <HiInformationCircle style={ { cursor: "pointer" } } onClick={ tourHandler } /></p>
      </div>
      <div className='profile-action'>
        <button id='xarwinit' onClick={ () => createProject() }>Xarwin It</button>
        <div className='userInfo'>
          <>
            <div onClick={ () => setIsProfileOpen( !isProfileOpen ) }>
              <img src={ avatar } alt='user' />
            </div>
            <p onClick={ () => setIsProfileOpen( !isProfileOpen ) }>
              { user?.displayName }
            </p>
            <FaCaretDown onClick={ () => setIsProfileOpen( !isProfileOpen ) } />
          </>
          { isProfileOpen && (
            <div className='profile-menu'>
              <p onClick={ () => navTo( '/profile' ) }>Edit Profile</p>
              <p onClick={ () => navTo( '/my-subscription' ) }>My Subscription</p>
              <p onClick={ () => navTo( '/plans' ) }>Subscription Plans</p>
              {/* <p onClick={ () => navTo( '/terms-conditions' ) }>
                Terms &amp; Conditions
              </p>
              <p onClick={ () => navTo( '/privacy-policy' ) }>Privacy Policy</p> */}
            </div>
          ) }
          {/* <FaBell onClick={ () => history.push( '/notifications' ) } /> */ }
        </div>
      </div>
    </div>
  );
};

const mapPropsToState = ( state ) =>
{
  return {
    tour: state?.tour?.tour
  }
}

export default connect( mapPropsToState, {} )( ( withRouter( TopBar ) ) );
