/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */

export default ( dc, config ) =>
{
    const defaultType = dc.getType( "default" );
    const {models} = config;
    const components = []
    models?.map((model)=>{
        if(model["Colors"]!==undefined&&model["Colors"]!==null){
            console.log(model["Colors"])
            model["Colors"].map((colorKey)=>{
                components.push({ type: 'option', content: colorKey,
                attributes: { value: colorKey, style: `background-color: ${colorKey};color: ${colorKey}` }, })
            })
            
        }
    })
    
    
    dc.addType('color-select', {
        model: {
            defaults: {
                name: "color-select",
                tagName: 'select',
                droppable: false,
                highlightable: false,
                components: [],
                traits: [{
                    name: 'Model',
                    type: 'color-select',
                    originalTab: 'Colors' // Add this custom attribute
                },],
                script:  function (){
                    // `this` is bound to the component element
                    console.log('the element', this);
                    function hexToRgb(hex) {
                        // Remove the # symbol if it's included
                        hex = hex.replace(/^#/, '');
                      
                        // Parse the hex string into three separate values (r, g, b)
                        const r = parseInt(hex.slice(0, 2), 16) / 255;
                        const g = parseInt(hex.slice(2, 4), 16) / 255;
                        const b = parseInt(hex.slice(4, 6), 16) / 255;
                      
                        // Return the RGB values as an object with values between 0 and 1
                        return { r, g, b };
                      }
                    this.addEventListener('change',  (event)=>{
                        const selectedValue = event.target.value
                        console.log(document)
                        const threedmodels = Array.from(document.getElementsByClassName( "3dmodel" ))
                        if ( threedmodels.length > 0 ){
                            console.log(threedmodels)
                            for ( var i=0;i<threedmodels.length;i++){
                                const threedmodel  = threedmodels[i]
                                console.log(threedmodel)
                                const modelMaterial = threedmodel?.model?.materials[ 0 ]
                                console.log(modelMaterial)
                                if ( modelMaterial !== undefined && modelMaterial !== null ){
                                    const colorString = hexToRgb(selectedValue)

                                    modelMaterial.pbrMetallicRoughness.setBaseColorFactor([colorString["r"],colorString["g"],colorString["b"]])
                                }
                            }
                        }
                    })
                }
            },
        },
    });
    ;
};
