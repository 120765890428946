import { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import TemplateCard from '../../../Components/Templates/TemplateCard';
import { useHistory, withRouter } from 'react-router-dom';
import { getAllTemplates, deleteTemplate } from '../../../../actions/Templates';
import { connect } from 'react-redux';
import './Templates.css'

const Templates = ( { allTemplates, getAllTemplates, deleteTemplate } ) =>
{
	const [ templates, setTemplates ] = useState( [] );
	const [ currentId, setCurrentId ] = useState( null );
	const [ isPopupOpen, setPopupOpen ] = useState( false );
	const history = useHistory();

	useEffect( () =>
	{
		getAllTemplates()
	}, [] )

	useEffect( () =>
	{
		setTemplates( allTemplates?.reverse() )
	}, [ allTemplates ] )


	const onDelete = ( id ) =>
	{
		setCurrentId( id );
		setPopupOpen( true );
	};
	const deleteConfirm = () =>
	{
		console.log( currentId )
		setPopupOpen( false );
		deleteTemplate( currentId, result =>
		{
			const { status } = result
			console.log( status )
			if ( status === true )
			{
				const remaining = templates.filter( template => template._id !== currentId )
				setTemplates( remaining )
			}
		} )
	};

	const uploadHandler = () =>
	{
		history.push( '/templates/create' )
	}

	return (
		<>
			{
				templates?.length > 0 ? <div className='templates-container'>
					<div className='row gx-5 gy-2'>
						{ templates.map( ( template, index ) => (
							<div className='col-md-6 col-lg-4' key={ index }>
								<TemplateCard
									key={ template._id }
									template={ template }
									onDelete={ onDelete }
									userId={ template.User_Id }
								/>
							</div>
						) ) }
					</div>
					{ isPopupOpen && <div className='delete-popup'>
						<div className='template-delete-container'>
							<FaTimes onClick={ () => setPopupOpen( false ) } />
							<div>
								<p>Are you sure you want to delete ?</p>
								<div>
									<button onClick={ () => deleteConfirm() }>Yes</button>
									<button onClick={ () => setPopupOpen( false ) }>No</button>
								</div>
							</div>
						</div>
					</div> }
				</div> : <div className='upload_template'>
					<button className='btn btn-lg' onClick={ uploadHandler }>Create Template</button>
				</div>
			}
		</>
	);
};

const mapPropsToState = ( state ) =>
{
	return {
		allTemplates: state?.template?.templates,
		templatesLoader: state?.template?.templatesLoader,
	}
}

export default connect( mapPropsToState, { getAllTemplates, deleteTemplate } )( ( withRouter( Templates ) ) );


