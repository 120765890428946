import axios from "axios";
import Swal from "sweetalert2";
import config from "./config";

const urlEncode = function ( data )
{
	var str = [];
	for ( var p in data )
	{
		if ( data.hasOwnProperty( p ) && ( !( data[ p ] == undefined || data[ p ] == null ) ) )
		{
			str.push( encodeURIComponent( p ) + "=" + ( data[ p ] ? encodeURIComponent( data[ p ] ) : "" ) );
		}
	}
	return str.join( "&" );
}


export const getAllPlans = () => async ( dispatch, getState ) =>
{
	dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
	const user = getState()?.user?.userData;
	// const token = await user?.getIdToken()
	console.log( user )
	axios.get( `${config.serverUrl}/users/plans/getAll`, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const plans = res.data.plans
		dispatch( { type: "SUBSCRIPTION_PLAN_UPDATE", payload: { plans: plans } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
		} )
}

export const getPlanById = ( planId ) => async ( dispatch, getState ) =>
{
	dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
	const user = getState()?.user?.userData;
	// const token = await user?.getIdToken()
	console.log( user )
	axios.get( `${config.serverUrl}/users/plans/${planId}`, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const planDetail = res.data.plan
		dispatch( { type: "SUBSCRIPTION_PLAN_DETAIL", payload: { planDetail: planDetail } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
		} )
}

export const subscribePlan = ( subscribe, history ) => async ( dispatch, getState ) =>
{
	dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
	const user = getState()?.user?.userData;
	// const token = await user?.getIdToken()
	console.log( user )
	subscribe[ "userId" ] = user.uid
	subscribe[ "name" ] = user.displayName
	subscribe[ "email" ] = user.email
	axios.post( `${config.serverUrl}/users/plans/subscribe`, subscribe, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
		history.push( "/dashboard" )

	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
		} )
}

export const getSubscriptionById = () => async ( dispatch, getState ) =>
{
	dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
	const user = getState()?.user?.userData;
	// const token = await user?.getIdToken()
	axios.get( `${config.serverUrl}/users/subscription/${user.uid}`, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const currentSubscription = res.data.subscription
		dispatch( { type: "CURRENT_SUBSCRIPTION_PLAN", payload: { currentSubscription: currentSubscription } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err.response )
			Swal.fire( { title: err?.response?.data?.message } )
			dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
		} )
}

export const getHostedPage = ( subscribe ) => async ( dispatch, getState ) =>
{
	axios.defaults.headers.post[ 'Content-Type' ] = 'application/x-www-form-urlencoded';

	dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
	const user = getState()?.user?.userData;
	// const token = await user?.getIdToken()
	return axios.post( `${config.serverUrl}/users/subscription/checkout`, urlEncode( {
		userId: user.uid,
		email: user.email,
		...subscribe,
	} ), {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} )
		.then( ( response ) =>
		{
			console.log( response.data )
			dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
			return response.data
		} )
		.catch( ( error ) =>
		{
			console.log( error )
			dispatch( { type: "SUBSCRIPTION_PLAN_LOADER" } )
			return null
		} )

}

export const updateSubscriptionDetails = ( hostedPageId, itemId, history ) => async ( dispatch, getState ) =>
{
	axios.defaults.headers.post[ 'Content-Type' ] = 'application/x-www-form-urlencoded';
	const user = getState()?.user?.userData;
	// const token = await user?.getIdToken()
	axios.post( `${config.serverUrl}/users/subscription/checkout/details`, urlEncode( { hostedId: hostedPageId, itemId: itemId } ), {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( response ) =>
	{
		console.log( response )
		Swal.fire( { title: "Plan Subscribed Successfully!" } )
		history.push( "/dashboard" )
	} ).catch( ( err ) =>
	{
		console.log( err )
	} )
}