import { useHistory } from 'react-router-dom';
import './Model.css'

const Model = ( { model, onDelete } ) =>
{
  const { _id, User_Id, Model_Name, Description, Format_Source } = model;
  const history = useHistory()
  const createProjectHandler = () =>
  {
    history.push( {
      pathname: '/create-project',
      selectedModel: model
    } )
  }
  return (
    <div className="card" >
      <div className="model-viewer">
        <model-viewer
          poster="https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/Xarwin-Preloader.gif"
          src={ Format_Source[ "GLTF" ] } ar
          camera-controls auto-rotate autoplay
          alt="A 3D model carousel"
        >
        </model-viewer>
      </div>
      <div className="card-body">
        <h5 className="card-title">{ Model_Name }</h5>
        <p className="card-text">{ Description }</p>
        <div className='actions'>
          <button className='btn' onClick={ createProjectHandler }>Start Project</button>
          { User_Id !== "default" && <button className='btn' onClick={ () => onDelete( _id ) }> Delete</button> }
          <button className='btn' onClick={ () => history.push( `/models/${_id}` ) }>View</button>
        </div>
      </div>
    </div >
  );
};
{/* <div className=''>
      <div className='model-headings'>
        <div className='title'>
          <h3>{ Model_Name }</h3>
          <p>{ Description }</p>
        </div>
        <div className='actions'>
          <button onClick={ () => history.push( `/models/${_id}` ) }>View</button>
        </div>
      </div>
      <div>
        <button onClick={ createProjectHandler }>Start Project</button>
        { User_Id !== "default" && <button onClick={ () => onDelete( _id ) }> Delete</button> }
      </div>
    </div> */}
export default Model;
