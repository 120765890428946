import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './TemplateCard.css'

const TemplateCard = ( { template, userId, onDelete } ) =>
{
	const history = useHistory()

	const onEdit = () =>
	{
		history.push( `/templates/editor/${template._id}` );
	};

	const onUseTemplateHandler = () =>
	{
		history.push( {
			pathname: '/create-project',
			selectedTemplate: template
		} )
	}

	const onViewTemplateHandler = () =>
	{
		history.push( `templates/${template._id}` )
	}

	return (
		<div className='template-card-wrapper'>
			<h1>{ template.Name }</h1>
			<br />
			<p>{ template.Category }</p>
			<div>
				<button onClick={ () => onUseTemplateHandler() }>Use</button>
				<button onClick={ () => onViewTemplateHandler() }>View</button>
				{ userId !== 'default' &&
					<button onClick={ () => onEdit() }>Edit</button> }
				{ userId !== 'default' && <button onClick={ () => onDelete( template._id ) }> Delete</button> }
			</div>
		</div>
	);
};

export default TemplateCard;
