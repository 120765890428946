import Tabs from './Tabs';
import TabContent from './TabContent';
import TabContents from './TabContents';
import TabContainer from './TabContainer';
import TextureSelect from './TextureSelect';
import ColorSelect from './ColorSelect';
import ColorTab from './ColorTab';
import TextureTab from './TextureTab';

export default (editor, config = {}) => {
  console.log(config)
  const dc = editor.DomComponents;
  const opts = {
    ...config,
    defaultModel: dc.getType('default').model,
    editor,
  };

  [
    Tabs,
    TabContent,
    TabContents,
    TabContainer,
    TextureSelect,
    ColorSelect,
    ColorTab,
    TextureTab,
  ].map(c => c(dc, opts));
}
