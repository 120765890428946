import logo from "../../../images/newXarwinLogo.png";
import {IoIosLogOut} from 'react-icons/io'
import { AiFillFolderAdd } from "react-icons/ai";
import { TiHome, TiFlash } from "react-icons/ti";
import { RiBarChart2Fill,RiLayoutMasonryFill } from "react-icons/ri";
import { BsImageFill } from "react-icons/bs";
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import './Sidebar.scss'

const Sidebar = () =>
{
  const { logOut } = useAuth()
  const history = useHistory();
  const location = useLocation();
  // get a screen size and set false on mobile screen

  const [ isOpen, setIsOpen ] = useState( true );

  const logoutHandler = () =>
  {
    logOut( history )
  }

  return (
    <div className="sidebar-wrapper">
      <div className="top-holder">
        <img src={ logo } alt='Logo Xarwin' />
        <div className="mobile-menu" onClick={ () => setIsOpen( !isOpen ) }>
          <span />
          <span />
          <span />
        </div>
      </div>
      { isOpen && (
        <div className="menus">

          <div className="menu-item" onClick={ () => history.push( '/dashboard' ) }>
            <TiHome />
            Dashboard
          </div>


          <div className="menu-item" onClick={ () => history.push( '/projects' ) } >
            <TiFlash /> My Projects
          </div>


          <div className="menu-item" onClick={ () => history.push( '/image-tracking' ) } >
            <BsImageFill /> Image Tracking
          </div>

          <div className="menu-item" onClick={ () => history.push( '/models' ) } >
            <RiBarChart2Fill />
            My Models
          </div>


          <div className="menu-item" onClick={ () => history.push( '/add-model' ) } >
            <AiFillFolderAdd />
            Add Model
          </div>

          <div className="menu-item" onClick={ () => history.push( '/templates' ) } active>
            <RiLayoutMasonryFill />
            Templates
          </div>

          {/* <div className="menu-item" onClick={ () => history.push( '/settings' ) } >
            <TiCog />
            Settings
          </div> */}


          <div className="menu-item" onClick={ logoutHandler }>
            <IoIosLogOut />
            Logout
          </div>
        </div>
      ) }
    </div>
  );
};

export default Sidebar;
