import React from 'react'
import './Subscription.css'
import { getPlanById } from '../../../../actions/SubscriptionPlans'
import { useState, useEffect } from 'react'
import { BsCheck2Circle, BsXCircle } from 'react-icons/bs'
import Spinner from '../../../../Utilities/Spinner/Spinner'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useHistory, useParams } from 'react-router-dom'
import { getSubscriptionById } from '../../../../actions/SubscriptionPlans'


const CurrentSubscriptionPage = ( { currentSubscription, getSubscriptionById, plansLoader } ) =>
{
	const [ currentSubscriptionDetail, setcurrentSubscriptionDetail ] = useState( null )
	useEffect( () =>
	{
		getSubscriptionById()
	}, [] )

	useEffect( () =>
	{
		setcurrentSubscriptionDetail( currentSubscription )
	}, [ currentSubscription ] )


	return (
		<div>
			{
				plansLoader === false ? currentSubscriptionDetail != null ? <div className='subscription'>
					<div id="subscription_plan_header">
						<h5>Subscription Plan</h5>
					</div>
					<div className="subscription_plan_card">
						<div className="subscription_plan_title">
							<h5>{ currentSubscriptionDetail[ "Plan_Id" ][ "Name" ] }</h5>
						</div>
						<div className="details">
							<div id="all_plan_card">
								<div className="plan_details">
									<div className="plan_features">
										<ul>
											<li className="price">{ currentSubscriptionDetail[ "Period_Unit" ] == "year" ? currentSubscriptionDetail[ "Plan_Id" ][ "Year_Price" ] : currentSubscriptionDetail[ "Plan_Id" ][ "Month_Price" ] }$</li>
											<li>{ currentSubscriptionDetail[ "Plan_Id" ][ "Views" ] } Views</li>
											<hr />
											<li> <span>{ currentSubscriptionDetail[ "Plan_Id" ][ "Storage" ] }MB Storage</span> </li>
											<hr />
											<li>Upto <span>{ currentSubscriptionDetail[ "Plan_Id" ][ "WebAR_Experience" ] }</span> WebAR </li>
											<hr />
											<li>Watermark { currentSubscriptionDetail[ "Plan_Id" ][ "Watermark" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
											<hr />
											<li>Templates  { currentSubscriptionDetail[ "Plan_Id" ][ "Templates" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
											<hr />
											<li>Domain { currentSubscriptionDetail[ "Plan_Id" ][ "Domain" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
											<hr />
											<li>Analytics { currentSubscriptionDetail[ "Plan_Id" ][ "Analytics" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
											<hr />
											<li>Community Support { currentSubscriptionDetail[ "Plan_Id" ][ "Community_Support" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
											<hr />
											<li>Dedicated Support { currentSubscriptionDetail[ "Plan_Id" ][ "Dedicated_Support" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
											<hr />
											<li>No Code { currentSubscriptionDetail[ "Plan_Id" ][ "No_Code" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
											<hr />
										</ul>
									</div>
								</div>
							</div>
							<div className="subscription_details">
								<table>
									<tbody>
										<tr>
											<td>
												Subscription Id
											</td>
											<td>
												{ currentSubscriptionDetail[ "Subscription_Id" ] }
											</td>
										</tr>
										<tr>
											<td>
												Period
											</td>
											<td>
												{ currentSubscriptionDetail[ "Period" ] }
											</td>
										</tr>
										<tr>
											<td>
												Unit
											</td>
											<td>
												{ currentSubscriptionDetail[ "Period_Unit" ] }
											</td>
										</tr>
										<tr>
											<td>
												Status
											</td>
											<td>
												{ currentSubscriptionDetail[ "Status" ] }
											</td>
										</tr>
										<tr>
											<td>
												Subscribed On
											</td>
											<td>
												{ Date( currentSubscriptionDetail[ "Current_Term_start" ] ) }
											</td>
										</tr>
										<tr>
											<td>
												Subscription Ends On
											</td>
											<td>
												{ Date( currentSubscriptionDetail[ "Current_Term_End" ] ) }
											</td>
										</tr>
									</tbody>
								</table>

							</div>
						</div>
					</div>
				</div> : "" : <Spinner />
			}
		</div>
	)
}

const mapPropsToState = ( state ) =>
{
	return {
		currentSubscription: state?.plans?.currentSubscription,
		plansLoader: state?.plans?.plansLoader,
	}
}

export default connect( mapPropsToState, { getSubscriptionById } )( ( withRouter( CurrentSubscriptionPage ) ) );

