import React from 'react'
import { Steps, Hints } from "intro.js-react";
import 'intro.js/introjs.css';
import './TourStep.css'
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const TourStep = ( { steps } ) =>
{
	const dispatch = useDispatch()
	const onExit = () =>
	{
		dispatch( { type: "TOUR_UPDATE", payload: { tour: "false" } } )
		localStorage.setItem( "tour", false )
	};
	const [ tour, setTour ] = useState( {
		stepsEnabled: true,
		initialStep: 0,
		steps: steps,
	} )

	return (
		<Steps
			enabled={ tour.stepsEnabled }
			steps={ tour.steps }
			initialStep={ tour.initialStep }
			onExit={ onExit }
			options={ {
				exitOnEsc: false,
				exitOnOverlayClick: false,
				skipLabel: "Skip",
				disableInteraction: true,
			} }
		/>
	)
}

export default TourStep