import React from 'react'
// import "@google/model-viewer/dist/model-viewer";
import './View.css'
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
const ViewModel = ( { model } ) =>
{

	return (
		<div className='model'>
			{ model ? <div className='model-view'>
				<div className="header">
					<h2 className='title'>{ model?.Model_Name }</h2>
				</div>
				<div id="model-body">
					<div className="model-viewer">
						<model-viewer
							poster="https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/Xarwin-Preloader.gif"
							src={ model[ "Format_Source" ][ "GLTF" ] } ar
							camera-controls auto-rotate autoplay
							alt="A 3D model carousel"
						>
						</model-viewer>

					</div>
					<div className="model-details">
						<table border={ 5 }>
							<tbody>
								<tr>
									<td className='label'>Model Name</td>
									<td className="content">{ model?.Model_Name }</td>
								</tr>
								<tr>
									<td className='label'>Model Id</td>
									<td className="content">{ model?._id }</td>
								</tr>
								<tr>
									<td className='label'>User Id</td>
									<td className="content">{ model?.User_Id }</td>
								</tr>
								<tr>
									<td className='label'>Type</td>
									<td className="content">{ model?.Type }</td>
								</tr>
								<tr>
									<td className='label'>GLTF/GLB</td>
									<td className="content">{ model ? model[ "Format_Source" ][ "GLTF" ] : "" }</td>
								</tr>
								<tr>
									<td className='label'>USDZ</td>
									<td className="content">{ model ? model[ "Format_Source" ][ "USDZ" ] : "" }</td>
								</tr>
								<tr>
									<td className='label'>Created At</td>
									<td className="content">{ model?.createdAt?.split( "T" )[ 0 ] }</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div> : <h3>Model Not Found</h3> }
		</div>
	)
}


const mapPropsToState = ( state ) =>
{
	return {
		modelLoader: state?.model?.modelssLoader,
		model: state?.model?.currentModel,
	}
}

export default connect( mapPropsToState, {} )( ( withRouter( ViewModel ) ) );


