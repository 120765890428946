/* eslint-disable import/no-anonymous-default-export */
import modelPlaceHolder from '../../../../../images/3dmodel.png'
import CryptoJS from 'crypto-js';


export default ( editor, opts = {}, model ) =>
{
	const bm = editor.BlockManager;
	const styles = `
	<style>
	#ar-button {
		background-image: url("https://user-images.githubusercontent.com/66217883/175517729-3f051f60-7d25-45df-9b41-f57065a17c6a.png");
		background-repeat: no-repeat;
		background-size: 20px 20px;
		background-position: 12px 50%;
		background-color: #fff;
		position: absolute;
		right: 5%;
		transform: translateX(-50%);
		bottom: 10px;
		padding: 0px 16px 0px 40px;
		font-family: Roboto Regular, Helvetica Neue, sans-serif;
		font-size: 14px;
		color: #4285f4;
		height: 36px;
		line-height: 36px;
		border-radius: 18px;
		border: 1px solid #dadce0;
	  }

	  #ar-button:active {
		background-color: #e8eaed;
	  }

	  #ar-button:focus {
		outline: none;
	  }

	  #ar-button:focus-visible {
		outline: 1px solid #4285f4;
	  }

	  @keyframes circle {
		from {
		  transform: translateX(-50%) rotate(0deg) translateX(50px)
			rotate(0deg);
		}
		to {
		  transform: translateX(-50%) rotate(360deg) translateX(50px)
			rotate(-360deg);
		}
	  }

	  @keyframes elongate {
		from {
		  transform: translateX(100px);
		}
		to {
		  transform: translateX(-100px);
		}
	  }

	  model-viewer > #ar-prompt {
		position: absolute;
		left: 50%;
		bottom: 175px;
		animation: elongate 2s infinite ease-in-out alternate;
		display: none;
	  }

	  model-viewer[ar-status="session-started"] > #ar-prompt {
		display: block;
	  }

	  model-viewer > #ar-prompt > img {
		animation: circle 4s linear infinite;
	  }

		model-viewer{
		background-color: #eee;
		width: 100%;
	  }
	  </style> 
	  `
	const encryptedModelId = CryptoJS.AES.encrypt(model['_id'], process.env.REACT_APP_HASH_SECRET_KEY);
	console.log(encryptedModelId)
	bm.add( opts.name, {
		label: `
	  <img width="50px" height="50px" src='${modelPlaceHolder}' />
	  <div class="gjs-block-label">
		${opts.label}
	  </div> 
	  `,
		category: opts.category,
		content: `
		<model-viewer
		draggable="false"
		droppable="false"
		class='3dmodel'
		name="${model[ "Model_Name" ]}"
				src="${model[ "Format_Source" ][ "GLTF" ]}"
				ios-src="${model[ "Format_Source" ][ "USDZ" ]}"

				ar ar-modes="scene-viewer webxr quick-look"
				model-id = "${encryptedModelId}"
				loading="eager"
				poster="https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/Xarwin-Preloader.gif"
				shadow-intensity="1"
				shadow-softness="1"
				exposure="1"
				alt="A 3D model of an astronaut"
				camera-controls
				autoplay
				// auto-rotate
				ar
			>
			<button id="ar-button" draggable="false" droppable="false" slot="ar-button">View in AR</button>

          <div id="ar-prompt" draggable="false" droppable="false">
            <img src="https://user-images.githubusercontent.com/66217883/175517705-28273a94-5932-42a9-925e-15fae1d48ddc.png" />
          </div>
			</model-viewer>${styles}
		`,
	} );
};
