import React, { useEffect, useState } from 'react'
// import "@google/model-viewer/dist/model-viewer";
import './ModelEditor.css'
import { connect, useDispatch, } from 'react-redux';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import ModelFeatures from './ModelEditorFeatures/ModelFeatures';
import TourStep from "../../Components/Tour/TourStep";
import { getProjectModelTexturesById } from '../../../actions/Models';


const ModelEditor = ( { modelDetails, tour,getProjectModelTexturesById } ) =>
{
	const [ currentModelIndex, setCurrentModelIndex ] = useState( 0 )
	const [ showHotspot, setShowHotspot ] = useState( false )
	const [ showHotspotLabel, setShowHotspotLabel ] = useState( false );
	const [ points, setPoints ] = useState( null )
	const [ labelValue, setLabelValue ] = useState( '' )
	const [ existingHotspots, setExistingHotspots ] = useState( [] )
	const [ threedmodels, setThreedmodels ] = useState( [] )
	const dispatch = useDispatch()


	const steps = [
		{
			element: ".features",
			intro: "Edit your model properties from here"
		},
		{
			element: ".bs-button",
			intro: "Add label to your model from here"
		},
		{
			element: "#saveModelButton",
			intro: "Save your changes"
		}
	]

	const handleClick = ( event ) =>
	{
		try
		{
			const { clientX, clientY } = event;
			let hit = event.target.positionAndNormalFromPoint( clientX, clientY );
			console.log( hit )
			setPoints( hit )
			setShowHotspotLabel( true )
		} catch ( error )
		{
			console.log( error )
		}
	};

	const setHotspot = () =>
	{
		if ( points )
		{
			console.log( "Here" )
			setExistingHotspots( [] )
			const hotspotId = Math.random().toString( 36 ).replace( '0.', '' );
			const hotspot = `<button data-gjs-draggable="false" class="view-button" id=${hotspotId} slot=hotspot-${hotspotId} data-position="${getDataPosition( points )}" data-normal="${getDataNormal( points )}" data-gjs-droppable="false" draggable="true" data-visibility-attribute="visible" >${labelValue}</button>`;

			console.log( hotspot )
			document.getElementById( 'model-viewer' ).firstChild.innerHTML += hotspot

			setShowHotspot( false )
			setShowHotspotLabel( false )
		}
		setShowHotspotLabel( false )
	}

	const fetchHotspots = () =>
	{
		const hotspots = Array.from( document.getElementsByClassName( 'view-button' ) )
		console.log( hotspots )
		setExistingHotspots( hotspots )
	}

	const getDataPosition = ( annot ) =>
	{
		return `${annot.position.x} ${annot.position.y} ${annot.position.z}`;
	};

	const getDataNormal = ( annot ) =>
	{
		return `${annot.normal.x} ${annot.normal.y} ${annot.normal.z}`;
	};

	useEffect( () =>
	{
		if ( modelDetails.length > 0 )
		{
			setThreedmodels( modelDetails )
			document.getElementById( 'model-viewer' ).innerHTML = modelDetails[ currentModelIndex ][ 'ModelViewer' ]
			console.log( document.getElementById( 'model-viewer' ).innerHTML )
			console.log( document.getElementById( 'model-viewer' ).firstChild )
			console.log( document.getElementById( 'model-viewer' ).firstChild )
			document.getElementById( 'model-viewer' ).firstChild.addEventListener( 'click', handleClick )
			console.log( document.getElementById( 'model-viewer' ).model )
			const modelId = document.getElementById( 'model-viewer' ).firstChild.getAttribute('model-id')
			getProjectModelTexturesById(modelId)
		}
	}, [ modelDetails ] )


	const updateThreedModels = ( model ) =>
	{
		const tempModels = threedmodels;
		tempModels[ currentModelIndex ] = model;
		setThreedmodels( tempModels )
		console.log( threedmodels )
		dispatch( { type: "MODEL_DETAILS_UPDATE", payload: { modelDetails: tempModels } } )
	}


	return (
		<div >
			{ tour != "false" && <TourStep steps={ steps } tourReducer={ tour } page={ "modeleditor" } /> }
			<div className='model-editor'>
				<div className='sidebar'>
					<ModelFeatures model={ threedmodels[ currentModelIndex ] } updateThreedModels={ updateThreedModels } />
				</div>
				<div className="editor-view">
					<div id="threedmodels">
						{
							threedmodels.map( ( model, index ) => (
								<div
									className={ 'model ' + ( currentModelIndex === index ? 'current-model' : '' ) }
									key={ index }
									onClick={ () =>
									{
										if ( index !== currentModelIndex )
										{
											setCurrentModelIndex( index );
											document.getElementById( 'model-viewer' ).innerHTML = modelDetails[ index ][ 'ModelViewer' ]
											document.getElementById( 'model-viewer' ).firstChild.addEventListener( 'click', handleClick )
										}
									} }
								>{ ( model.Name === null || model.Name === '' ) ? `Model ${index + 1}` : model.Name }</div>
							) )
						}
					</div>
					<div className='model-editor-panel'>
						<div className="header">
							<div className="hotspot">
								<Button className="bs-button" variant="success" onClick={ () => setShowHotspot( true ) }>Add Hotspot</Button>
								{
									showHotspot && <div>
										<p>Please click on the Model</p>
									</div>
								}
								{ showHotspot && showHotspotLabel && <Alert show={ true } variant="success">
									<Alert.Heading>HOTSPOT</Alert.Heading>
									<p>
										Hotspot Label
									</p>
									<hr />

									<InputGroup className="mb-3">
										<Form.Control
											placeholder="Hotpsot"
											id='hotspot-input'
											onChange={ ( e ) =>
											{
												console.log( "width" )
												setLabelValue( e.target.value )
											} }
										/>
									</InputGroup>
									<div className="d-flex justify-content-end">
										<Button variant="outline-success" onClick={ setHotspot }>
											Save
										</Button>
									</div>
								</Alert> }
								<Button className="bs-button" style={ { backgroundColor: "#23374D" } } variant="danger" onClick={ fetchHotspots }>Delete Hotspot</Button>
								{
									existingHotspots.map( ( l1, index ) => (
										<div key={ index } onClick={ () =>
										{
											const temp = existingHotspots.filter( ( l ) =>
												l.id !== l1.id
											)
											console.log( temp )
											setExistingHotspots( temp )
											document.getElementById( l1.id ).remove()
										} }>
											<Button variant="contained" color="danger">{ l1.innerHTML } X</Button>
										</div>
									) )
								}
							</div>
						</div>
						<div className="" id="model-viewer"
						>

						</div>
					</div>

				</div>
			</div>
		</div>
	)
}


const mapStateToProps = ( state ) =>
{
	// console.log( state )
	console.log( document.getElementById( 'model-viewer' ) )
	return {
		modelEditor: state?.modelEditor,
		modelDetails: state?.modelEditor?.modelDetails,
		tour: state?.tour?.tour,
	}
}

export default connect( mapStateToProps,{getProjectModelTexturesById} )( ModelEditor )

