import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import "./CreateProjects.css"
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { getAllModelsByUser } from '../../../actions/Models';
import TourStep from '../../Components/Tour/TourStep';
import { createProjectFunction } from '../../../actions/Projects';
import { getAllTemplates } from '../../../actions/Templates';
import { useMemo } from 'react';

const CreateProjects = ( { user, getAllModelsByUser, getAllTemplates, createProjectFunction, models, templates, tour } ) =>
{
    const [ modelsData, setModelData ] = useState( [] )
    const [ templatesData, setTemplatesData ] = useState( [] )
    const history = useHistory();
    const location = useLocation()
    const steps = [
        {
            element: "#create-project-name",
            intro: "Name your project"
        },
        {
            element: "#create-project-description",
            intro: "Describe your project"
        },
        {
            element: "#create-project-model",
            intro: "You can choose your models or go for the one we created just for you!"
        },
        {
            element: "#create-project-upload",
            intro: "You can even upload your own model here!"
        },
    ]

    const fetchData = useMemo( () =>
    {
        return async () =>
        {
            await getAllModelsByUser();

            await getAllTemplates();

        };
    }, [] );

    useEffect( () =>
    {
        fetchData()
    }, [ fetchData ] )

    useEffect( () =>
    {
        console.log( models )
        const selectedModel = location.selectedModel
        const selectedTemplate = location.selectedTemplate
        console.log( selectedTemplate )
        if ( selectedModel !== undefined && selectedModel !== null )
        {
            setModelData( [ selectedModel ] )
        } else
        {
            setModelData( models )
        }
        if ( selectedTemplate !== undefined && selectedTemplate !== null )
        {
            setTemplatesData( [ selectedTemplate ] )
        } else
        {
            setTemplatesData( templates )
        }
    }, [ models, templates ] )


    const [ form, setForm ] = useState( {
        user: "",
        project: "",
        format: "",
        template: "",
    } );

    const onChange = ( value ) =>
    {
        setForm( ( prev ) =>
        {
            return { ...prev, ...value };
        } );
    };

    const handleSubmit = ( e ) =>
    {
        e.preventDefault();

        const userProject = {
            Name: user.displayName,
            User_Id: user.uid,
            Project_Name: form.project,
            Description: form.description,
            Model_Id: form.model,
            Template: form.template,
        };

        console.log( userProject );
        createProjectFunction( userProject, history )
    };

    const uploadModel = () =>
    {
        history.push( '/add-model' )
    }


    return (
        <div>
            { tour != "false" && <TourStep steps={ steps } tourReducer={ tour } /> }
            <div className='create-project-container'>
                <div className='create-project-form'>
                    <div id="create-project-name">
                        <label htmlFor="project">Project Name</label>
                        <input
                            className='inputField'
                            type="text"
                            id="project"
                            placeholder='Furniture'
                            value={ form.project }
                            onChange={ ( e ) => onChange( { project: e.target.value } ) }
                            required
                        />
                    </div>

                    <div id="create-project-description">
                        <label htmlFor="description">Description </label>
                        <input
                            className='inputField'
                            type="text"
                            id="description"
                            placeholder='Interior furniture project'
                            value={ form.description }
                            onChange={ ( e ) => onChange( { description: e.target.value } ) }
                        />
                    </div>

                    <div id="create-project-select">
                        <label htmlFor="model">Choose A Model </label>
                        <div className='model_box'>
                            <select
                                className="inputField"
                                type="select"
                                id="model"
                                value={ form.model }
                                onChange={ ( e ) => onChange( { model: e.target.value } ) }
                            >
                                <option>--select an option--</option>
                                {
                                    modelsData.map( ( model ) => (

                                        <option
                                            key={ model._id }
                                            value={ model._id }
                                            id={ model._id }
                                        >{ model.Model_Name }</option>

                                    ) )
                                }
                            </select>
                            <label htmlFor="model"> Or </label><br />
                            <button id="create-project-upload" onClick={ () => uploadModel() }>Upload Model</button>
                        </div>

                    </div>

                    <div id="create-project-select">
                        <label htmlFor="template">Choose A Template </label>
                        <div className='template_box'>
                            <select
                                className="inputField"
                                type="select"
                                id="template"
                                value={ form.template }
                                onChange={ ( e ) => onChange( { template: e.target.value } ) }
                            >
                                <option>--select an option--</option>
                                {
                                    templatesData?.map( ( template ) => (
                                        <option
                                            key={ template._id }
                                            value={ template._id }
                                            id={ template._id }
                                        >{ template.Name }</option>

                                    ) )
                                }
                            </select>
                        </div>

                    </div> <br />

                    <button onClick={ handleSubmit } type="submit">
                        Create Project
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ( state, ownProps ) =>
{
    return {
        models: state?.model?.models,
        tour: state?.tour?.tour,
        projectsLoader: state?.project?.projectsLoader,
        user: state?.user?.userData,
        templates: state?.project?.templates,
    }
}

export default connect( mapStateToProps, { getAllModelsByUser, createProjectFunction, getAllTemplates } )( ( withRouter( CreateProjects ) ) );

