import { FaRegTimesCircle } from 'react-icons/fa';
import './Notifications.scss'
import notificationPlaceHolder from '../../../images/notification.jpg'

const Notifications = () =>
{
  return (
    <div className='notification-card'>
      {/* <div className='notification'>
        <span />
        <div>
          <h3>Lorem Ipsum</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Perspiciatis voluptatem a quia explicabo iusto illo officiis,
            dolores, placeat reprehenderit tenetur alias.
          </p>
        </div>
        <FaRegTimesCircle />
      </div>
      <div className='notification'>
        <span />
        <div>
          <h3>Lorem Ipsum</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Perspiciatis voluptatem a quia explicabo iusto illo officiis,
            dolores, placeat reprehenderit tenetur alias.
          </p>
        </div>
        <FaRegTimesCircle />
      </div>
      <div className='notification'>
        <span />
        <div>
          <h3>Lorem Ipsum</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Perspiciatis voluptatem a quia explicabo iusto illo officiis,
            dolores, placeat reprehenderit tenetur alias.
          </p>
        </div>
        <FaRegTimesCircle />
      </div>
      <div className='notification'>
        <span />
        <div>
          <h3>Lorem Ipsum</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Perspiciatis voluptatem a quia explicabo iusto illo officiis,
            dolores, placeat reprehenderit tenetur alias.
          </p>
        </div>
        <FaRegTimesCircle />
      </div>
      <div className='notification'>
        <span />
        <div>
          <h3>Lorem Ipsum</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Perspiciatis voluptatem a quia explicabo iusto illo officiis,
            dolores, placeat reprehenderit tenetur alias.
          </p>
        </div>
        <FaRegTimesCircle />
      </div> */}
      <div className='notification-placeholder'>
        <img src={ notificationPlaceHolder } alt="" />
        <p>No New Notifications!</p>
      </div>
    </div>
  );
};

export default Notifications;
