import React from 'react'
import { BsCheck2Circle, BsXCircle } from 'react-icons/bs'
import './PlanCard.css'
import { useHistory, withRouter } from 'react-router-dom'
import { getHostedPage, updateSubscriptionDetails } from '../../../actions/SubscriptionPlans'
import { connect } from 'react-redux'
import config from '../../../actions/config'

const PlanCard = ( { plan, index, monthly, priceCode, getHostedPage, updateSubscriptionDetails, userDetails } ) =>
{
	const history = useHistory()
	const cbInstance = window.Chargebee.init( {
		site: config.siteName
	} )

	const planConfirmationHandler = () =>
	{
		const subscribe = {
			"itemId": plan[ "Item_Id" ],
			"period": monthly === false ? "month" : "year",
			"price_code": priceCode,
		}
		cbInstance.openCheckout( {
			hostedPage ()
			{
				return getHostedPage( subscribe )
			},
			success ( hostedPageId )
			{
				console.log( hostedPageId );
				return updateSubscriptionDetails( hostedPageId, plan[ "Item_Id" ], history )
			},
			close ()
			{
				console.log( "checkout new closed" );
			},
			step ( step )
			{
				console.log( "checkout", step );
			}
		} )
	}

	return (
		<div id="all_plan_card">
			<div className="plan_title">
				<h5>{ plan[ "Item_Id" ] }</h5>
			</div>
			<div className="plan_details">
				<div className="plan_features">
					<ul>
						<li className="price">{ priceCode === "USD" ? ( monthly === false ? plan[ "Month_Price" ] : plan[ "Year_Price" ] ) + "$" : "₹" + ( monthly === false ? plan[ "Month_INR_Price" ] : plan[ "Year_INR_Price" ] ) } </li>
						<li>{ plan[ "Views" ] }</li>
						<hr />
						<li> <span>{ plan[ "Storage" ] < 1024 ? plan[ "Storage" ] + " MB" : plan[ "Storage" ] / 1024 + " GB" }</span> </li>
						<hr />
						<li>Upto <span>{ plan[ "WebAR_Experience" ] }</span> WebAR </li>
						<hr />
						<li> { plan[ "Watermark" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
						<hr />
						<li>  { plan[ "Templates" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
						<hr />
						<li> { plan[ "Domain" ] == true ? "Coming Soon" : <BsXCircle color="red" size="20" /> }</li>
						<hr />
						<li> { plan[ "Analytics" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
						<hr />
						<li> { plan[ "Community_Support" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
						<hr />
						<li> { plan[ "Dedicated_Support" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
						<hr />
						<li> { plan[ "No_Code" ] == true ? <BsCheck2Circle color="green" size="20" /> : <BsXCircle color="red" size="20" /> }</li>
						<hr />
					</ul>
				</div>
				{ userDetails !== undefined && userDetails !== null && userDetails[ "Current_Subscription" ] !== undefined && <div className="actions">
					{ userDetails[ "Current_Subscription" ][ "Plan_Id" ][ "_id" ] === plan[ "_id" ] ? <button
						style={ { backgroundColor: "#23384D" } }
						variant="danger"
					>Current Subscription</button> : <button
						variant="danger"
						onClick={ planConfirmationHandler }
					>Subscribe</button> }
				</div> }
			</div>
		</div>
	)
}
const mapPropsToState = ( state ) =>
{
	return {
		plansLoader: state?.plans?.plansLoader,
	}
}

export default connect( mapPropsToState, { getHostedPage, updateSubscriptionDetails } )( ( withRouter( PlanCard ) ) );


