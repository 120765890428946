export const customFontFamilyType = ( editor ) =>
{
	editor.StyleManager.addType( 'custom-font-family', {
		// Create UI
		create ( { props, change } )
		{
			const el = document.createElement( 'div' );
			const fontOptions = []
			props.list.map( ( font ) => fontOptions.push( `<option style="font-family: ${font.value}" value="${font.value}">${font.name}</option>` ) )
			el.innerHTML = `
			<div class="gjs-field gjs-select">
				<span id="gjs-sm-input-holder">
					<select class="font-family-select">${fontOptions}</select>
				</span>
				<div class="gjs-sel-arrow">
					<div class="gjs-d-s-arrow">
					</div>
				</div>
			</div>`;
			const inputEl = el.querySelector( '.font-family-select' );
			inputEl.addEventListener( 'change', event => change( { event } ) ); // `change` will trigger the emit
			inputEl.addEventListener( 'input', event => change( { event, partial: true } ) );
			return el;
		},
		// Clean the memory from side effects if necessary (eg. global event listeners, etc.)
		destroy ()
		{
		},
	} );
};