import axios from 'axios'
import Swal from 'sweetalert2';
import config from './config';

export const getAllITProjects = () => ( dispatch, getState ) =>
{
	dispatch( { type: "IT_PROJECT_LOADER" } )
	const user = getState()?.user?.userData;
	console.log( user )
	console.log( user.stsTokenManager.accessToken )
	axios.get( `${config.serverUrl}/image-tracking/getAll/${user.uid}`, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const itProjects = res.data.itProjects
		dispatch( { type: "IT_PROJECT_UPDATE", payload: { itProjects: itProjects } } )
	} )
}

export const createITProject = ( projectData, history ) => async ( dispatch, getState ) =>
{
	dispatch( { type: "IT_PROJECT_LOADER" } )
	const user = getState()?.user?.userData;
	// const token = await user?.getIdToken()
	var formData = new FormData()

	Object.keys( projectData ).forEach( key =>
	{
		console.log( projectData[ key ] )
		console.log( key )
		if(key==="SocialMediaLinks"){
			for (let SocialMediaLinksKey in projectData[ key ]) {
				formData.append(`SocialMediaLinks[${SocialMediaLinksKey}]`, projectData[key][SocialMediaLinksKey])
			}
		}else{
			formData.append( key, projectData[ key ] )
		}
	} );


	axios.post( `${config.serverUrl}/image-tracking/create`, formData, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken,
			'Content-Type': 'multipart/form-data'
		},
		onUploadProgress: ( progressEvent ) =>
		{
			const { loaded, total } = progressEvent;
			let percent = Math.floor( ( loaded * 100 ) / total );
			console.log( `${loaded} || ${percent}%` )
			if ( percent < 90 )
				dispatch( { type: "UPDATE_UPLOAD_MODEL_PERCENTAGE", payload: { uploadModelPercentage: `${percent}%` } } )
			else if ( percent < 98 )
				setTimeout( () =>
				{
					dispatch( { type: "UPDATE_UPLOAD_MODEL_PERCENTAGE", payload: { uploadModelPercentage: `${percent}%` } } )
				}, 3000 )
			else
				dispatch( { type: "UPDATE_UPLOAD_MODEL_PERCENTAGE", payload: { uploadModelPercentage: "Completed, creating Model..." } } )
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		dispatch( { type: "IT_PROJECT_LOADER" } )
		history.push( '/image-tracking' )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			console.log( err.data )
			console.log( err.response )
			Swal.fire( { title: err.response.data.replace( /<(?:.|\n)*?>/gm, '' ).replace( /\s/g, ' ' ) } )
			dispatch( { type: "IT_PROJECT_LOADER" } )
		} )
}


export const deleteITProject = ( currentId, callback ) => ( dispatch, getState ) =>
{
	dispatch( { type: "IT_PROJECT_LOADER" } )
	const user = getState()?.user?.userData;
	axios.delete( `${config.serverUrl}/image-tracking/${currentId}`, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
		}
	} ).then( data =>
	{
		console.log( data );
		dispatch( { type: "IT_PROJECT_LOADER" } )
		if ( data )
		{
			callback( { status: true } )
		} else
		{
			callback( { status: false } )
		}
	} ).catch( ( error ) =>
	{
		console.log( error )
		dispatch( { type: "IT_PROJECT_LOADER" } )
		callback( { status: false } )
	} )
}