import React, { useEffect, useState } from "react";
import { useLocation, useParams, withRouter } from "react-router-dom";
import "grapesjs/dist/css/grapes.min.css";
import axios from "axios";
import { connect, useDispatch } from "react-redux";
import Sidebar from '../../Components/Editor/Sidebar'
import TopNav from '../../Components/Editor/TopNav'
import geditorConfig from "../../../Utilities/Editor/geditor_config";
// import "@google/model-viewer/dist/model-viewer";
import "bootstrap";
import './Editor.scss'
import useAuth from "../../../hooks/useAuth";
import { Redirect, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ModelEditor from "../ModelEditor/ModelEditor";
import logo from '../../../images/Xarwin-logo-dark.png'
import Features from "../../Components/Editor/Features";
import TourStep from "../../Components/Tour/TourStep";
import { HiInformationCircle } from 'react-icons/hi'
import { getAllModelsByUser } from "../../../actions/Models";
import { getUserDetailsById } from "../../../actions/User";

const Editor = ( { showModelEditor, modelDetails, getAllModelsByUser, getUserDetailsById, tour, user, dbUser, models } ) =>
{
	const [ editor, setEditor ] = useState( null );
	const { projectId } = useParams();
	const { templateId } = useParams();
	const location = useLocation();
	const history = useHistory();
	const { serverUrl } = useAuth()
	const dispatch = useDispatch()
	const tourHandler = () =>
	{
		dispatch( { type: "TOUR_UPDATE", payload: { tour: "true" } } )
	}

	const steps = [
		{
			element: "#editor",
			intro: "This is your presentation editor where you can create your own NO-CODE website and edit your model."
		},
		{
			element: "#model-tab",
			intro: "Edit your model from here"
		},
		{
			element: "#block-tab",
			intro: "Drag and drop you web components from here"
		},
		{
			element: "#style-tab",
			intro: "Want to style your site?\nClick here!"
		},
		{
			element: "#trait-tab",
			intro: "Add your web components attributes like video source from here"
		},
		{
			element: ".saveDb",
			intro: "Save your work as a draft"
		},
		{
			element: ".publish",
			intro: "Publish your work from here"
		},
	]
	console.log( showModelEditor )

	useEffect( () =>
	{
		dispatch( { type: "CLOSE_MODEL_EDITOR" } )
		dispatch( { type: "MODEL_UPDATE", payload: { models: null } } )
		console.log( user )
		console.log( user.length )
		if ( user != null && user.stsTokenManager.accessToken != null && serverUrl != null )
		{
			console.log( user )
			console.log( user.stsTokenManager.accessToken )
			console.log( "Fetching Project" )
			getUserDetailsById()
			getAllModelsByUser()
		}
	}, [] );

	useEffect( () =>
	{
		if ( dbUser !== undefined && dbUser !== null && models !== null )
		{
			console.log( models )
			const current_plan = dbUser[ "Current_Subscription" ][ "Plan_Id" ]
			if ( !location.pathname.includes( "templates" ) )
			{
				axios.get( `${serverUrl}/projects/${projectId}`, {
					headers: {
						"Authorization": "Bearer " + user.stsTokenManager.accessToken
					}
				} ).then( ( res ) =>
				{
					const project = res.data.project
					const editor = geditorConfig( {
						id: projectId,
						serverUrl: serverUrl,
						user: user,
						history: history,
						user_id: user.uid,
						project: project,
						models: current_plan[ "Multiple_Models" ] === false ? ( project[ "Model_Id" ].length > 0 ? [ project[ "Model_Id" ][ 0 ] ] : [] ) : models,
						isTemplate: false,
					} );
					setEditor( editor );
				} )
			} else
			{
				const editor = geditorConfig( {
					id: templateId,
					serverUrl: serverUrl,
					user: user,
					history: history,
					user_id: user.uid,
					project: null,
					models: current_plan[ "Multiple_Models" ] === false ? models.filter( ( model ) => model[ "User_Id" ] === 'default' ) : models,
					isTemplate: true,
				} );
				setEditor( editor );
			}

		}
	}, [ dbUser, models ] )


	const showEditorHandler = () =>
	{
		console.log( editor )
		const modelComponent = editor.Pages.getAll()[ 0 ].getMainComponent().find( '.3dmodel' );
		console.log( modelComponent )

		var iframe = document.getElementsByClassName( "gjs-frame" )[ 0 ];
		const threedmodels = iframe.contentWindow.document.getElementsByClassName( "3dmodel" )
		if ( threedmodels.length > 0 )
		{
			console.log( threedmodels )
			const modelDetails = []
			for ( var threedmodel of threedmodels )
			{
				console.log( threedmodel )
				const modelMaterial = threedmodel?.model?.materials[ 0 ]
				if ( modelMaterial !== undefined && modelMaterial !== null )
				{
					var detail = {
						'id': threedmodel.getAttribute( 'id' ),
						'Name': threedmodel.getAttribute( 'name' ),
						"Lighting": {
							exposure: threedmodel.getAttribute( 'exposure' ),
							shadowIntensity: threedmodel.getAttribute( 'shadow-intensity' ),
							shadowSoftness: threedmodel.getAttribute( 'shadow-softness' ),
						},
						"Hotspot": false,
						"Material": {
							roughness: modelMaterial.pbrMetallicRoughness.roughnessFactor,
							metalness: modelMaterial.pbrMetallicRoughness.metallicFactor,
							baseColorFactor: null,
							emissiveFactor: null,
							texture: null,
						},
						'ModelViewer': threedmodel.outerHTML
					}
					modelDetails.push( detail )
				}
			}
			dispatch( {
				type: "MODEL_DETAILS_UPDATE", payload: {
					modelDetails: modelDetails,
				}
			} )
			dispatch( { type: "SHOW_MODEL_EDITOR" } )
		}

	}

	const updateEditedModel = async () =>
	{
		if ( editor != null && modelDetails.length > 0 )
		{
			console.log( 'Inside Here' )
			const modelComponent = editor.Pages.getAll()[ 0 ].getMainComponent().find( '.3dmodel' );
			console.log( modelComponent )

			for ( var model of modelDetails )
			{
				console.log( `#${model[ "id" ]}` )
				const parser = new DOMParser()
				const editedModelCode = parser.parseFromString( model[ "ModelViewer" ], 'text/html' ).getElementById( model[ "id" ] )
				var iframe = document.getElementsByClassName( "gjs-frame" )[ 0 ];
				const modelViewerComponent = iframe.contentWindow.document.getElementById( model[ "id" ] )
				if ( modelViewerComponent.model != null && modelViewerComponent.model.materials.length > 0 )
				{
					const material = modelViewerComponent.model.materials[ 0 ];
					material.pbrMetallicRoughness.setRoughnessFactor( parseInt( model[ "Material" ].roughness ) )
					material.pbrMetallicRoughness.setMetallicFactor( parseInt( model[ "Material" ].metalness ) )
					model[ "Material" ].baseColorFactor !== null && material.pbrMetallicRoughness.setBaseColorFactor(
						[ model[ "Material" ].baseColorFactor[ "r" ],
						model[ "Material" ].baseColorFactor[ "g" ],
						model[ "Material" ].baseColorFactor[ "b" ] ]
					)
					model[ "Material" ].emissiveFactor !== null && material.setEmissiveFactor(
						[
							model[ "Material" ].emissiveFactor[ "r" ],
							model[ "Material" ].emissiveFactor[ "g" ],
							model[ "Material" ].emissiveFactor[ "b" ]
						]
					)
					if ( model[ "Material" ].texture !== null )
					{
						const modelTexture = await modelViewerComponent.createTexture( model[ "Material" ].texture.src );
						// Set the texture name
						modelTexture.name = model[ "Material" ].texture.key.split( "/" )[ 1 ];
						// Applies the new texture to the specified channel.
						material.pbrMetallicRoughness[ "baseColorTexture" ].setTexture( modelTexture );
					}
					const threedmodel = editor.Pages.getAll()[ 0 ].getMainComponent().find( `#${model[ "id" ]}` )
					threedmodel[ 0 ].components( editedModelCode.innerHTML )
					for ( const attr of editedModelCode.attributes )
					{
						const map = {}
						map[ attr.name ] = attr.value
						threedmodel[ 0 ].addAttributes( map )
					}
				}
			}
			editor.store()
			window.location.reload();
		}
	}

	return (
		<div className="model-presentation-editor">
			{ tour != "false" && <TourStep steps={ steps } tourReducer={ tour } /> }
			<div className="header">
				<div className="logo">
					<img src={ logo } alt="" />
					<h2 className="d-flex align-items-center">Editor  <HiInformationCircle onClick={ tourHandler } /></h2>
				</div>
				<div className="panel__devices"></div>
			</div>
			<div>
				<div
					className="sidenav d-flex flex-column position-fixed"
				>
					<div style={ { display: 'flex', alignItems: 'start' } }>
						<Sidebar showEditorHandler={ showEditorHandler } />
						<Features />
					</div>
				</div>
				<div
					className="main-content position-relative w-75 start-25"
				>
					<div className="editor-container d-flex">
						<div id="editor">

						</div>
						<div className="actions">
							<TopNav />
						</div>
					</div>
					<Modal
						show={ showModelEditor }
						fullscreen={ true }
						id='Model-Editor'
						dialogClassName="modal-90w"
						aria-labelledby="example-custom-modal-styling-title"
					>
						<Modal.Header>
							<Modal.Title id="example-custom-modal-styling-title">
								<div className="header">
									<div className="logo">
										<img src={ logo } alt="" />
									</div>
									<h2>Model-Editor <HiInformationCircle onClick={ tourHandler } /></h2>
								</div>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ModelEditor />
						</Modal.Body>
						<Modal.Footer>
							<button className="modeleditorbutton" onClick={ () =>
							{
								updateEditedModel()
								dispatch( { type: "CLOSE_MODEL_EDITOR" } )
							} }>Back to Editor</button>
						</Modal.Footer>
					</Modal>
				</div>
			</div>
		</div>
	);
}
const mapPropsToState = ( state ) =>
{
	console.log( state )
	return {
		showModelEditor: state?.modelEditor?.showModelEditor,
		tour: state?.tour?.tour,
		user: state?.user?.userData,
		dbUser: state?.user?.dbUser,
		models: state?.model?.models,
		modelDetails: state?.modelEditor?.modelDetails,
	}
}

export default connect( mapPropsToState, { getAllModelsByUser, getUserDetailsById } )( ( withRouter( Editor ) ) );

