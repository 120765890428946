import Model from '../../Components/Model/Model';
import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { useHistory, withRouter } from 'react-router-dom';
import { getAllModelsByUser } from '../../../actions/Models';
import { connect } from 'react-redux';
import { deleteModel } from '../../../actions/Models';
import './Models.css'

const Models = ( { getAllModelsByUser, deleteModel, models } ) =>
{
  const { serverUrl, user } = useAuth()
  const [ currentId, setCurrentId ] = useState( null );
  const [ isPopupOpen, setPopupOpen ] = useState( false );
  const history = useHistory()
  const [ modelsData, setModelData ] = useState( [] )

  useEffect( () =>
  {
    getAllModelsByUser()
  }, [] )


  useEffect( () =>
  {
    console.log( models )
    setModelData( models.sort( ( a, b ) => a.createdAt > b.createdAt ) )
  }, [ models ] )


  const onDelete = ( id ) =>
  {
    setCurrentId( id );
    setPopupOpen( true );
  };
  const deleteConfirm = () =>
  {
    setPopupOpen( false );
    deleteModel( currentId, result =>
    {
      const { status } = result
      if ( status == true )
      {
        const remaining = models.filter( model => model._id !== currentId )
        setModelData( remaining )
      }
    } )
  };

  const uploadHandler = () =>
  {
    history.push( '/add-model' )
  }


  return (
    <>
      { modelsData.length > 0 ? <div className='models-container'>
        <div className='row gx-5 gy-2'>
          { modelsData.map( ( model ) => (
            <div className='col-md-4' key={ model._id }>
              <Model
                model={ model }
                id={ model._id }
                onDelete={ onDelete }
              />
            </div>
          ) ) }
        </div>
        { isPopupOpen && <div className='delete-popup'>
          <div className='model-delete-container'>
            <FaTimes onClick={ () => setPopupOpen( false ) } />
            <div>
              <p>Are you sure you want to delete ?</p>
              <div>
                <button onClick={ () => deleteConfirm() }>Yes</button>
                <button onClick={ () => setPopupOpen( false ) }>No</button>
              </div>
            </div>
          </div>
        </div> }
      </div> : <div className='upload_model'>
        <button className='btn btn-lg' onClick={ uploadHandler }>Upload a Model</button>
      </div> }
    </>
  )
}

const mapPropsToState = ( state ) =>
{
  return {
    models: state?.model?.models,
    modelsLoader: state?.model?.modelsLoader,
  }
}

export default connect( mapPropsToState, { getAllModelsByUser, deleteModel } )( ( withRouter( Models ) ) );
