import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import PageNotFound from '../Dashboard/Components/PageNotFound/PageNotFound';
import Layout from '../Dashboard/layouts/Layout';
import Dashboard from '../Dashboard/pages/Dashboard/Dashboard';
import Projects from '../Dashboard/pages/Projects/Projects';
import Models from '../Dashboard/pages/Models/Models';
import ViewModelPage from '../Dashboard/pages/ViewModel/ViewModel';
import Settings from '../Dashboard/pages/Settings/Settings';
import Profile from '../Dashboard/pages/Profile/Profile';
import Notifications from '../Dashboard/pages/Notifications/Notifications';
import PaymentHistory from '../Dashboard/pages/PaymentHistory/PaymentHistory';
import Login from '../Dashboard/pages/Auth/LoginPage/Login';
import Register from '../Dashboard/pages/Auth/Register/Register';
import AddModel from '../Dashboard/pages/AddModel/AddModel';
import CreateProjects from '../Dashboard/pages/CreateProjects/CreateProjects';
import ViewProject from '../Dashboard/pages/ViewProject/ViewProject.js';
import RegisterSuccess from '../Dashboard/pages/RegisterSuccess/RegisterSuccess';
import ForgotPassword from '../Dashboard/pages/ForgotPassword/ForgotPassword';
import AllPlans from '../Dashboard/pages/SubscriptionPlans/AllPlans/Plans'
import Editor from '../Dashboard/pages/Editor/Editor';
import UsernamePage from '../Dashboard/pages/PostRegistrationPages/Username/Username';
import ChooseSubscription from '../Dashboard/pages/PostRegistrationPages/ChooseSubscription/ChooseSubscription';
import EditUserProfile from '../Dashboard/pages/Profile/EditUserProfile';

import CurrentSubscription from '../Dashboard/pages/SubscriptionPlans/CurrentSubscription/Subscription'

import Verify from '../Dashboard/pages/Auth/Verification/Verify';

import PrivateRoute from './PrivateRoute';
import Templates from '../Dashboard/pages/Templates/AllTemplates/Templates';
import CreateTemplate from '../Dashboard/pages/Templates/CreateTemplate/CreateTemplate';
import { updateUserLoginTime } from '../actions/User';
import { connect } from 'react-redux';
import CreateITPage from '../Dashboard/pages/CreateImageTracker/CreateITPage';
import ImageTrackerList from '../Dashboard/pages/ImageTracker/ImageTrackerList';
import ViewITPage from '../Dashboard/pages/ViewIT/ViewITPage';
import ViewTemplatePage from '../Dashboard/pages/Templates/ViewTemplate/ViewTemplatePage';
const MainBuilder = ( { updateUserLoginTime } ) =>
{
  useEffect( () =>
  {
    updateUserLoginTime()
  }, [] );

  return (
    <>
      <Switch>
        <Route exact path='/' component={ Login } />
        <Route exact path='/login' component={ Login } />
        <Route exact path='/register' component={ Register } />
        <Route exact path='/register/success' component={ RegisterSuccess } />
        <Route exact path='/forgot-password' component={ ForgotPassword } />
        <Route exact path='/register/username' component={ UsernamePage } />
        <Route exact path='/register/subscription' component={ ChooseSubscription } />
        <Route exact path='/editor/:projectId' component={ Editor } />
        <Route exact path='/verify' component={ Verify } />
        <Route exact path='/templates/editor/:templateId' component={ Editor } />

        <Layout>
          <Switch>

            <PrivateRoute exact path='/dashboard' component={ Dashboard } />
            <PrivateRoute exact path='/projects' component={ Projects } />
            <PrivateRoute exact path='/viewproject/:id' component={ ViewProject } />
            <PrivateRoute exact path='/models' component={ Models } />
            <PrivateRoute exact path='/models/:modelId' component={ ViewModelPage } />
            <PrivateRoute exact path='/add-model' component={ AddModel } />
            <PrivateRoute exact path='/create-project' component={ CreateProjects } />
            <PrivateRoute exact path='/image-tracking/create' component={ CreateITPage } />
            <PrivateRoute exact path='/image-tracking' component={ ImageTrackerList } />
            <PrivateRoute exact path='/image-tracking/:id' component={ ViewITPage } />
            <PrivateRoute exact path='/settings' component={ Settings } />
            <PrivateRoute exact path='/profile' component={ EditUserProfile } />
            <PrivateRoute exact path='/notifications' component={ Notifications } />
            <PrivateRoute exact path='/payment-history' component={ PaymentHistory } />
            <PrivateRoute exact path='/plans' component={ AllPlans } />
            <PrivateRoute exact path='/my-subscription' component={ CurrentSubscription } />
            <PrivateRoute exact path='/templates' component={ Templates } />
            <PrivateRoute exact path='/templates/:templateId' component={ ViewTemplatePage } />
            <PrivateRoute exact path='/templates/create' component={ CreateTemplate } />
          </Switch>
        </Layout>

        <Route component={ PageNotFound } />
      </Switch>
    </>
  );
};

export default connect( null, { updateUserLoginTime } )( withRouter( MainBuilder ) );
