import axios from 'axios';
import Swal from 'sweetalert2';
import config from './config.js'

export const getAllTemplates = () => async ( dispatch, getState ) =>
{
	dispatch( { type: "PROJECT_LOADER" } )
	const user = getState()?.user?.userData;
	console.log( user )
	axios.get( `${config.serverUrl}/users/${user.uid}/templates`, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const templates = res.data.templates
		console.log( templates )
		dispatch( { type: "TEMPLATE_UPDATE", payload: { templates: templates } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "PROJECT_LOADER" } )
		} )
}

export const createTemplate = ( templateDetails, history ) => async ( dispatch, getState ) =>
{
	dispatch( { type: "TEMPLATE_LOADER" } )
	const user = getState()?.user?.userData;
	console.log( user )
	templateDetails[ "user_id" ] = user.uid
	axios.post( `${config.serverUrl}/users/templates/create`, templateDetails, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		dispatch( { type: "TEMPLATE_LOADER" } )
		Swal.fire( { icon: 'success', title: "Template Created!" } )
		// history.push( `/templates/editor/${res.data.template[ "_id" ]}` )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			Swal.fire( { title: err.message } )
			dispatch( { type: "TEMPLATE_LOADER" } )
		} )
}

export const deleteTemplate = ( templateId, callback ) => ( dispatch, getState ) =>
{
	dispatch( { type: "TEMPLATE_LOADER" } )
	const user = getState()?.user?.userData;
	axios.delete( `${config.serverUrl}/users/templates/${templateId}`, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
		}
	} ).then( data =>
	{
		console.log( data );
		dispatch( { type: "TEMPLATE_LOADER" } )
		if ( data )
		{
			callback( { status: true } )
		} else
		{
			callback( { status: false } )
		}
	} ).catch( ( error ) =>
	{
		console.log( error )
		dispatch( { type: "TEMPLATE_LOADER" } )
		callback( { status: false } )
	} )
}

export const getTemplateById = ( templateId ) => ( dispatch, getState ) =>
{
	dispatch( { type: "TEMPLATE_LOADER" } )
	const user = getState()?.user?.userData;
	console.log( user.stsTokenManager.accessToken )
	axios.get( `${config.serverUrl}/users/templates/${templateId}`, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
		}
	} )
		.then( ( res ) =>
		{
			console.log( res )
			dispatch( { type: "CURRENT_TEMPLATE_UPDATE", payload: { currentTemplate: res.data.template } } )
		} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "TEMPLATE_LOADER" } )
		} )
}
