import grapesjs from "grapesjs";
import loadBlocks from './blocks.js'
import loadComponents from './components.js'

export default grapesjs.plugins.add( "customButton", ( editor, options ) =>
{
	console.log( options )
	let opts = {
		label: "Custom Button",
		name: "custom-button",
		category: "Basic",
	};

	loadBlocks( editor, opts );
	loadComponents( editor, opts );
} );
