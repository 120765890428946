import { BrowserRouter as Router } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import MainBuilder from './routes/MainBuilder';
import ReactGa4 from 'react-ga4'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './App.css'

ReactGa4.send( { hitType: "pageview", page: window.location.pathname } );

function App ()
{
  const { auth, setUser, logOut } = useAuth()
  const dispatch = useDispatch()
  useEffect( () =>
  {
    auth.onAuthStateChanged( ( user ) =>
    {
      if ( user )
      {
        dispatch( {
          type: "USER_UPDATE", payload: {
            user: user
          }
        } )
      } else
      {
        logOut();
      }
    } );
  }, [] );


  return (
    <Router>
      <MainBuilder />
    </Router>
  );
}

export default App;
