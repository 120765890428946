import axios from 'axios';
import Swal from 'sweetalert2';
import config from './config';

export const getAllModelsByUser = () => async ( dispatch, getState ) =>
{
	console.log( "Models user" )
	dispatch( { type: "MODEL_LOADER" } )
	const user = getState()?.user?.userData;
	console.log( user )
	console.log( user.stsTokenManager.accessToken )
	// const token = await user?.getIdToken()
	axios.get( `${config.serverUrl}/models/user/${user.uid}`, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const models = res.data.models
		dispatch( { type: "MODEL_UPDATE", payload: { models: models } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "MODEL_LOADER" } )
		} )
}


export const getModelById = ( modelId ) => async ( dispatch, getState ) =>
{
	dispatch( { type: "MODEL_LOADER" } )
	const user = getState()?.user?.userData;
	axios.get( `${config.serverUrl}/models/${modelId}`, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const model = res.data.model
		dispatch( { type: "CURRENT_MODEL_UPDATE", payload: { currentModel: model } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "MODEL_LOADER" } )
		} )
}


export const createModel = ( modelData, modelFile, history ) => async ( dispatch, getState ) =>
{
	dispatch( { type: "MODEL_LOADER" } )
	const user = getState()?.user?.userData;
	// const token = await user?.getIdToken()
	var data = new FormData()
	console.log( modelData )
	console.log( modelFile )
	data.append( "Model_Name", modelData[ "Model_Name" ] )
	data.append( "Description", modelData[ "Description" ] )
	data.append( "Type", modelData[ "Type" ] )
	data.append( "User_Id", user.uid )
	data.append( "Format_Source", JSON.stringify( modelData[ "Format_Source" ] ) )
	console.log( data )
	for ( const file in modelFile )
	{
		if ( modelFile[ file ] !== null )
		{
			data.append( 'files', modelFile[ file ] )
		}
	}
	for ( var key of data.entries() )
	{
		console.log( key[ 0 ] + ', ' + key[ 1 ] )
	}

	axios.post( `${config.serverUrl}/models/create`, data, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken,
			'Content-Type': 'multipart/form-data'
		},
		onUploadProgress: ( progressEvent ) =>
		{
			const { loaded, total } = progressEvent;
			let percent = Math.floor( ( loaded * 100 ) / total );
			console.log( `${loaded} || ${percent}%` )
			if ( percent < 90 )
				dispatch( { type: "UPDATE_UPLOAD_MODEL_PERCENTAGE", payload: { uploadModelPercentage: `${percent}%` } } )
			else if ( percent < 98 )
				setTimeout( () =>
				{
					dispatch( { type: "UPDATE_UPLOAD_MODEL_PERCENTAGE", payload: { uploadModelPercentage: `${percent}%` } } )
				}, 3000 )
			else
				dispatch( { type: "UPDATE_UPLOAD_MODEL_PERCENTAGE", payload: { uploadModelPercentage: "Completed, creating Model..." } } )
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		dispatch( { type: "MODEL_LOADER" } )
		history.push( '/models' )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			console.log( err.data )
			console.log( err.response )
			Swal.fire( { title: err.response.data.replace( /<(?:.|\n)*?>/gm, '' ).replace( /\s/g, ' ' ) } )
			dispatch( { type: "MODEL_LOADER" } )
		} )
}

export const deleteModel = ( currentId, callback ) => async ( dispatch, getState ) =>
{
	dispatch( { type: "MODEL_LOADER" } )
	const user = getState()?.user?.userData;
	// const token = await user?.getIdToken()

	axios.delete( `${config.serverUrl}/models/${currentId}`, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
		}
	} ).then( data =>
	{
		dispatch( { type: "MODEL_LOADER" } )
		callback( { status: true } )
	} ).catch( ( error ) =>
	{
		dispatch( { type: "MODEL_LOADER" } )
		callback( { status: false } )
	} )
}

export const uploadTextureImage = ( textureFiles, modelId, callback ) => ( dispatch, getState ) =>
{
	const user = getState()?.user?.userData;
	console.log( user.stsTokenManager.accessToken )
	var data = new FormData()
	data.append('Model_Id',modelId)
	data.append('User_Id', user.uid)
	console.log(textureFiles)
	for(const fileIndex in textureFiles ){
		data.append( 'files', textureFiles[fileIndex] )
	}
	for ( var key of data.entries() )
	{
		console.log( key[ 0 ] + ', ' + key[ 1 ] )
	}

	axios.post( `${config.serverUrl}/models/textures`, data, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken,
			'Content-Type': 'multipart/form-data'
		},
		onUploadProgress: ( progressEvent ) =>
		{
			const { loaded, total } = progressEvent;
			let percent = Math.floor( ( loaded * 100 ) / total );
			console.log( `${loaded} || ${percent}%` )
			dispatch( { type: "UPDATE_EDITOR_ASSET_UPLOAD_PERCENTAGE", payload: { percentage: percent } } )
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const model = res.data.model;
		dispatch( { type: "UPDATE_CURRENT_EDITOR_MODEL_DETAIL", payload: { currentEditorModel: model } } )
		callback( {
			status: true,
			model: model
		} )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			console.log( err.data )
			console.log( err.response )
			callback( {
				status: false,
				model: null
			} )
		} ).finally( () =>
		{
			dispatch( { type: "UPDATE_EDITOR_ASSET_UPLOAD_PERCENTAGE", payload: { percentage: 0 } } )
		} )
}

export const uploadModelColors = ( colorsList, modelId, callback ) => ( dispatch, getState ) =>
{
	const user = getState()?.user?.userData;
	const data = {
		Colors: colorsList,
		Model_Id:modelId,
		User_Id: user.uid,
	}
	axios.post( `${config.serverUrl}/models/colors`, {data}, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken,
		},
	} ).then( ( res ) =>
	{
		console.log( res )
		const {model,models} = res.data;
		dispatch( { type: "UPDATE_CURRENT_EDITOR_MODEL_DETAIL", payload: { currentEditorModel: model } } )
		
		callback( {
			status: true,
			model: model
		} )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			console.log( err.data )
			console.log( err.response )
			callback( {
				status: false,
				model: null
			} )
		} )
}

export const getProjectModelTexturesById = ( modelId ) => async ( dispatch, getState ) =>
{
	const user = getState()?.user?.userData;
	axios.post( `${config.serverUrl}/models/textures/project`,{Model_Id: modelId}, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const model = res.data.model
		dispatch( { type: "UPDATE_CURRENT_EDITOR_MODEL_DETAIL", payload: { currentEditorModel: model } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
		} )
}