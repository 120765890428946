import React from 'react'
import { useEffect } from 'react'
import './Username.css'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { useState } from 'react'
import { updateUsername } from '../../../../actions/User'
import Spinner from '../../../../Utilities/Spinner/Spinner.js'
import { useHistory } from 'react-router-dom'
import { getUserDetailsById } from '../../../../actions/User'

const Username = ( { loader, updateUsername, getUserDetailsById, userData, dbUser } ) =>
{
	const [ username, setUsername ] = useState( "" )
	const [ pageError, setPageError ] = useState( null )
	const [ userDetailsDb, setUserDetailsDb ] = useState( null )
	const history = useHistory()

	useEffect( () =>
	{
		console.log( userData.uid )
		if ( userData.uid !== undefined && userData.uid !== null )
		{
			getUserDetailsById()
		}
	}, [ userData ] )

	useEffect( () =>
	{
		setUserDetailsDb( dbUser )
	}, [ dbUser ] )



	const usernameButtonHandler = () =>
	{
		if ( username === "" )
		{
			setPageError( "Please provide a username!" )
		} else
		{
			setPageError( null )
			updateUsername( username, history )
		}
	}
	console.log( userDetailsDb )
	if ( userDetailsDb?.Username !== undefined && userDetailsDb?.Username !== null )
	{
		return <Redirect to={ { pathname: "/dashboard" } } />
	}

	return (
		<div className="post-registration">
			<div className='form-box'>
				<h1>Hello { userData?.displayName }! Welcome</h1>
				<p>Let's create a unique username for you</p>
				<div className="input-group mb-3">
					<span className="input-group-text" id="basic-addon1">@</span>
					<input
						type="text"
						className="form-control"
						placeholder="Username"
						required
						onChange={ ( e ) => setUsername( e.target.value ) }
					/>
				</div>
				<p style={ { color: "red", fontSize: "14px" } }>{ pageError }</p>
				{ loader === false ? <div className="username-actions">
					<button className="btn btn-lg" onClick={ usernameButtonHandler }>Create</button>
				</div> : <Spinner /> }
			</div>
		</div>
	)
}

const mapPropsToState = ( state ) =>
{
	return {
		loader: state?.user?.userLoader,
		userData: state?.user?.userData,
		dbUser: state?.user?.dbUser,
	}
}

export default connect( mapPropsToState, { updateUsername, getUserDetailsById } )( ( withRouter( Username ) ) );
