import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import './CreateTemplate.scss'
import { createTemplate } from '../../../../actions/Templates'
import Spinner from '../../../../Utilities/Spinner/Spinner'

const CreateTemplate = ( { templateLoader, createTemplate } ) =>
{
	const history = useHistory()
	const [ templateDetails, setTemplateDetails ] = useState( {
		name: "",
		category: "",
	} )
	const CreateTemplateHandler = () =>
	{
		createTemplate( templateDetails, history )
	}
	return (
		<div className='create-template'>
			<div className="template-form">
				<div className="input-box" >
					<div className="form-control" style={ { width: "100%" } }>
						<label className='form-label' htmlFor="component-outlined">Template Name</label>
						<input
							required
							placeholder="Office"
							value={ templateDetails[ "name" ] }
							onChange={ ( e ) =>
							{
								setTemplateDetails( { ...templateDetails, name: e.target.value } )
							} }
							label="Plan Name"
						/>
					</div>
					<div className="form-control" style={ { width: "100%" } }>
						<label className='form-label' htmlFor="component-outlined">Template Category</label>
						<input
							required
							placeholder="Architecture"
							value={ templateDetails[ "category" ] }
							onChange={ ( e ) =>
							{
								setTemplateDetails( { ...templateDetails, category: e.target.value } )
							} }
							label="Plan Name"
						/>
					</div>
				</div>
			</div>
			{ templateLoader === false ? <div className="actions">
				<button
					className='btn'
					onClick={ CreateTemplateHandler }
				>Create Template</button>
			</div> : <Spinner /> }
		</div>
	)
}

const mapPropsToState = ( state ) =>
{
	return {
		templateLoader: state?.template?.templatesLoader,
	}
}

export default connect( mapPropsToState, { createTemplate } )( ( withRouter( CreateTemplate ) ) );



