const INITIAL_STATE = {
	tour: localStorage.getItem( "tour" )
};

export const tourReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "TOUR_UPDATE":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				tour: action.payload.tour,
			};
		default:
			return state;
	}
}

