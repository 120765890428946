import React, { useEffect, useState } from 'react'
import './Verify.scss'
import useAuth from '../../../../hooks/useAuth'
import logo from '../../../../images/Xarwin-logo-dark.png'
import { useHistory } from 'react-router-dom'

const Verify = () =>
{
	const { handleEmailVerification, user } = useAuth()
	const [ mailSent, setMailSent ] = useState( false )
	const history = useHistory()

	useEffect( () =>
	{
		handleEmailVerification()
	}, [] )


	const emailverificationButtonHandler = () =>
	{
		setMailSent( true )
		handleEmailVerification()
	}
	const continueHandler = () =>
	{
		history.push( "/register/username" )
	}
	return (
		<div className='verify-wrapper'>
			<div className="verify-container">
				<div className="header">
					<img src={ logo } alt="" />
					<h1>Verify your Mail!</h1>
				</div>
				<div className='content'>
					<p>
						Verify your E-Mail: Check you E-Mails (Spam folder
						included) for a confirmation E-Mail or send
						another confirmation E-Mail.
					</p>
				</div>
				<div className="actions">
					{ mailSent === false ? <button
						type="button"
						onClick={ emailverificationButtonHandler }
					>
						Send verification E-Mail
					</button> : <button
						type="button"
					>
						Verification Mail Sent!
					</button> }
					<br />
					<button onClick={ continueHandler }>Continue</button>
				</div>
			</div>
		</div>
	)
}

export default Verify