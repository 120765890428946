/* eslint-disable import/no-anonymous-default-export */

export default ( editor, opts = {} ) =>
{
	const bm = editor.BlockManager;
	const styles = `
	<style>
	.qr-poster {
		width: fit-content;
		background-color: #5E5CCD;
		padding: 10px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.qr-poster>.qr-box {
		background-color: white;
		border-radius: 10px;
		padding: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}


	.qr-poster>.qr-box>p {
		color: black;
		font-family: 'Montserrat';
		font-size: 15px;
		font-weight: bold;
	}
	  </style> 
	  `

	bm.add( opts.name, {
		label: `
		<i class="fa fa-qrcode"></i>
		<br />
	  <div class="gjs-block-label">
		${opts.label}
	  </div> 
	  `,
		category: opts.category,
		content: `
		<div class="qr-poster">
				<div class="qr-box">
					<div id="qr-code">
						<img src="https://chart.apis.google.com/chart?cht=qr&chs=300x300&chl=https://app.xarwin.com"
							alt="" />
					</div>
					<p>Scan to Experience without downloading an app</p>
				</div>
			</div>${styles}
		`,
	} );
};
