import React, { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useHistory, withRouter } from 'react-router-dom';
import "./AddModel.css"
import Spinner from '../../../Utilities/Spinner/Spinner';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import { useDropzone } from "react-dropzone";
import { createModel } from '../../../actions/Models';
import { connect } from 'react-redux';
import TourStep from '../../Components/Tour/TourStep';
import { BiHelpCircle } from 'react-icons/bi';

const AddModel = ( { createModel, modelLoader, tour, uploadModelPercentage } ) =>
{
    const { acceptedFiles, getRootProps, getInputProps } =
        useDropzone();
    const history = useHistory();
    const [ modelData, setModelData ] = useState( { Model_Name: "", Description: "", Type: "Original", Format_Source: { "GLTF": "", "USDZ": "" } } )
    const [ publish, setPublish ] = useState( { android: false, ios: false } )
    const [ type, setType ] = useState( "" )

    const [ modelFile, setModelFile ] = useState( {
        android: null, ios: null,
    } );
    const steps = [
        {
            element: "#create-model-name",
            intro: "Name your model"
        },
        {
            element: "#create-model-description",
            intro: "Define your model features and speciality."
        },
        {
            element: "#create-model-choose",
            intro: "Choose where you want to publish your models."
        },
        {
            element: "#create-model-choose",
            intro: "Android supports GLTF,GLB,OBJ and IOS supports USDZ model files"
        },
    ]



    const handleModelFile = ( type ) =>
    {
        var file = acceptedFiles[ 0 ]
        console.log( file )
        console.log( file[ "name" ].split( "." ) )
        var ext = file[ "name" ].split( "." ).pop().toLowerCase()
        if ( type === "android" )
        {
            if ( ext === 'glb' || ext === 'obj' || ext === 'zip' )
            {
                console.log( "GLB" )
                console.log( modelFile )
                console.log( setModelFile )
                setModelFile( { ...modelFile, android: file, } )
                setModelData( { ...modelData, Format_Source: { ...modelData[ "Format_Source" ], "GLTF": "" } } )
                setPublish( { ...publish, android: !publish[ "android" ] } )
            } else
            {

                Swal.fire( {
                    icon: 'error',
                    title: 'Incorrect file type!',
                    text: 'Only glb format,obj format and GLTF(as a zip file) format are supported.',
                } )
                setModelFile( { ...modelFile, android: null } )
                setModelData( { ...modelData, Format_Source: { ...modelData[ "Format_Source" ], "GLTF": "" } } )
            }
        } else if ( type === "ios" )
        {
            if ( ext === 'usdz' )
            {
                setModelFile( { ...modelFile, ios: file } )
                setModelData( { ...modelData, Format_Source: { ...modelData[ "Format_Source" ], "USDZ": "" } } )
                setPublish( { ...publish, ios: !publish[ "ios" ] } )
            } else
            {
                Swal.fire( {
                    icon: 'error',
                    title: 'Incorrect file type!',
                    text: 'Only USDZ format is supported.',
                } )
                setModelFile( { ...modelFile, ios: null } )
                setModelData( { ...modelData, Format_Source: { ...modelData[ "Format_Source" ], "USDZ": "" } } )
            }
        }
        console.log( modelFile )
    }


    const handleSubmit = ( e ) =>
    {
        console.log( "submit" )
        e.preventDefault()
        if ( publish[ "android" ] === false && publish[ "ios" ] === false )
        {
            Swal.fire( { title: "Please choose a publish type" } )
        } else
        {
            createModel( modelData, modelFile, history )
        }
    }

    const [ current, setCurrent ] = useState( -1 );
    const faqs = [
        {
            question: 'I do not have a .glb or .usdz file',
            answer: [
                <>You can use any of these converters to convert your model to .glb file.<br />1. Use an online converter such as <a href='https://www.creators3d.com/online-viewer' target='_blank'>https://www.creators3d.com/online-viewer</a> <br />2. Use CLI tool here ( hyperlink : <a href='https://github.com/facebookincubator/FBX2glTF' target='_blank'>https://github.com/facebookincubator/FBX2glTF</a>)<br />3. Export your 3D model out of your 3D modeling software.</>,
            ],
        },
    ];


    return (
        <div className='add-model-form'>
            { tour != "false" && <TourStep steps={ steps } tourReducer={ tour } /> }
            <form>
                <div className="form">
                    <div id="create-model-name" className=" align-items-center">
                        <label className='col-md-4'>Enter a name for your model*</label>
                        <input
                            className="col-sm-12 col-md-8 input-field"
                            onChange={
                                ( e ) => setModelData(
                                    {
                                        ...modelData,
                                        Model_Name: e.target.value
                                    } )
                            }
                            type="text"
                            placeholder='Ex: Wooden Chair'
                            required={ true } />
                    </div>
                    <div id="create-model-description" className=" align-items-center">
                        <label className='col-md-4'>How do you describe your model?*</label>
                        <input
                            className="col-sm-12 col-md-8 input-field"
                            onChange={
                                ( e ) => setModelData(
                                    {
                                        ...modelData,
                                        Description: e.target.value
                                    } )
                            }
                            type="text"
                            placeholder='Furniture made with teak wood for living room' required={ true } />
                    </div>
                    <div id="create-model-choose" className="row align-items-center">
                        <label className='col-md-4'>Publish my model in both Android and IOS*</label>
                        <div className="col-md-8">
                            <input
                                type="checkbox"
                                checked={ publish[ "android" ] } data-bs-toggle="modal" data-bs-target="#myModal"
                                onChange={ () =>
                                {
                                    if ( publish[ "android" ] === true )
                                    {
                                        setModelFile( { ...modelFile, android: null } )
                                        setModelData( { ...modelData, Format_Source: { ...modelData[ "Format_Source" ], "GLTF": "" } } )
                                        setPublish( { ...publish, android: false } )
                                    }
                                    setType( "android" )
                                } } /> GLB &nbsp; &nbsp; &nbsp;
                        </div>
                    </div>
                    <br />
                    <div id="create-model-choose" className="row align-items-center">
                        <label className="col-md-4">I will add my own USDZ model for IOS</label>
                        <div className="col-md-8">
                            <input type="checkbox"
                                checked={ publish[ "ios" ] }
                                data-bs-toggle="modal" data-bs-target="#myModal"
                                onChange={ () =>
                                {
                                    if ( publish[ "ios" ] === true )
                                    {
                                        setModelFile( { ...modelFile, ios: null } )
                                        setModelData( { ...modelData, Format_Source: { ...modelData[ "Format_Source" ], "USDZ": "" } } )
                                        setPublish( { ...publish, ios: false } )
                                    }
                                    setType( "ios" )
                                } } /> USDZ
                        </div>
                    </div>
                    <div className="row d-flex">
                        {
                            modelFile[ "android" ] !== null && <div className='file-box'>
                                <label>Android</label>
                                <p> { modelFile[ "android" ].name }</p>
                            </div>
                        }
                        {
                            modelFile[ "ios" ] !== null && <div className='file-box'>
                                <label>IOS</label>
                                <p> { modelFile[ "ios" ].name }</p>
                            </div>
                        }
                    </div>
                    <div className="row d-flex">
                        {
                            modelData[ "Format_Source" ][ "GLTF" ] !== "" && <div className='file-box'>
                                <label>Android</label>
                                <p> { modelData[ "Format_Source" ][ "GLTF" ] }</p>
                            </div>
                        }
                        {
                            modelData[ "Format_Source" ][ "USDZ" ] !== "" && <div className='file-box'>
                                <label>IOS</label>
                                <p> { modelData[ "Format_Source" ][ "USDZ" ] }</p>
                            </div>
                        }
                    </div>
                    { modelLoader === false ? <div className="actions">
                        <button
                            type="submit"
                            className='createButton'
                            onClick={ handleSubmit }
                        >Create Model</button>
                    </div> : <div style={ {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    } }>
                        <Spinner />
                        <p>Uploading { uploadModelPercentage }</p>
                    </div> }
                </div>
            </form>

            <div className="modal fade" id="myModal">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <label></label>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div { ...getRootProps( { className: "dropzone input-file" } ) }>
                            <input
                                { ...getInputProps() }
                            />
                            <br />
                            <h4 className='text-center'>{ acceptedFiles.length == 0 ? "Drag your file here!" : acceptedFiles[ 0 ].name }</h4>
                            <p>Provide GLB file, OBJ file or GLTF(as Zip) for Android</p>
                            <p>Provide USDZ file for IOS</p>
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                        <p className='text-center'>Or</p>
                        <div className='mx-2'>
                            <label >Add Model URL</label>
                            <input
                                className="input-field"
                                type="text"
                                placeholder='Model URL'
                                onChange={ ( e ) =>
                                {
                                    if ( type === "android" )
                                    {
                                        setPublish( { ...publish, android: !publish[ "android" ] } )
                                        setModelData( { ...modelData, Format_Source: { ...modelData[ "Format_Source" ], "GLTF": e.target.value } } )
                                    } else if ( type === "ios" )
                                    {
                                        setPublish( { ...publish, ios: !publish[ "ios" ] } )
                                        setModelData( { ...modelData, Format_Source: { ...modelData[ "Format_Source" ], "USDZ": e.target.value } } )
                                    }
                                } }
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={ () =>
                                {
                                    if ( acceptedFiles.length !== 0 )
                                    {
                                        handleModelFile( type )
                                        acceptedFiles.length = 0
                                    }
                                } }
                                data-bs-dismiss="modal"
                            >Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='FaqContainer'>
                { faqs.map( ( faq, index ) => (
                    <div className="FaqItem" key={ index } onClick={ () => setCurrent( index ) }>
                        { current === index ? (
                            <>
                                <div className="Question" >
                                    <p>{ faq.question }</p>
                                    <BiHelpCircle size="25" />
                                </div>
                                <div className='Answer active'>
                                    <ol type='a'>
                                        { faq.answer.map( ( answer, index ) => (
                                            <li key={ index }>{ answer }</li>
                                        ) ) }
                                    </ol>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="Question" >
                                    <p>{ faq.question }</p>
                                    <BiHelpCircle size="25" />
                                </div>
                                <div className='Answer'>
                                    <ol type='a'>
                                        { faq.answer.map( ( answer, index ) => (
                                            <li key={ index }>{ answer }</li>
                                        ) ) }
                                    </ol>
                                </div>
                            </>
                        ) }
                    </div>
                ) ) }
            </div>
        </div>
    );
};


const mapPropsToState = ( state ) =>
{
    return {
        modelLoader: state?.model?.modelsLoader,
        uploadModelPercentage: state?.model?.uploadModelPercentage,
        tour: state?.tour?.tour,
    }
}

export default connect( mapPropsToState, { createModel } )( ( withRouter( AddModel ) ) );

















