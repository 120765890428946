import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getUserDetailsById, updateUserInfo } from '../../../actions/User'
import Spinner from '../../../Utilities/Spinner/Spinner'
import ViewUser from './Profile'

const EditUser = ( { getUserDetailsById, updateUserInfo, currentUser, userLoader } ) =>
{
  const [ user, setUser ] = useState( currentUser )

  useEffect( () =>
  {
    getUserDetailsById()
  }, [] )

  useEffect( () =>
  {
    setUser( currentUser )
  }, [ currentUser ] )


  return (
    <div>
      {
        userLoader === false ?
          user ? <div className='edit-user'>
            <ViewUser user={ user } updateUserInfo={ updateUserInfo } />
          </div> : "User Not Found"
          :
          <Spinner />
      }
    </div>
  )
}


const mapPropsToState = ( state ) =>
{
  return {
    currentUser: state?.user?.dbUser,
    userLoader: state?.user?.userLoader,
  }
}

export default connect( mapPropsToState, { getUserDetailsById, updateUserInfo } )( ( withRouter( EditUser ) ) );
