import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { dashboardReducer } from "./reducers/DashboardReducer.js";
import { editorReducer } from "./reducers/EditorReducer.js";
import { modelReducer } from "./reducers/ModelReducer.js";
import { projectReducer } from "./reducers/ProjectReducer.js";
import { subscriptionplansReducer } from "./reducers/SubscriptionPlans.js";
import { tourReducer } from "./reducers/TourReducer.js";
import { userReducer } from "./reducers/UserReducer.js";
import { templatesReducer } from "./reducers/TemplateReducer.js";


import thunk from 'redux-thunk'
import { itProjectReducer } from "./reducers/ITProjectReducer.js";

const appReducer = combineReducers( {
	user: userReducer,
	modelEditor: editorReducer,
	dashboard: dashboardReducer,
	plans: subscriptionplansReducer,
	model: modelReducer,
	project: projectReducer,
	tour: tourReducer,
	template: templatesReducer,
	itProject: itProjectReducer,
} );

const rootReducer = ( state, action ) =>
{
	if ( action.type === "USER_LOGOUT" )
	{
		storage.removeItem( "persist:root" );
		state = undefined;
		return appReducer( undefined, action );
	} else
	{
		return appReducer( state, action );
	}
};
const persistConfig = {
	key: "root",
	storage,
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer( persistConfig, rootReducer, applyMiddleware( thunk ), );

export const store = createStore(
	persistedReducer,
	composeEnhancers( applyMiddleware( thunk ) )
);
export const persistedStore = persistStore( store );
// persistedStore,store}
