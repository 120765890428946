import React, { useState } from 'react'
import './Profile.css'
import Swal from 'sweetalert2'
import StyledTextField from '../../../Utilities/styles/global.styles'


const ViewUser = ( { updateUserInfo, user } ) =>
{
  const [ userValue, setUserValue ] = useState( {
    Name: user.Name,
    Email: user.Email
  } )
  const fileHandler = ( e ) =>
  {
    const file = e.target.files[ 0 ]
    console.log( file )
    if ( [ 'jpeg', 'jpg', 'png' ].includes( file.name.split( "." )[ 1 ].toLowerCase() ) )
    {
      setUserValue( { ...userValue, profile: file } )
    } else
    {
      Swal.fire( "Please select a image" )
    }
  }

  const updateButtonHandler = () =>
  {
    const userInfo = new FormData()
    userInfo.append( 'Name', userValue[ "Name" ] )
    userInfo.append( 'Email', userValue[ "Email" ] )
    userInfo.append( 'file', userValue[ "profile" ] )
    updateUserInfo( userInfo, user._id )
  }

  return (
    <div className='view-user' >
      <div className="left-side">
        <img src={ userValue[ "profile" ] ? URL.createObjectURL( userValue[ "profile" ] ) : user[ "Profile_Picture" ] ? user[ "Profile_Picture" ] : "https://imgs.search.brave.com/yFal3Br6dsTTL4qSOoW-pvpWug8lcc8g_6B3Mp7ujIk/rs:fit:840:881:1/g:ce/aHR0cHM6Ly93d3cu/cG5nZmluZC5jb20v/cG5ncy9tLzYxMC02/MTA0NDUxX2ltYWdl/LXBsYWNlaG9sZGVy/LXBuZy11c2VyLXBy/b2ZpbGUtcGxhY2Vo/b2xkZXItaW1hZ2Ut/cG5nLnBuZw" } />
        <div class="file-input">
          <input type="file" name="files" id="file" class="file" onChange={ fileHandler } />
          <label for="file">
            Select file
            <p class="file-name"></p>
          </label>
        </div>
      </div>
      <div className='right-side'>
        <div className='input-box'>
          <div>
            <p>Name</p>
            <StyledTextField
              className="inputField"
              label="Name"
              defaultValue={ userValue.Name }
              variant="outlined"
              onChange={
                ( e ) => setUserValue( { ...userValue, Name: e.target.value } )
              }
              required
            />
          </div>
          <div>
            <p>Email</p>
            <StyledTextField
              className="inputField"
              defaultValue={ userValue.Email }
              readOnly
              variant="outlined"
              required
              onChange={
                ( e ) => setUserValue( { ...userValue, Email: e.target.value } )
              }
            />
          </div>
          <div>
            <p>User ID</p>
            <StyledTextField
              className="inputField"
              label="User ID"
              defaultValue={ user._id }
              readOnly
              variant="outlined"
              required
            />
          </div>
          <div>
            <p>Firebase ID</p>
            <StyledTextField
              className="inputField"
              label="Firebase ID"
              defaultValue={ user.Firebase_Id }
              readOnly
              variant="outlined"
              required
            />
          </div>
          <h5>No of Models: <span><h2>{ user[ "Model_Id" ].length }</h2></span> </h5>
          <h5>No of Projects: <span><h2>{ user[ "Project_Id" ].length }</h2></span> </h5>
        </div>
        <div className="actions">
          <button
            className='btn btn-secondary btn-lg'
            onClick={ () => updateButtonHandler() }>
            Save
          </button>
          { user.Ban === true && <button
            className='btn btn-warning btn-lg'
          >
            Banned
          </button> }
        </div>
      </div>
    </div>
  )
}


export default ViewUser