import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import GoogleLogo from "./img/google-logo.png";
import useAuth from '../../../../hooks/useAuth';
import Spinner from "../../../../Utilities/Spinner/Spinner";
import { connect, useDispatch } from "react-redux";
import './Register.css'

const Register = ( { loader } ) =>
{
  const { serverUrl, handleUserRegister, googleSignin } = useAuth()
  const history = useHistory();
  const [ showPassword, setShowPassword ] = useState( false );
  const [ focused, setFocused ] = useState( {
    email: false,
    name: false,
    website: false,
    password: false,
    confirmPassword: false,
  } );
  const [ formData, setFormData ] = useState( {
    email: "",
    name: "",
    website: "",
    password: "",
    confirmPassword: "",
  } );
  const dispatch = useDispatch()

  const handleSubmit = async ( e ) =>
  {
    e.preventDefault();
    const newUser = { ...formData };

    // validate the form
    if (
      newUser.name === "" ||
      newUser.email === "" ||
      newUser.password === "" ||
      newUser.confirmPassword === ""
    )
    {
      alert( "Please enter the required fields" );
      return;
    } else if ( newUser.password !== newUser.confirmPassword )
    {
      alert( "Both the Passwords should match!" )
      return;
    }
    dispatch( { type: "USER_LOADER" } )
    handleUserRegister( newUser, history )



    return () =>
    {
      setFormData( {
        email: "",
        name: "",
        website: "",
        password: "",
        confirmPassword: "",
      } );
    };
  };

  const updateForm = ( value ) =>
  {
    setFormData( ( prev ) =>
    {
      return { ...prev, ...value };
    } );
  };

  const toggleShowPassword = () =>
  {
    setShowPassword( !showPassword );
  };

  const handleFocus = ( value ) =>
  {
    setFocused( ( prev ) =>
    {
      return { ...prev, ...value };
    } );
  };

  // google login
  const googleSignUpHandler = () =>
  {
    dispatch( { type: "USER_LOADER" } )
    googleSignin( history );
  };

  return (
    <div className="register-wrapper">
      <div className="register-form-container">
        <h1>New to xARwin?</h1>
        <h4>Register Now!</h4>
        <div className="register-form" onSubmit={ handleSubmit }>

          {/* Email */ }
          <div className="formgroup">
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              value={ formData.email }
              placeholder="johnsmith123@email.com"
              required
              onBlur={ ( e ) => handleFocus( { email: true } ) }
              focused={ focused.email.toString() }
              onChange={ ( e ) => updateForm( { email: e.target.value } ) }
            />
            <span className="errorMessage">
              It should be a valid email address!
            </span>
          </div>

          {/* FirstName, LastName */ }
          <div className="formgroup" >
            <label>Name</label>
            <input
              type="text"
              value={ formData.name }
              name="name"
              pattern="[A-Za-z ]{3,16}"
              placeholder="John Smith"
              required
              onBlur={ ( e ) => handleFocus( { name: true } ) }
              focused={ focused.name.toString() }
              onChange={ ( e ) => updateForm( { name: e.target.value } ) }
            />
            <span className="errorMessage">
              Name should be 3-30 characters and shouldn't include any special
              characters!
            </span>
          </div>

          {/* Password */ }
          <div className="formgroup">
            <label>Password</label>
            <input
              type={ showPassword ? "text" : "password" }
              name="password"
              value={ formData.password }
              pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$"
              required
              onBlur={ ( e ) => handleFocus( { password: true } ) }
              focused={ focused.password.toString() }
              onChange={ ( e ) => updateForm( { password: e.target.value } ) }
            />
            <div onClick={ toggleShowPassword } className="eyeIcon">
              { showPassword ? <AiFillEyeInvisible /> : <AiFillEye /> }
            </div>
            <span className="errorMessage">
              Password should be 8-16 characters and include both uppercase and
              lower case characters. It should include at least one number and
              one special character!
            </span>
          </div>

          {/* Confirm Password */ }
          <div className="formgroup">
            <label>Confirm Password</label>
            <input
              type="password"
              value={ formData.confirmPassword }
              name="password"
              pattern={ formData.password }
              required
              onBlur={ ( e ) => handleFocus( { confirmPassword: true } ) }
              onFocus={ ( e ) => handleFocus( { confirmPassword: true } ) }
              focused={ focused.confirmPassword.toString() }
              onChange={ ( e ) => updateForm( { confirmPassword: e.target.value } ) }
            />
            <span className="errorMessage">Passwords don't match!</span>
          </div>

          {/* Captcha */ }
          {/* Terms and Conditions */ }

          { loader === false ? <div className="register-buttons">
            <button type="submit" onClick={ handleSubmit }>
              Register
            </button>
            <div className="orDivider">
              <span />
              OR
              <span />
            </div>
            <button
              className="social-button"
              type="submit"
              onClick={ googleSignUpHandler }
            >
              <img src={ GoogleLogo } alt="google-logo" />
              Sign up with Google
            </button>
          </div> : <Spinner /> }
        </div>
      </div>
    </div>
  );
};

const mapPropsToState = ( state ) =>
{
  return {
    loader: state?.user?.userLoader,
  }
}

export default connect( mapPropsToState, {} )( ( withRouter( Register ) ) );


