import { useHistory } from 'react-router-dom';

const linkCard = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem"
}

const linkCardContainer = {
  marginBottom: "10px",
}

const h3 = {
  fontWeight: "400",
  fontSize: "1.2rem",
  margin: 0,
}

const button = {
  background: "#6773E7",
  color: "white",
  border: "none",
  padding: "0.5rem 1rem",
  borderRadius: "5px",
  width: "160px",
}


const Links = () =>
{
  const history = useHistory()

  const createExperienceHandler = () =>
  {
    history.push( "/create-project" )
  }

  const uploadModelHandler = () =>
  {
    history.push( "/add-model" )
  }

  const websitesHandler = () =>
  {
    history.push( "/projects" )
  }


  return (
    <div>
      <div style={ linkCardContainer }>
        <div style={ linkCard }>
          <h3 style={ h3 }>Create an Experience</h3>
          <button style={ button } onClick={ createExperienceHandler }>Xarwin It</button>
        </div>
      </div>
      <div style={ linkCardContainer }>
        <div style={ linkCard }>
          <h3 style={ h3 }>Upload your model</h3>
          <button style={ button } onClick={ uploadModelHandler }>Upload Model</button>
        </div>
      </div>
      <div style={ linkCardContainer }>
        <div style={ linkCard }>
          <h3 style={ h3 }>Access your Experiences</h3>
          <button style={ button } onClick={ websitesHandler }>My Experiences</button>
        </div>
      </div>
    </div>
  );
};

export default Links;
