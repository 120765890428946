import GoogleLogo from "./img/google-logo.png";
import FacebookLogo from "./img/facebook-logo.png";
import useAuth from "../../../../hooks/useAuth";
import React, { useEffect, useState } from "react";
import { useHistory, Link, Redirect, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Spinner from "../../../../Utilities/Spinner/Spinner";
import './Login.css'
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";


const Login = ( { loader, user } ) =>
{
  const { handleUserLogin, error, logOut, googleSignin, facebookSignin } =
    useAuth();
  const [ email, setEmail ] = useState( "" );
  const [ password, setPassword ] = useState( "" );
  const [ showPassword, setShowPassword ] = useState( false );
  const [ agree, setAgree ] = useState( false );
  const history = useHistory();
  const dispatch = useDispatch()

  useEffect( () =>
  {
    dispatch( { type: "CLOSE_USER_LOADER" } )
  }, [] )


  // capture the email
  const hanldeEmail = ( e ) =>
  {
    setEmail( e.target.value );
    console.log( email );
  };

  // capture the password
  const hanldePassword = ( e ) =>
  {
    setPassword( e.target.value );
    console.log( password );
  };

  const handleLogin = () =>
  {
    if ( email !== "" && password !== "" )
    {
      dispatch( { type: "USER_LOADER" } )
      handleUserLogin( email, password, history );
    }
  };


  // google login
  const googleLogin = () =>
  {
    dispatch( { type: "USER_LOADER" } )
    googleSignin( history );
  };

  // facebook login
  const facebookLogin = () =>
  {
    dispatch( { type: "USER_LOADER" } )
    facebookSignin( history );
    console.log( user );
  };

  const signUp = () =>
  {
    history.push( "/register" );
  };
  if ( user.email )
    return <Redirect to="/dashboard" />

  return (
    <div className="auth-wrapper">
      <div className="login-form-container">
        <h1>Hello ! Welcome back.</h1>
        <p>Log in with your credentials</p>
        { error ? (
          <p style={ { color: "red" } }>
            { " " }
            enter a valid email address or password{ " " }
          </p>
        ) : (
          <p></p>
        ) }
        <div className="login-form">
          <div className="formgroup">
            <label>Email Address</label>
            <input
              type="text"
              name="email"
              placebolder="Email Address"
              onBlur={ hanldeEmail }
            />
          </div>
          <div className="formgroup">
            <label>
              Password
            </label>
            <div className="input-group mb-3">
              <input
                type={ showPassword === false ? "password" : "text" }
                name="password"
                placebolder="Password"
                onBlur={ hanldePassword }
              />
              <span onClick={ () => setShowPassword( !showPassword ) } className="input-group-text">{ showPassword === false ? <AiFillEyeInvisible /> : <AiFillEye /> }</span>
              <div className="forgot-password-link">
                <Link to="/forgot-password">
                  <span>Forgot Password?</span>
                </Link>
              </div>
            </div>
          </div>
          <br />

          { loader === false ? <div className="login-buttons">
            <button onClick={ handleLogin }>Log in</button>
            <br />
            <div className="orDivider">
              <span />
              OR
              <span />
            </div>

            <div className="social-button" onClick={ googleLogin }>
              <img src={ GoogleLogo } alt="google-logo" />
              <span>Sign in with Google</span>
            </div>

            {/* <div className="social-button" onClick={ facebookLogin }>
              <img src={ FacebookLogo } alt="google-logo" />
              <span>Sign in with Facebook</span>
            </div> */}
            <h4>
              Don't have an account?{ " " }
              <button onClick={ signUp }>
                Sign Up
              </button>
            </h4>
          </div> : <Spinner /> }

        </div>
      </div>
    </div>
  );
};

const mapPropsToState = ( state ) =>
{
  return {
    loader: state?.user?.userLoader,
    user: state?.user?.userData,
  }
}

export default connect( mapPropsToState, {} )( ( withRouter( Login ) ) );

