import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import ReactGa4 from 'react-ga4'
import { clarity } from 'react-microsoft-clarity';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistedStore } from './redux/store.js'
import AuthProvider from './context/AuthProvider';

const domain = "dev-nbja26q6.us.auth0.com";
const clientId = "ZL1evBQqyEma1qXCU06yMFvyv8a1xb0e";
console.log( domain, clientId )

const trackingCode = process.env.REACT_APP_TRACKING_CODE
ReactGa4.initialize( trackingCode )
clarity.init( process.env.REACT_APP_CLARITY_CODE );

ReactDOM.render(
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistedStore }>
      <AuthProvider>
        <App />
      </AuthProvider>
    </PersistGate>
  </Provider>,
  document.getElementById( 'root' )
);
