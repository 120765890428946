const INITIAL_STATE = {
	userData: {},
	dbUser: null,
	token: null,
	userError: null,
	userLoader: false,
};

export const userReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "USER_UPDATE":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				userData: action.payload.user,
				userError: null,
				userLoader: false,
			};
		case "USER_DB_UPDATE":
			return {
				...state,
				dbUser: action.payload.dbUser,
				userError: null,
				userLoader: false,
			}
		case "TOKEN_UPDATE":
			return {
				...state,
				token: action.payload.taken,
				userError: null,
				userLoader: null,
			}
		case "USER_LOADER":
			return {
				...state,
				userLoader: !state.userLoader,
				userError: null,
			}
		case "CLOSE_USER_LOADER":
			return {
				...state,
				userLoader: false,
				userError: null,
			}
		case "USER_ERROR":
			return {
				...state,
				userError: action.payload.error,
				userLoader: false,
			}
		default:
			return state;
	}
}

