import grapesjs from "grapesjs";
import loadBlocks from './blocks.js'
import loadComponents from './components.js'

export default grapesjs.plugins.add( "qrCodeComponent", ( editor, options ) =>
{
	console.log( options )
	let opts = {
		label: 'QR Code',
		name: 'QR Code',
		category: "Basic",
	};

	loadBlocks( editor, opts );
	loadComponents( editor, opts );
} );

