const INITIAL_STATE = {
	itProjects: [],
	currentITProject: null,
	itProjectsError: null,
	itProjectsLoader: false,
};

export const itProjectReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "IT_PROJECT_UPDATE":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				itProjects: action.payload.itProjects,
				itProjectsError: null,
				itProjectsLoader: false,
			};
		case "CURRENT_IT_PROJECT_UPDATE":
			return {
				...state,
				currentITProject: action.payload.currentITProject,
				itProjectsError: null,
				itProjectsLoader: false,
			};
		case "IT_PROJECT_LOADER":
			return {
				...state,
				itProjectsLoader: !state.itProjectsLoader,
				itProjectsError: null,
			}
		case "IT_PROJECT_ERROR":
			return {
				...state,
				itProjectsError: action.payload.error,
				itProjectsLoader: false,
			}
		default:
			return state;
	}
}

