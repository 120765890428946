import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import './ForgotPassword.css'

const ForgotPassword = () =>
{
  const [ email, setEmail ] = useState( "" );
  const [ displayMessage, setDisplayMessage ] = useState( "" );
  const { handleForgotPassword, error } = useAuth();

  const updateForm = ( value ) =>
  {
    setEmail( ( prev ) => value );
  };

  const forgotPasswordHandler = () =>
  {
    handleForgotPassword( email )
  };

  return (
    <div className="forgot-password-wrapper">
      <div className="forgot-password-form-container">
        <h1>Forgot Password</h1>
        <div className="forgot-password-form">
          <div className="form-group">
            <label>Email Address</label>
            <input
              type="email"
              name="email"
              value={ email }
              onChange={ ( e ) => updateForm( e.target.value ) }
              placeholder="Enter your registered email id"
              required
            />
          </div>

          { error ? (
            <div className="error">{ displayMessage }</div>
          ) : (
            <div className="success">{ displayMessage }</div>
          ) }

          <button type="submit" onClick={ handleForgotPassword }>
            Reset Password
          </button>
          <p>
            Return back to <Link to="/login">Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
