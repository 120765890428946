import React from 'react'
import { useEffect, useState } from 'react'
import './Plans.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getAllPlans } from '../../../../actions/SubscriptionPlans'
import PlanCard from '../../../Components/Plancard/PlanCard'
import Spinner from '../../../../Utilities/Spinner/Spinner'
import { Button } from 'react-bootstrap'
import { getUserDetailsById } from '../../../../actions/User'

const AllPlans = ( { getUserDetailsById, getAllPlans, plansLoader, plans, userData, dbUser } ) =>
{
	const [ allPlans, setAllPlans ] = useState( [] )
	const [ monthly, setMonthly ] = useState( false )
	const [ priceCode, setPriceCode ] = useState( "USD" )
	useEffect( () =>
	{
		console.log( userData )
		console.log( userData.length )
		console.log( userData.length )
		if ( userData[ "uid" ] !== undefined )
		{
			getAllPlans();
			getUserDetailsById();
		}
	}, [ userData ] )

	useEffect( () =>
	{
		console.log( plans )
		setAllPlans( plans )
	}, [ plans ] )

	console.log( dbUser )
	return (
		<div style={ { width: "98%" } }>
			<h1 className='text-center m-0'>Choose Your Plan</h1>
			{
				plansLoader === false ? <div className='all_plans'>
					<div className="header d-flex align-items-center justify-content-center">
						<div className="d-flex align-items-center">Monthly&nbsp; <label className="switch">
							<input type="checkbox" checked={ monthly } onChange={ () => setMonthly( !monthly ) } />
							<span className="slider round"></span>
						</label>&nbsp;Yearly</div>
						&nbsp;&nbsp;&nbsp;
						<div className='price-code'>
							<label htmlFor="">Currency</label>
							<select value={ priceCode } onChange={ ( e ) => setPriceCode( e.target.value ) }>
								<option value="USD">USD</option>
								<option value="INR">INR</option>
							</select>
						</div>
					</div>
					{ dbUser !== undefined && dbUser !== null ? <div className="plans">
						<div className='plans-container'>
							<div id="plan-label-card">
								<div className="plan_title">
									<h5>PLAN</h5>
								</div>
								<div className="plan_details">
									<div className="plan_features">
										<ul>
											<li className="price">Price</li>
											<li>Views</li>
											<hr />
											<li> Storage</li>
											<hr />
											<li>WebAR Experience</li>
											<hr />
											<li>Watermark</li>
											<hr />
											<li>Templates</li>
											<hr />
											<li>Custom Domain</li>
											<hr />
											<li>Analytics</li>
											<hr />
											<li>Community Support</li>
											<hr />
											<li>Dedicated Support</li>
											<hr />
											<li>No Code</li>
											<hr />
										</ul>
									</div>
									<div className="actions">
										<Button
											className="dummy"
										>Subscribe</Button>
									</div>
								</div>

							</div>

							{
								allPlans.map( ( plan, index ) => (
									<PlanCard plan={ plan } key={ index } index={ index } monthly={ monthly } priceCode={ priceCode } userDetails={ dbUser } />
								) )
							}
						</div>
					</div> : <Spinner /> }
				</div> : <Spinner />
			}
		</div>
	)
}

const mapPropsToState = ( state ) =>
{
	return {
		plans: state?.plans?.plans,
		plansLoader: state?.plans?.plansLoader,
		userData: state?.user?.userData,
		dbUser: state?.user?.dbUser,
	}
}

export default connect( mapPropsToState, { getUserDetailsById, getAllPlans } )( ( withRouter( AllPlans ) ) );

