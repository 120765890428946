import grapesjs from "grapesjs";
import loadBlocks from './blocks.js'
import loadComponents from './components.js'

export default grapesjs.plugins.add( "modelviewerComponent", ( editor, options ) =>
{
	console.log( options )
	options[ "models" ].map( ( model ) =>
	{
		console.log(model)
		let opts = {
			label: model[ "Model_Name" ],
			name: model[ "Model_Name" ],
			category: "Models",
		};

		loadBlocks( editor, opts, model );
		loadComponents( editor, opts );
	} )
} );

