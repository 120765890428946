import grapesjs from "grapesjs";
import $ from 'jquery'
import axios from 'axios'
import
{
	addEditorCommand,
	deviceManager,
	panels,
	scripts,
	selectorManager,
	storageSetting,
	styleManager,
	styles,
	traitManager,
} from "./geditor_utils";
import gjsBlockBasic from 'grapesjs-blocks-basic'
import grapesjsBlockBootstrap from "grapesjs-blocks-bootstrap4";
import grapesjsStyleBg from "grapesjs-style-bg";
import gjsBlocksFlexbox from 'grapesjs-blocks-flexbox'
import CryptoJS from 'crypto-js';

// Do not remove
import modelviewerComponent from '../../Dashboard/Components/Editor/plugins/modelviewer/'
import customButton from '../../Dashboard/Components/Editor/plugins/button/'
import qrCodeComponent from '../../Dashboard/Components/Editor/plugins/qrcode/'
// import swiperComponent from '../../Dashboard/Components/Editor/plugins/swiper/'
import { customFontFamilyType } from "../../Dashboard/Components/Editor/plugins/utitlites/FontStyleManager";
// import textureComponent from '../../Dashboard/Components/Editor/plugins/select'
import grapesjsTabs from '../../Dashboard/Components/Editor/plugins/color-texture-tabs'



const geditorConfig = ( { id, serverUrl, user, history, project, models, user_id, isTemplate } ) =>
{
	
	console.log( user )
	
	$( ".panel__devices" ).html( "" );
	$( ".panel__basic-actions" ).html( "" );
	$( ".panel__editor" ).html( "" );
	$( "#blocks" ).html( "" );
	$( "#styles-container" ).html( "" );
	$( "#trait-container" ).html( "" );
	
	// Content for Preview
	const navbar = $( "#navbar" );
	const mainContent = $( "#main-content" );
	const panelTopBar = $( "#main-content > .navbar-light" );
	
	const editor = grapesjs.init( {
		allowScripts: 1,
		container: "#editor",
		blockManager: {
			appendTo: "#blocks",
		},
		styleManager: styleManager,
		traitManager: traitManager,
		selectorManager: selectorManager,
		panels: panels( project ),
		deviceManager: deviceManager,
		assetManager: {
			// Upload endpoint, set `false` to disable upload, default `false`
			upload: `${serverUrl}/projects/assets/upload/${user_id}`,
			headers: {
				"Authorization": "Bearer " + user.stsTokenManager.accessToken
			},
			// The name used in POST to pass uploaded files, default: `'files'`
			uploadName: 'files',
		},
		storageManager: storageSetting( id, serverUrl ),
		canvas: {
			styles: styles,
			scripts: scripts,
		},
		plugins: [
			gjsBlockBasic,
			"modelviewerComponent",
			"customButton",
			grapesjsBlockBootstrap,
			grapesjsStyleBg,
			gjsBlocksFlexbox,
			customFontFamilyType,
			"grapesjsTabs",
		],
		pluginsOpts: {
			[ gjsBlockBasic ]: {
				blocks: [ 'column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image' ]
			},
			modelviewerComponent: {
				models: models
			},
			customButton: {},
			[ grapesjsBlockBootstrap ]: {
				blockCategories: {
					forms: false,
					layout: false,
					media: false,
					components: false,
				}
			},
			grapesjsStyleBg: {},
			[ gjsBlocksFlexbox ]: {},
			grapesjsTabs: {
				models: models,
			},
		},
	} );

	const encryptedModelId =project !== null? CryptoJS.AES.encrypt(project[ "Model_Id" ][ 0 ][ '_id' ], process.env.REACT_APP_HASH_SECRET_KEY):null;
	editor.setComponents( `
	<div class="project">
	<div class="heading">
	<h2 class="title">New project 1</h2>
	<p>
	Enable your customers to visualize your Product right in their
	environment using Immersive AR without even having to download an
	app. Press on 'View in AR'
	</p>`+
	( project !== null&&encryptedModelId!==null ? `<div class="qr-poster">
	<div class="qr-box">
	<div id="qr-code">
	<img src="${`https://chart.apis.googlLamp Projecte.com/chart?cht=qr&chs=300x300&chl=${project[ "Url" ]}`}"
	alt="" />
	</div>
	<p>Scan to Experience without downloading an app</p>
	</div>
	</div>`: '' ) + `
	</div>`+ ( models.length > 0 ? `
	<div data-gjs-highlightable="true" class="gjs-row">
	<div data-gjs-highlightable="true" class="gjs-cell">
	<model-viewer 
	data-gjs-droppable="false"
	class='3dmodel'
	
	ar ar-modes="scene-viewer webxr quick-look"
	model-id = "${encryptedModelId}"
	loading="eager"
	poster="https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/Xarwin-Preloader.gif"
	src="${project[ "Model_Id" ][ 0 ][ 'Format_Source' ][ "GLTF" ]}" ios-src="${project[ "Model_Id" ][ 0 ][ 'Format_Source' ][ "USDZ" ]}" shadow-intensity="1" ar
	camera-controls auto-rotate autoplay
	alt="A 3D model carousel" 
	>
	<button data-gjs-droppable="false" id="ar-button" slot="ar-button">View in AR</button>
	
	<div id="ar-prompt"  data-gjs-droppable="false">
	<img
	src="https://user-images.githubusercontent.com/66217883/175517705-28273a94-5932-42a9-925e-15fae1d48ddc.png" />
	</div>
	</model-viewer>
	</div>
	</div>`: '' ) + `
	</div>
	`)
	
	editor.setStyle( `
	@media only screen and (max-width: 800px) {
		.project {
			flex-direction: column-reverse;
		}
		
		.project>.heading {
			padding: 10px !important;
		}
		
		[class='3dmodel'] {
			height: 50vh !important;
		}
		
		model-viewer {
			height: 50vh !important;
		}
		
		.project>.heading>.qr-poster {
			display: none !important;
		}
	}
	
	#ar-button {
		background-image: url("https://user-images.githubusercontent.com/66217883/175517729-3f051f60-7d25-45df-9b41-f57065a17c6a.png");
		background-repeat: no-repeat;
		background-size: 20px 20px;
		background-position: 12px 50%;
		background-color: #fff;
		position: absolute;
		right: 5%;
		transform: translateX(-50%);
		bottom: 10px;
		padding: 0px 16px 0px 40px;
		font-family: Roboto Regular, Helvetica Neue, sans-serif;
		font-size: 14px;
		color: #4285f4;
		height: 36px;
		line-height: 36px;
		border-radius: 18px;
		border: 1px solid #dadce0;
	}
	
	#ar-button:active {
		background-color: #e8eaed;
	}
	
	#ar-button:focus {
		outline: none;
	}
	
	#ar-button:focus-visible {
		outline: 1px solid #4285f4;
	}
	
	@keyframes circle {
		from {
			transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg);
		}
		
		to {
			transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg);
		}
	}
	
	@keyframes elongate {
		from {
			transform: translateX(100px);
		}
		
		to {
			transform: translateX(-100px);
		}
	}
	
	model-viewer {
		width: 100%;
		height: 100vh;
	}
	
	model-viewer>#ar-prompt {
		position: absolute;
		left: 50%;
		bottom: 175px;
		animation: elongate 2s infinite ease-in-out alternate;
		display: none;
	}
	
	model-viewer[ar-status="session-started"]>#ar-prompt {
		display: block;
	}
	
	model-viewer>#ar-prompt>img {
		animation: circle 4s linear infinite;
	}
	
	.project {
		display: flex;
		justify-content: space-around;
		margin: 0 auto;
	}
	
	.project>.heading {
		flex: 1;
		padding: 40px 60px;
	}
	
	.project>.heading:before {
		background: #222;
		height: 2px;
		width: 40px;
		content: "";
		display: block;
		margin-bottom: 16px;
	}
	
	[class='3dmodel'] {
		background-color: #eee;
		width: 100%;
		height: 100vh;
	}
	
	.loader {
		position: absolute;
		top: 0;
		bottom: 0%;
		left: 0;
		right: 0%;
		z-index: 99;
		opacity: 0.7;
		display: none;
		background: lightgrey url('https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/Xarwin-Preloader.gif') center center no-repeat;
	}
	
	.gjs-row {
		display: table;
		padding-top: 10px;
		padding-right: 10px;
		padding-bottom: 10px;
		padding-left: 10px;
		width: 100%;
		flex: 1;
	}
	
	.project>.heading>.qr-poster {
		width: fit-content;
		background-color: #5E5CCD;
		padding: 10px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	
	.project>.heading>.qr-poster>.qr-box {
		background-color: white;
		border-radius: 10px;
		padding: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	
	
	.project>.heading>.qr-poster>.qr-box>p {
		color: black;
		font-family: 'Montserrat';
		font-size: 15px;
		font-weight: bold;
	}
	
	`)
	
	const bm = editor.BlockManager
	const basic = [ 'text', 'header', 'paragraph', 'image', ]
	const layout = [ 'column1', 'column2', 'column3', 'column3-7', ]
	const blocks = bm.getAll()
	blocks.models.map( ( block ) =>
	{
		if ( layout.includes( block.id ) )
		{
			block.set( 'category', 'Layout' )
		}
		else if ( basic.includes( block.id ) )
		{
			block.set( 'category', 'Basic' )
		}
	} )
	blocks.models.sort( ( a, b ) =>
	{
		let fa = a.get( 'category' ),
		fb = b.get( 'category' );
		
		if ( fa < fb )
		{
			return -1;
		}
		if ( fa > fb )
		{
			return 1;
		}
		return 0;
	} )
	bm.render( blocks.models )
	
	
	addEditorCommand( editor, serverUrl, id, user, history );
	
	editor.Storage.add( 'remote', {
		async load ()
		{
			console.log( "LOADINGG DATAAA" )
			if ( isTemplate === false )
			{
				const res = await axios.get( `${serverUrl}/webpage/${id}/content` )
				console.log( res )
				return res.data.data
			} else
			{
				const res = await axios.get( `${serverUrl}/users/templates/${id}/content` )
				console.log( res )
				return res.data.data
			}
			
		},
		
		async store ( data )
		{
			$( ".saveDb" ).html( "Saving..." );
			$( ".saveDb" ).prop( "disabled", true )
			$( ".publish" ).css( "color", "red" )
			console.log( " STORINGG DATA" )
			console.log( data )
			if ( isTemplate === false )
			{
				await axios.patch( `${serverUrl}/webpage/${id}/content`, { data } );
			} else
			{
				await axios.patch( `${serverUrl}/users/templates/${id}/content`, { data } );
			}
			
			$( ".saveDb" ).prop( "disabled", false )
			$( ".saveDb" ).html( "Save" );
		},
	} );
	
	editor.on( 'component:selected', ( component ) =>
	{
		$( '#editor-tabs li' ).children().removeClass( 'active' );
		$( '#style-tab' ).addClass( 'active' );
		$( '.tab-content' ).children().removeClass( 'active show' );
		$( '#style' ).addClass( 'active show' );
	} )
	
	editor.on( 'asset:upload:start', () =>
	{
		$( ".gjs-am-assets" ).append( "<div class=\"uploading\">Uploading,Please wait..</div>" )
	} );
	
	editor.on( 'asset:upload:end', () =>
	{
		$( ".gjs-am-assets>.uploading" ).remove()
	} );
	
	editor.on( "run:preview", () =>
	{
		console.log( "It will trigger when we click on preview icon" );
		// This will be used to hide border
		editor.stopCommand( "sw-visibility" );
		// This will hide the sidebar view
		navbar.removeClass( "sidebar" );
		// This will make the main-content to be full width
		mainContent.removeClass( "main-content" );
		
		// This will hide top panel where we have added the button
		panelTopBar.addClass( "d-none" );
	} );
	editor.on( "stop:preview", () =>
	{
		// This event is reverse of the above event.
		console.log( "It will trigger when we click on cancel preview icon" );
		editor.runCommand( "sw-visibility" );
		navbar.addClass( "sidebar" );
		mainContent.addClass( "main-content" );
		panelTopBar.removeClass( "d-none" );
	} );
	
	setTimeout( () =>
	{
		let categories = editor.BlockManager.getCategories();
		categories.each( ( category ) => category.set( "open", false ) );
	}, 1000 );
	return editor;
};


export default geditorConfig