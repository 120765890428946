import { useHistory } from 'react-router-dom';
import './Settings.scss'

const Settings = () =>
{
  const notificationlabel = 'Settings';
  const history = useHistory();
  return (
    <div className='settings-wrapper'>
      <div>
        <h1>Notification</h1>
        <div className='toggle-switch'>
          <input
            type='checkbox'
            className='checkbox'
            name={ notificationlabel }
            id={ notificationlabel }
          />
          <label className='label' htmlFor={ notificationlabel }>
            <span className='inner' />
            <i className='switch' />
          </label>
        </div>
      </div>
      <div onClick={ () => history.push( '/payment-history' ) }>
        <h1>Payment History</h1>
      </div>
      <div onClick={ () => history.push( '/change-password' ) }>
        <h1>Change Password</h1>
      </div>
    </div>
  );
};

export default Settings;
