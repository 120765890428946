import { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import ProjectCard from '../../Components/ProjectCard/ProjectCard';

import { useHistory, withRouter } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { getAllProjects } from '../../../actions/Projects';
import { connect } from 'react-redux';
import { deleteprojectFunction } from '../../../actions/Projects';
import './Projects.css'

const Projects = ( { allProjects, deleteprojectFunction, getAllProjects } ) =>
{
  const [ projects, setProjects ] = useState( [] );
  const [ currentId, setCurrentId ] = useState( null );
  const [ isPopupOpen, setPopupOpen ] = useState( false );
  const history = useHistory();

  useEffect( () =>
  {
    getAllProjects()
  }, [] )

  useEffect( () =>
  {
    setProjects( allProjects?.reverse() )
  }, [ allProjects ] )


  const onDelete = ( id ) =>
  {
    setCurrentId( id );
    setPopupOpen( true );
  };
  const deleteConfirm = () =>
  {
    console.log( currentId )
    setPopupOpen( false );
    deleteprojectFunction( currentId, result =>
    {
      const { status } = result
      console.log( status )
      if ( status === true )
      {
        const remaining = projects.filter( project => project._id !== currentId )
        setProjects( remaining )
      }
    } )
  };

  const uploadHandler = () =>
  {
    history.push( '/create-project' )
  }

  return (
    <>
      {
        projects?.length > 0 ? <div className='projects-container'>
          <div className='row gx-5 gy-2'>
            { projects.map( ( project, index ) => (
              <div className='col-md-6 col-lg-4' key={ index }>
                <ProjectCard
                  key={ project._id }
                  id={ project._id }
                  projectName={ project.Project_Name }
                  thumbnail={ project.Thumbnail }
                  description={ project.Description }
                  projectId={ project.Project_Id }
                  userId={ project.User_Id }
                  modelId={ project.Model_Id }
                  templateId={ project.Template_Id }
                  status={ project.Status }
                  draft={ project.Draft }
                  onDelete={ onDelete }
                />
              </div>

            ) ) }
          </div>
          { isPopupOpen && <div className='delete-popup'>
            <div className='project-delete-container'>
              <FaTimes onClick={ () => setPopupOpen( false ) } />
              <div>
                <p>Are you sure you want to delete ?</p>
                <div>
                  <button onClick={ () => deleteConfirm() }>Yes</button>
                  <button onClick={ () => setPopupOpen( false ) }>No</button>
                </div>
              </div>
            </div>
          </div> }
        </div> : <div className='upload_project'>
          <button className='btn btn-lg' onClick={ uploadHandler }>Create an Experience</button>
        </div>
      }
    </>
  );
};

const mapPropsToState = ( state ) =>
{
  return {
    allProjects: state?.project?.projects,
    projectsLoader: state?.project?.projectsLoader,
  }
}

export default connect( mapPropsToState, { getAllProjects, deleteprojectFunction } )( ( withRouter( Projects ) ) );


