/* eslint-disable import/no-anonymous-default-export */
export default ( editor, opts = {} ) =>
{
	const bm = editor.BlockManager;

	const styles = `
		<style>
		.custom-button {
			background-color: black;
			border-radius: 10px;
			padding: 10px;
			border: 1px solid black;
			width: fit-content;
			margin: auto auto auto auto;
		}
		.custom-button>a{
			text-decoration: none;
			color: white;
		}
		</style>
	`;
	bm.add( opts.name, {
		label: `
		<div class="gjs-block__media"><svg viewBox="0 0 24 24">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12z" fill="#000000"/>
      </svg></div>
	  <div class="gjs-block-label">
		${opts.label}
	  </div> 
	  `,
		category: opts.category,
		content: `
		<div class="custom-button">
			<a href="">Button</a>
		</div>${styles}
		`,
	} );
};
