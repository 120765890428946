const firebaseDevConfig = {
    apiKey: "AIzaSyAazO0nXaeXkfdtpwNLwhg3O3xM6bsmA6U",
    authDomain: "xarwin-b833e.firebaseapp.com",
    projectId: "xarwin-b833e",
    storageBucket: "xarwin-b833e.appspot.com",
    messagingSenderId: "230135752154",
    appId: "1:230135752154:web:3d5442144d78e3c53ccce3",
    measurementId: "G-55JJHP677J"
};

const firebaseProdConfig = {
    apiKey: "AIzaSyDI6nKeJgoTLCmU18nU5G7js-bpjeqb1Kg",
    authDomain: "xarwin-prod.firebaseapp.com",
    projectId: "xarwin-prod",
    storageBucket: "xarwin-prod.appspot.com",
    messagingSenderId: "604584886781",
    appId: "1:604584886781:web:0ddb4a46b02c01c96d5994",
    measurementId: "G-QMN4P263HM"
};

const config = process.env.REACT_APP_STAGE === "production" ? firebaseProdConfig : firebaseDevConfig;

export default config;
