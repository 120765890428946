import axios from 'axios'
import Swal from 'sweetalert2';
import config from './config';

export const getAllProjects = () => ( dispatch, getState ) =>
{
	dispatch( { type: "PROJECT_LOADER" } )
	const user = getState()?.user?.userData;
	console.log( user )
	console.log( user.stsTokenManager.accessToken )
	axios.get( `${config.serverUrl}/projects/getAll/${user.uid}`, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const projects = res.data.projects
		dispatch( { type: "PROJECT_UPDATE", payload: { projects: projects } } )
	} )
}

export const createProjectFunction = ( userProject, history ) => ( dispatch, getState ) =>
{
	dispatch( { type: "PROJECT_LOADER" } )
	const user = getState()?.user?.userData;
	axios.post( `${config.serverUrl}/projects/create`, userProject, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
		}
	} )
		.then( res =>
		{
			console.log( res )
			dispatch( { type: "PROJECT_LOADER" } )
			Swal.fire( { title: 'Project Added Successfull!' } )
			history.push( `/editor/${res.data[ "_id" ]}` )
		} )
		.catch( ( error ) =>
		{
			dispatch( { type: "PROJECT_LOADER" } )
			console.log( error.response )
			Swal.fire( { title: error.response?.data?.message } )
			if ( error.response?.data?.status?.toLowerCase() === "username" )
			{
				history.push( "/register/username" )
			}
		} )
}

export const deleteprojectFunction = ( currentId, callback ) => ( dispatch, getState ) =>
{
	dispatch( { type: "PROJECT_LOADER" } )
	const user = getState()?.user?.userData;
	axios.delete( `${config.serverUrl}/projects/${currentId}`, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
		}
	} ).then( data =>
	{
		console.log( data );
		dispatch( { type: "PROJECT_LOADER" } )
		if ( data )
		{
			callback( { status: true } )
		} else
		{
			callback( { status: false } )
		}
	} ).catch( ( error ) =>
	{
		console.log( error )
		dispatch( { type: "PROJECT_LOADER" } )
		callback( { status: false } )
	} )
}

export const getProjectById = ( id ) => ( dispatch, getState ) =>
{
	dispatch( { type: "PROJECT_LOADER" } )
	const user = getState()?.user?.userData;
	console.log( user.stsTokenManager.accessToken )
	axios.get( `${config.serverUrl}/projects/${id}`, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken
		}
	} )
		.then( ( res ) =>
		{
			console.log( res )
			dispatch( { type: "CURRENT_PROJECT_UPDATE", payload: { currentProject: res.data.project } } )
			dispatch( { type: "CURRENT_MODEL_UPDATE", payload: { currentModel: res.data.project[ "Model_Id" ][ 0 ] } } )
		} )
		.catch( ( err ) =>
		{
			console.log( err )
		} )
}

export const uploadTextureImage = ( textureFile, callback ) => ( dispatch, getState ) =>
{
	const user = getState()?.user?.userData;
	console.log( user.stsTokenManager.accessToken )
	var data = new FormData()
	data.append( 'files[]', textureFile )
	axios.post( `${config.serverUrl}/projects/assets/upload/${user.uid}`, data, {
		headers: {
			'Authorization': 'Bearer ' + user.stsTokenManager.accessToken,
			'Content-Type': 'multipart/form-data'
		},
		onUploadProgress: ( progressEvent ) =>
		{
			const { loaded, total } = progressEvent;
			let percent = Math.floor( ( loaded * 100 ) / total );
			console.log( `${loaded} || ${percent}%` )
			dispatch( { type: "UPDATE_EDITOR_ASSET_UPLOAD_PERCENTAGE", payload: { percentage: percent } } )
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const textureInfo = res.data.data;
		callback( {
			status: true,
			texture: textureInfo[ 0 ]
		} )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			console.log( err.data )
			console.log( err.response )
			callback( {
				status: false,
				texture: null
			} )
		} ).finally( () =>
		{
			dispatch( { type: "UPDATE_EDITOR_ASSET_UPLOAD_PERCENTAGE", payload: { percentage: 0 } } )
		} )
}