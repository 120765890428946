import axios from 'axios';
import Swal from 'sweetalert2';
import config from './config';

export const getUserDetailsById = () => async ( dispatch, getState ) =>
{
	dispatch( { type: "USER_LOADER" } )
	const user = getState()?.user?.userData;
	console.log( user )
	// const token = await user?.getIdToken()
	axios.get( `${config.serverUrl}/users/${user.uid}`, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const user = res.data.user
		dispatch( { type: "USER_DB_UPDATE", payload: { dbUser: user } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "USER_LOADER" } )
		} )
}

export const updateUsername = ( username, history ) => async ( dispatch, getState ) =>
{
	dispatch( { type: "USER_LOADER" } )
	const user = getState()?.user?.userData;
	console.log( user )
	// const token = await user?.getIdToken()
	axios.post( `${config.serverUrl}/users/username`, {
		Username: username.toLowerCase(),
		User_Id: user.uid,
	}, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const user = res.data.user
		dispatch( { type: "USER_DB_UPDATE", payload: { dbUser: user } } )
		history.push( "/register/subscription" )
	} )
		.catch( ( err ) =>
		{
			console.log( err.data )
			console.log( err.response )
			Swal.fire( { title: err?.response?.data?.message } )
			dispatch( { type: "USER_LOADER" } )
		} )
}

export const updateUserInfo = ( userInfo, userId ) => ( dispatch, getState ) =>
{
	console.log( userInfo )
	dispatch( { type: "USER_LOADER" } )
	const user = getState()?.user?.userData;
	axios.post( `${config.serverUrl}/users/update/${userId}`, userInfo, {
		headers: {
			'Authorization': 'Bearer ' + user.accessToken, 'Content-Type': 'multipart/form-data'
		},
	} ).then( ( res ) =>
	{
		const user = res.data.user
		dispatch( {
			type: "USER_DB_UPDATE", payload: {
				dbUser: user
			}
		} )
	} ).catch( ( err ) =>
	{
		dispatch( { type: "USER_LOADER" } )
		console.log( err )
	} )
}

export const updateUserLoginTime = () => async ( dispatch, getState ) =>
{
	const user = getState()?.user?.userData;
	console.log( user )
	if ( user?.uid !== undefined && user?.uid !== null )
	{
		axios.post( `${config.serverUrl}/users/login/${user.uid}`, {}, {
			headers: {
				'Authorization': "Bearer " + user.stsTokenManager.accessToken
			}
		} ).then( ( res ) =>
		{
			console.log( res )
		} )
			.catch( ( err ) =>
			{
				console.log( err.data )
				console.log( err.response )
			} )
	}
}