import './Sidebar.css'
import {IoCubeSharp } from 'react-icons/io5'
import {BsFonts,BsFillGearFill} from 'react-icons/bs'
import { FaMagic } from 'react-icons/fa'

const Sidebar = ( { showEditorHandler } ) =>
{
	return (
		<>
			<ul className="nav nav-tabs" id="editor-tabs" role="tablist">
				<li className="nav-item" role="presentation">
					<button
						className="nav-link"
						id="model-tab"
						data-bs-toggle="tab"
						data-bs-target="#block"
						type="button"
						role="tab"
						aria-controls="block"
						aria-selected="true"
						onClick={ showEditorHandler }
					>
						<IoCubeSharp size={ 30 } />
						<br />
						<p>Model</p>
					</button>
				</li>
				<li className="nav-item" role="presentation">
					<button
						className="nav-link active"
						id="block-tab"
						data-bs-toggle="tab"
						data-bs-target="#block"
						type="button"
						role="tab"
						aria-controls="block"
						aria-selected="true"
					>
						<BsFonts size={ 30 } />
						<br />
						<p>Components</p>
					</button>
				</li>
				<li className="nav-item" role="presentation">
					<button
						className="nav-link"
						id="style-tab"
						data-bs-toggle="tab"
						data-bs-target="#style"
						type="button"
						role="tab"
						aria-controls="style"
						aria-selected="false"
					>
						<FaMagic size={ 30 } />
						<br />
						<p>Appearance</p>
					</button>
				</li>
				<li className="nav-item" role="presentation">
					<button
						className="nav-link"
						id="trait-tab"
						data-bs-toggle="tab"
						data-bs-target="#trait"
						type="button"
						role="tab"
						aria-controls="trait"
						aria-selected="false"
					>
						<BsFillGearFill size={ 30 } />
						<br />
						<p>Attributes</p>
					</button>
				</li>
			</ul>
		</>
	);
}

export default Sidebar;
