import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import axios from "axios";
// import "@google/model-viewer/dist/model-viewer";
import './ViewProject.css';
import './QrImage.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import View from '../../Components/ViewProjectComponents/View';
import ViewModel from '../../Components/ViewModelComponent/View'
import { connect, useDispatch } from 'react-redux';
import TourStep from '../../Components/Tour/TourStep';
import { getProjectById } from '../../../actions/Projects';

const ViewProject = ( { tour, currentProject, getProjectById } ) =>
{
    const { user, serverUrl } = useAuth();
    const { id } = useParams();
    const steps = [
        {
            element: ".project-view",
            intro: "Your project details can be viewed here."
        },
        {
            element: ".project-header",
            intro: "Know your project status from here"
        },
        {
            element: ".share",
            intro: "Share your amazing project with just a link"
        },
        {
            element: ".social-share",
            intro: "You can also publish the link directly in your social media as well."
        },
        {
            element: ".QR",
            intro: "Just scan to view your project"
        }
    ]


    useEffect( () =>
    {
        console.log( id )
        getProjectById( id )
    }, [] )


    return (

        <div>
            { tour != "false" && <TourStep steps={ steps } tourReducer={ tour } /> }
            <div className='projects'>
                <Tabs
                    defaultActiveKey="project"
                    id="fill-tab-example"
                    className="project-tabs mb-3"
                    fill
                >
                    <Tab eventKey="project" title="Project">
                        <View />
                    </Tab>
                </Tabs>
            </div>
        </div>


    );
};

const mapPropsToState = ( state ) =>
{
    return {
        tour: state?.tour?.tour,
        currentProject: state?.project?.currentProject
    }
}

export default connect( mapPropsToState, { getProjectById } )( ( withRouter( ViewProject ) ) );

