import grapesjs from "grapesjs";
import loadComponents from './components/index';
import loadBlocks from './blocks';
import defOptions from './options';
import loadTraits from './traits'

export default grapesjs.plugins.add ("grapesjsTabs",(editor, opts = {}) => {
  const options = {
    ...defOptions,
    ...opts
  };

  // Add components
  loadComponents(editor, options);

  // Add blocks
  loadBlocks(editor, options);

  loadTraits(editor,options)
});
