import React, { useEffect } from 'react'
import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs";
import { useLocation, useParams, withRouter } from "react-router-dom";
import useAuth from '../../../../hooks/useAuth';
import axios from 'axios'
import { styles, scripts } from '../../../../Utilities/Editor/geditor_utils'
import $ from 'jquery'
import './ViewTemplatePage.css'
import { getTemplateById } from '../../../../actions/Templates';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const ViewTemplatePage = ( { currentTemplate, getTemplateById } ) =>
{
	const { templateId } = useParams();
	const { serverUrl } = useAuth()
	const history = useHistory()
	useEffect( () =>
	{
		getTemplateById( templateId )
		$( "#panel__devices" ).html( "" );
		const editor = grapesjs.init( {
			container: '#editor',
			storageManager: {
				type: "remote",
				stepsBeforeSave: 1,
				autoload: true,
				autosave: false,
				contentTypeJson: true,
				storeComponents: true,
				storeStyles: true,
				storeHtml: true,
				storeCss: true,
				headers: {
					"Content-Type": "application/json",
				},
			},
			deviceManager: {
				devices: [ {
					name: 'Desktop',
					width: '', // default size
				}, {
					name: 'Mobile',
					width: '320px', // this value will be used on canvas width
					widthMedia: '480px', // this value will be used in CSS @media
				} ]
			},
			panels: {
				defaults: [
					{
						id: "panel-devices",
						el: "#panel__devices",
						buttons: [
							{
								id: "device-desktop",
								label: '<i class="fa fa-television"></i>',
								command: "set-device-desktop",
								togglable: false,
							},
							{
								id: "device-mobile",
								label: '<i class="fa fa-mobile"></i>',
								command: "set-device-mobile",
								togglable: false,
								active: true,
							},
						],
					},
				],
			},
			canvas: {
				styles: styles,
				scripts: scripts,
			},
		} );
		editor.Storage.add( 'remote', {
			async load ()
			{
				console.log( "LOADINGG DATAAA" )
				const res = await axios.get( `${serverUrl}/users/templates/${templateId}/content` )
				console.log( res )
				return res.data.data

			},
			async store ( data )
			{

			},
		} );
		// Commands
		editor.Commands.add( "set-device-desktop", {
			run: ( editor ) => editor.setDevice( "Desktop" ),
		} );
		editor.Commands.add( "set-device-mobile", {
			run: ( editor ) => editor.setDevice( "Mobile" ),
		} );
	}, [] )

	const onUseTemplateHandler = () =>
	{
		if ( currentTemplate !== null )
		{
			history.push( {
				pathname: '/create-project',
				selectedTemplate: currentTemplate
			} )
		}
	}

	return (
		<div className='view_template'>
			<div className='header'>
				<button onClick={ onUseTemplateHandler }>Use Template</button>
				<div id="panel__devices"></div>
			</div>
			<div id="editor">

			</div>
		</div>
	)
}

const mapPropsToState = ( state ) =>
{
	return {
		currentTemplate: state?.template?.currentTemplate,
		templatesLoader: state?.template?.templatesLoader,
	}
}

export default connect( mapPropsToState, { getTemplateById } )( ( withRouter( ViewTemplatePage ) ) );
