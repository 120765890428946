import React from "react";
import { Link } from "react-router-dom";
import './RegisterSuccess.css'

const RegisterSuccess = () =>
{
  return (
    <div className="register-success-wrapper">
      <div className="register-success-form">
        <h1>Successfully Registered!</h1>
        <img src="https://www.freeiconspng.com/uploads/success-icon-10.png" width="150" alt="Success Register" />
        <h4>Return back to <Link to="/login">Login</Link></h4>
      </div>
    </div>
  );
};

export default RegisterSuccess;
