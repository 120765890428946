import axios from "axios";
import
{
    getAuth,
    onAuthStateChanged,
    signOut,
    signInWithEmailAndPassword,
    updateProfile,
    GoogleAuthProvider,
    signInWithPopup,
    FacebookAuthProvider,
    createUserWithEmailAndPassword,
    onIdTokenChanged,
    sendPasswordResetEmail,
    sendEmailVerification
} from "firebase/auth";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../actions/config";

import initializeAuthentication from "../Firebase/firebase.init";


initializeAuthentication();


const useFirebase = () =>
{
    //const serverUrl = "https://app.xarwin.com/api"
    const serverUrl = config.serverUrl
    const [ user, setUser ] = useState( {} )
    const auth = getAuth()
    const [ isLoading, setIsLoading ] = useState( true );
    const [ error, setError ] = useState( '' )
    const history = useHistory();

    const dispatch = useDispatch();

    const googleProvider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();

    // google login
    const googleSignin = ( history ) =>
    {

        signInWithPopup( auth, googleProvider )
            .then( ( result ) =>
            {
                console.log( result )
                console.log( result.user )
                setUser( result.user )
                dispatch( {
                    type: "USER_UPDATE", payload: {
                        user: result.user
                    }
                } )
                const Email = result.user.email
                const Name = result.user.displayName
                const Firebase_Id = result.user.uid
                saveUser( Email, Name, Firebase_Id, 'POST', history );
            } )
            .catch( ( error ) =>
            {
                Swal.fire( { title: error.message } )
                dispatch( {
                    type: "USER_LOADER"
                } )
            } )
            .finally( () =>
            {
                setIsLoading( false )
            } )

    }

    // facebook Login
    const facebookSignin = ( history ) =>
    {
        signInWithPopup( auth, facebookProvider )
            .then( ( result ) =>
            {
                setUser( result.user )
                const Email = result.user.email
                const Name = result.user.displayName
                const Firebase_Id = result.user.uid
                saveUser( Email, Name, Firebase_Id, 'POST', history );
                // console.log(result.user);
                setIsLoading( false )
            } )
    }


    // user register with email and password

    const handleUserRegister = ( newUser, history ) =>
    {

        createUserWithEmailAndPassword( auth,
            newUser.email,
            newUser.confirmPassword ).then( ( result ) =>
            {
                const user = result.user
                console.log( result.user );
                setUser( user );
                dispatch( {
                    type: "USER_UPDATE", payload: {
                        user: user
                    }
                } )
                saveUser( user.email, newUser.name, user.uid, 'POST', history );
                setError( '' )
            } )
            .catch( ( error ) =>
            {
                Swal.fire( { title: error.message } )
                dispatch( {
                    type: "USER_LOADER"
                } )
            } )
    }


    //  user login with email and password

    const handleUserLogin = ( email, password, history ) =>
    {
        signInWithEmailAndPassword( auth, email, password )
            .then( ( result ) =>
            {
                console.log( result.user );
                setUser( result.user );
                dispatch( {
                    type: "USER_UPDATE", payload: {
                        user: result.user
                    }
                } )
                saveUser( result.user.email, result.user.name, result.user.uid, 'POST', history );
                setError( '' )
            } )
            .catch( ( error ) =>
            {
                setError( error.message )
            } );
    };


    // Forget password

    const handleForgetPassword = ( email, history ) =>
    {
        sendPasswordResetEmail( auth, email )
            .then( () =>
            {
                //password reset email sent
                Swal.fire( { title: "An email has been sent to " + email } )
            } )
            .catch( ( error ) =>
            {
                Swal.fire( { title: "This email doesn't exist. Enter a different email." } )
                console.log( error.message );
            } );
    }

    // Send email verification

    const handleEmailVerification = () =>
    {
        console.log( "Email verification" )
        sendEmailVerification( user )
    }



    // useEffect( () =>
    // {
    //     const unsubscribed = onAuthStateChanged( auth, ( user ) =>
    //     {
    //         console.log( user )
    //         if ( user )
    //         {
    //             console.log( user )
    //             console.log( user.stsTokenManager.accessToken )
    //             setUser( user );
    //             dispatch( {
    //                 type: "USER_UPDATE", payload: {
    //                     user: user
    //                 }
    //             } )
    //         }
    //         else
    //         {
    //             setUser( {} );
    //             dispatch( {
    //                 type: "USER_UPDATE", payload: {
    //                     user: {}
    //                 }
    //             } )
    //             logOut();
    //         }
    //         console.log( user )
    //         setIsLoading( false )
    //         console.log( isLoading );
    //     } );

    //     return () => unsubscribed;
    // }, [] )


    // logout
    const logOut = ( history ) =>
    {
        signOut( auth )
            .then( () =>
            {
                if ( history !== undefined && history !== null )
                {
                    setUser( {} );
                    dispatch( { type: "USER_LOGOUT" } )
                    history.push( "/login" )
                }
            } )

    }

    // set user to database
    const saveUser = ( Email, Name, Firebase_Id, method, history ) =>
    {
        console.log( "Saving user" )
        const userData = { Email, Name, Firebase_Id };
        axios.post( `${serverUrl}/users/create`, JSON.stringify( userData ), {
            headers: {
                'content-type': 'application/json'
            },
        } ).then( ( result ) =>
        {
            console.log( result )
            console.log( user )
            if ( result.data[ "Username" ] === undefined || result.data[ "Username" ] === null )
            {
                history.push( "/register/username" )
            } else
            {
                history.push( "/dashboard" )
            }
        } )
    }



    return {
        googleSignin,
        auth,
        user,
        handleUserRegister,
        handleUserLogin,
        handleForgetPassword,
        handleEmailVerification,
        logOut,
        facebookSignin,
        error,
        serverUrl,
        isLoading
    }
}

export default useFirebase;
