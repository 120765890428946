import { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import ProjectCard from '../../Components/ProjectCard/ProjectCard';

import { useHistory, withRouter } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { connect } from 'react-redux';
import './ImageTrackerList.css'
import { deleteITProject, getAllITProjects } from '../../../actions/ITProject';

const ImageTrackerList = ( { getAllITProjects, itProjects, deleteITProject } ) =>
{
	const [ projects, setProjects ] = useState( [] );
	const [ currentId, setCurrentId ] = useState( null );
	const [ isPopupOpen, setPopupOpen ] = useState( false );
	const history = useHistory();

	useEffect( () =>
	{
		getAllITProjects()
	}, [] )

	useEffect( () =>
	{
		setProjects( itProjects?.reverse() )
	}, [ itProjects ] )


	const onDelete = ( id ) =>
	{
		setCurrentId( id );
		setPopupOpen( true );
	};
	const deleteConfirm = () =>
	{
		console.log( currentId )
		setPopupOpen( false );
		deleteITProject( currentId, result =>
		{
			const { status } = result
			console.log( status )
			if ( status === true )
			{
				const remaining = projects.filter( project => project._id !== currentId )
				setProjects( remaining )
			}
		} )
	};

	const createHandler = () =>
	{
		history.push( '/image-tracking/create' )
	}

	return (
		<>

			{
				projects?.length > 0 ? <div className='it-projects-container'>
					<div className='header d-flex'>
						<button className='btn' onClick={ createHandler }>Create an Experience</button>
					</div>
					<div className='row gx-5 gy-2'>
						{ projects.map( ( project, index ) => (
							<div className='col-md-6 col-lg-4' key={ index }>
								<ProjectCard
									key={ project._id }
									id={ project._id }
									projectName={ project.Project_Name }
									description={ project.Description }
									userId={ project.User_Id }
									status={ project.Status }
									onDelete={ onDelete }
									isITProject={ true }
									itProject={ project }
								/>
							</div>

						) ) }
					</div>
					{ isPopupOpen && <div className='delete-popup'>
						<div className='project-delete-container'>
							<FaTimes onClick={ () => setPopupOpen( false ) } />
							<div>
								<p>Are you sure you want to delete ?</p>
								<div>
									<button onClick={ () => deleteConfirm() }>Yes</button>
									<button onClick={ () => setPopupOpen( false ) }>No</button>
								</div>
							</div>
						</div>
					</div> }
				</div> : <div className='upload_it_project'>
					<button className='btn btn-lg' onClick={ createHandler }>Create an Experience</button>
				</div>
			}
		</>
	);
};

const mapPropsToState = ( state ) =>
{
	return {
		itProjects: state?.itProject?.itProjects,
		itProjectsLoader: state?.itProject?.itProjectsLoader,
	}
}

export default connect( mapPropsToState, { getAllITProjects, deleteITProject } )( ( withRouter( ImageTrackerList ) ) );


