import { useEffect, useState } from 'react';
import './Dashboard.css'
import useAuth from '../../../hooks/useAuth';
import Analytics from './inc/Analytics';
import LineChartAnalytics from './inc/LineChartAnalytics';
import Links from './inc/Links';
import RecentModels from './inc/RecentModels/RecentModels';
import { getAllProjectsAnalytics } from '../../../actions/Dashboard';
import { getAllModelsByUser } from '../../../actions/Models';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TourStep from '../../Components/Tour/TourStep';

const Dashboard = ( { getAllProjectsAnalytics, getAllModelsByUser, analytics, models, tour } ) =>
{
  const [ analyticsData, setAnalyticsData ] = useState( {} )
  const [ modelsData, setModelData ] = useState( [] )
  const steps = [
    {
      element: "#dashboard",
      intro: "This is your Dashboard."
    },
    {
      element: "#links",
      intro: "Quick Links to create an amazing AR experience"
    },
    {
      element: "#analytics",
      intro: "Know your product reach here"
    },
    {
      element: "#models",
      intro: "Your recent models appear here"
    },
    {
      element: "#xarwinit",
      intro: "You can start from here!"
    }
  ]


  useEffect( () =>
  {
    getAllProjectsAnalytics()
    getAllModelsByUser()
  }, [] )

  useEffect( () =>
  {
    console.log( analytics )
    setAnalyticsData( analytics )
  }, [ analytics ] )

  useEffect( () =>
  {
    console.log( models )
    setModelData( models?.length > 5 ? models?.splice( -5 ) : models )
  }, [ models ] )


  return (
    <div>
      { tour != "false" && <TourStep steps={ steps } tourReducer={ tour } /> }
      <div className='dashboard'>
        <div className="dashboard-container d-flex flex-column flex-md-row">
          <div className='card' id="links">
            <p>Links</p>
            <Links />
          </div>
          <div className='card' id="analytics">
            <p>Views</p>
            <LineChartAnalytics analytics={ analyticsData } />
          </div>
        </div>
        <div className='row' id="models">
          { models?.length > 0 ? <div className='card'>
            <p>Models</p>
            <RecentModels className="col-md-2" models={ modelsData } />
          </div> : "" }
        </div>
      </div>
    </div>
  );
};
const mapPropsToState = ( state ) =>
{
  return {
    analytics: state?.dashboard?.dashboardAnalytics,
    models: state?.model?.models,
    dashboardLoader: state?.dashboard?.dashboardLoader,
    tour: state?.tour?.tour
  }
}

export default connect( mapPropsToState, { getAllProjectsAnalytics, getAllModelsByUser } )( ( withRouter( Dashboard ) ) );


