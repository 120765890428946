import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
// import "@google/model-viewer/dist/model-viewer";
import './ViewModel.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ViewModel from '../../Components/ViewModelComponent/View'
import { connect, useDispatch } from 'react-redux';
import { getModelById } from '../../../actions/Models';

const ViewModelPage = ( { currentModel, getModelById } ) =>
{
    const { modelId } = useParams()
    useEffect( () =>
    {
        getModelById( modelId )
    }, [] )

    return (

        <div>
            <div className='models'>
                <Tabs
                    defaultActiveKey="model"
                    id="fill-tab-example"
                    className="model-tabs mb-3"
                    fill
                >
                    <Tab eventKey="model" title="Model">
                        <ViewModel />
                    </Tab>
                </Tabs>
            </div>
        </div>


    );
};

const mapPropsToState = ( state ) =>
{
    return {
        currentModel: state?.project?.currentModel
    }
}

export default connect( mapPropsToState, { getModelById } )( ( withRouter( ViewModelPage ) ) );

