import Sidebar from '../inc/Sidebar/Sidebar';
import TopBar from '../inc/TopBar/TopBar';
import './Layout.scss'

const Layout = ( { children } ) =>
{
  return (
    <>
      <div className='layout-wrapper'>
        <Sidebar />
        <div className='main-wrapper'>
          <TopBar />
          <main>{ children }</main>
        </div>
      </div>
    </>
  );
};

export default Layout;
