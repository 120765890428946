const INITIAL_STATE = {
	editor: [],
	showModelEditor: false,
	editorError: null,
	editorLoader: false,
	modelDetails: [],
	assetUploadPercentage: 0,
	project: null,
	currentEditorModel: null,
};

export const editorReducer = ( state = INITIAL_STATE, action ) =>
{
	switch ( action.type )
	{
		case "SHOW_MODEL_EDITOR":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				showModelEditor: true,
				editorError: null,
				editorLoader: false,
			};
		case "CLOSE_MODEL_EDITOR":
			console.log( "action.payload>>>", action.payload );
			return {
				...state,
				showModelEditor: false,
				editorError: null,
				editorLoader: false,
			};
		case "MODEL_DETAILS_UPDATE":
			return {
				...state,
				modelDetails: action.payload.modelDetails
			}
		case "PROJECT_UPDATE":
			return {
				...state,
				project: action.payload.project,
			};
		case "UPDATE_MODEL_EDITOR":
			console.log( action.payload.Lighting )
			return {
				...state,
				modelEditor: {
					...state.modelEditor,
					Lighting: action.payload.Lighting,
					Material: action.payload.Material,
					Hotspot: action.payload.Hotspot,
				},
				modelViewer: action.payload.modelViewer
			}
		case "UPDATE_MODEL_VIEWER":
			console.log( action.payload.modelMaterial )
			return {
				...state,
				modelViewer: action.payload.modelViewer,
				modelMaterial: action.payload.modelMaterial,
			}
		case "UPDATE_EDITOR_ASSET_UPLOAD_PERCENTAGE":
			return {
				...state,
				assetUploadPercentage: action.payload.percentage,
			}
		case "EDITOR_LOADER":
			return {
				...state,
				editorLoader: !state.editorLoader
			}
		case "UPDATE_CURRENT_EDITOR_MODEL_DETAIL":
			return {
				...state,
				currentEditorModel: action.payload.currentEditorModel,
			}
		default:
			return state;
	}
}

