import { Button } from 'react-bootstrap'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import './ModelFeatures.css'
import {BiTrash} from 'react-icons/bi'
import { BsFillArrowDownSquareFill, BsFillArrowUpSquareFill, BsDownload,  } from 'react-icons/bs'
import { useEffect } from 'react'
import { uploadTextureImage,uploadModelColors } from '../../../../actions/Models'

const ModelFeatures = ( { model, hotspot, uploadTextureImage,uploadModelColors, updateThreedModels, assetUploadPercentage, currentModelDetail } ) =>
{
	const [ currentModel, setCurrentModel ] = useState( null )
	
	const fonts = [ 'Poppins', 'Montserrat', 'Lato', 'Helvetica', 'Oswald', 'Times New Roman', 'Arial Black', 'Tahoma', 'Verdana', 'Courier New Courier', 'Open Sans', 'Great Vibes', 'Roboto', 'Noto Sans Mono' ]
	const [ chosenFont, setChosenFont ] = useState( '' )
	const [ openCategory, setOpenCategory ] = useState( 'lighting' )
	const [ isHotspotError, setIsHotspotError ] = useState( false )
	const [ animations, setAnimations ] = useState( [] )
	
	const [colors, setColors] = useState([]);
	// Initialize a variable to hold the current selected color
	const [currentColor, setCurrentColor] = useState('#000000'); // Default to black
	
	// Function to handle adding a color to the list
	const addColorToList = () => {
		setColors([...colors, currentColor]);
		setCurrentColor('#000000'); // Reset the current color to black after adding
	};


	const removeColorFromList = (colorToRemove) => {
		const updatedColors = colors.filter((color) => color !== colorToRemove);
		setColors(updatedColors);
	  };
	
	
	
	const toggleCategory = ( category ) =>
	{
		setOpenCategory( category )
	}
	
	
	const environmentImages = {
		"House": "https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/Plain.hdr",
		"Lounge": "https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/lythwood_lounge_4k.hdr",
		"Forest": "https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/whipple_creek_regional_park_04_4k.hdr",
		"Sunrise": "https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/spruit_sunrise_4k.hdr"
	}
	
	const animationHandler = () =>
	{
		toggleCategory( 'animations' )
		const modelviewer = document.getElementById( 'model-viewer' ).firstChild
		
		setAnimations( modelviewer.availableAnimations )
		
	}
	
	const materialHandler = ( control, value ) =>
	{
		const modelViewer = document.getElementById( 'model-viewer' ).firstChild;
		
		let modelMaterial = modelViewer.model.materials[ 0 ];
		if ( control === 'roughness' )
		{
			modelMaterial.pbrMetallicRoughness.setRoughnessFactor( value );
			setCurrentModel( {
				...currentModel, Material: {
					...currentModel.Material,
					roughness: value,
				}
			} )
		} else if ( control === 'metalness' )
		{
			modelMaterial.pbrMetallicRoughness.setMetallicFactor( value );
			setCurrentModel( {
				...currentModel, Material: {
					...currentModel.Material,
					metalness: value,
				}
			} )
			
		}
	}
	
	const colorHandler = ( control, color ) =>
	{
		const modelViewer = document.getElementById( 'model-viewer' ).firstChild;
		
		
		let modelMaterial = modelViewer.model.materials[ 0 ];
		console.log( modelMaterial )
		const rgb = {
			r: parseInt( color.substr( 1, 2 ), 16 ) / 255,
			g: parseInt( color.substr( 3, 2 ), 16 ) / 255,
			b: parseInt( color.substr( 5, 2 ), 16 ) / 255
		}
		const value = [ rgb[ "r" ], rgb[ "g" ], rgb[ "b" ] ]
		if ( control === 'base' )
		{
			modelMaterial.pbrMetallicRoughness.setBaseColorFactor( value )
			setCurrentModel( {
				...currentModel, Material: {
					...currentModel.Material,
					baseColorFactor: rgb,
				}
			} )
		}
		else if ( control === 'emissive' )
		{
			modelMaterial.setEmissiveFactor( value )
			setCurrentModel( {
				...currentModel, Material: {
					...currentModel.Material,
					emissiveFactor: rgb,
				}
			} )
		}
		console.log( modelMaterial.pbrMetallicRoughness.baseColorFactor )
	}
	
	function hexToRgb ( hex )
	{
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec( hex );
		return result ? {
			r: parseInt( result[ 1 ], 16 ),
			g: parseInt( result[ 2 ], 16 ),
			b: parseInt( result[ 3 ], 16 )
		} : null;
	}
	
	
	const [ userColor ] = useState( '<select class="controls" ,="" id="color-controls"></select>' )
	
	const userColorHandler = ( color ) =>
	{
		console.log( document.getElementById( 'color-controls' ) )
		var colorControls = document.getElementById( 'color-controls' )
		console.log( colorControls )
		const rgbColor = hexToRgb( color )
		if ( colorControls === undefined || colorControls === null )
		{
			const modelViewer = document.getElementById( 'model-viewer' ).firstChild;
			document.getElementById( 'model-viewer' ).firstChild.innerHTML += userColor;
			let modelMaterial = modelViewer.model.materials[ 0 ];
			document.getElementById( 'color-controls' ).addEventListener( 'change', ( event ) =>
			{
				const colorString = event.target.value.split( "," ).map( ( numberString ) => parseFloat( numberString ) );
				modelMaterial.pbrMetallicRoughness.setBaseColorFactor( colorString )
			} )
		}
		document.getElementById( 'color-controls' ).innerHTML += `<option value="${rgbColor.r / 255},${rgbColor.g / 255},${rgbColor.b / 255},1">${color}</option>`
		
	}


	const saveColorHandler = async()=>{
		const modelViewer = document.getElementById( 'model-viewer' ).firstChild;
		const modelId = modelViewer.getAttribute('model-id')
		uploadModelColors(colors, modelId, async result =>
			{
				const { status, model } = result
				if ( status == true )
				{
					console.log( model )
					setColors([])
				}
			} )
	}
	
	const uploadTextureImageHandler = async ( event ) =>
	{
		
		const modelViewer = document.getElementById( 'model-viewer' ).firstChild;
		const modelId = modelViewer.getAttribute('model-id')
		uploadTextureImage( event.target.files, modelId, async result =>
			{
				const { status, model } = result
				if ( status == true )
				{
					console.log( model )
				}
			} )
		}
		
		useEffect( () =>
		{
			if ( model !== null )
			{
				setCurrentModel( model )
			}
		}, [ model ] )
		
		console.log(currentModelDetail)
		return (
			<div className='model-editor-sidebar'>{
				currentModel !== undefined && currentModel !== null && <div className='features'>
				<div className='category'>
				<h3 onClick={ () => toggleCategory( 'lighting' ) }>Lighting { openCategory === 'lighting' ? <BsFillArrowUpSquareFill /> : <BsFillArrowDownSquareFill /> }</h3>
				<br />
				{ openCategory === 'lighting' && <div className="features-list">
				
				<div>
				<label htmlFor="shadow-intensity">Shadow-Intensity</label>
				<input type="range" name="" id="" min="0" max="2" step="0.01" value={ currentModel.Lighting.shadowIntensity } onChange={
					
					( e ) =>
					{
						
						document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'shadow-intensity', e.target.value )
						setCurrentModel( {
							...currentModel, Lighting: {
								...currentModel.Lighting,
								shadowIntensity: e.target.value,
							}
						} )
					}
				} />
				</div>
				<div>
				<label htmlFor="shadow-softness">Shadow-Softness</label>
				<input type="range" name="" id="shadow-softness" min="0" max="2" step="0.01" value={ currentModel.Lighting.shadowSoftness }
				onChange={
					( e ) =>
					{
						
						document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'shadow-softness', e.target.value )
						setCurrentModel( {
							...currentModel, Lighting: {
								...currentModel.Lighting,
								shadowSoftness: e.target.value,
							}
						} )
					} } />
					</div>
					<div>
					<label htmlFor="exposure">Exposure</label>
					<input type="range" name="" id="" min="0" max="2" step="0.01" value={ currentModel.Lighting.exposure }
					onChange={ ( e ) =>
						{
							
							document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'exposure', e.target.value )
							setCurrentModel( {
								...currentModel, Lighting: {
									...currentModel.Lighting,
									exposure: e.target.value,
								}
							} )
						} } />
						</div>
						<div className='d-flex flex-row'>
						<label htmlFor="ar-mode">Google AR model</label>
						&nbsp;
						<input
						type="checkbox"
						id="ar-mode"
						onChange={ ( e ) =>
							{
								if ( e.target.checked )
								{
									document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'ar-modes', "scene-viewer webxr quick-look" )
								} else
								{
									document.getElementById( 'model-viewer' ).firstChild.removeAttribute( 'ar-modes' )
								}
							} }
							/>
							</div>
							<div>
							<label htmlFor="font">Font Family</label>
							<select
							name=""
							id=""
							value={ chosenFont }
							onChange={ ( e ) =>
								{
									var sCls = Array.from( document.getElementsByClassName( 'view-button' ) );
									if ( sCls.length > 0 )
									{
										setIsHotspotError( false )
										setChosenFont( e.target.value )
										console.log( e.target.value )
										console.log( sCls )
										for ( var hotspot in sCls )
										{
											console.log( sCls[ hotspot ] )
											sCls[ hotspot ].style.fontFamily = e.target.value;
										}
									} else
									{
										setIsHotspotError( true )
									}
								} }>
								{
									fonts.map( ( font, index ) => (
										<option key={ index } value={ font }>{ font }</option>
										) )
									}
									</select>
									{
										isHotspotError && <p>Create a hotspot!</p>
									}
									</div>
									
									</div> }
									</div>
									<div className='category'>
									<h3 onClick={ animationHandler }>Animations { openCategory === 'animations' ? <BsFillArrowUpSquareFill /> : <BsFillArrowDownSquareFill /> }</h3>
									<br />
									{ openCategory === 'animations' && <div className="features-list">
									<div>
									<select
									onChange={ ( e ) =>
										{
											document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'animation-name', e.target.value )
										} }
										>
										<option value="none">None</option>
										{
											animations.map( ( animation, index ) => (
												<option key={ index } value={ animation }>{ animation }</option>
												) )
											}
											</select>
											</div>
											</div> }
											</div>
											<div className='category'>
											<h3 onClick={ () => toggleCategory( 'environment' ) }>Environment { openCategory === 'environment' ? <BsFillArrowUpSquareFill /> : <BsFillArrowDownSquareFill /> }</h3>
											<br />
											{ openCategory === 'environment' && <div className="features-list">
											<div>
											<label>Environment Image</label>
											<select
											onChange={ ( e ) =>
												{
													if ( e.target.value === 'none' )
													{
														document.getElementById( 'model-viewer' ).firstChild.removeAttribute( 'environment-image' )
													} else
													{
														document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'environment-image', e.target.value )
													}
													
												} }
												>
												<option value="none">None</option>
												{
													Object.keys( environmentImages ).map( ( key, index ) => (
														<option key={ index } value={ environmentImages[ key ] }>{ key }</option>
														) )
													}
													</select>
													</div>
													<div>
													<label>Skybox Image</label>
													<select
													onChange={ ( e ) =>
														{
															if ( e.target.value === 'none' )
															{
																document.getElementById( 'model-viewer' ).firstChild.removeAttribute( 'skybox-image' )
															} else
															{
																document.getElementById( 'model-viewer' ).firstChild.setAttribute( 'skybox-image', e.target.value )
															}
														} }
														>
														<option value="none">None</option>
														{
															Object.keys( environmentImages ).map( ( key, index ) => (
																<option key={ index } value={ environmentImages[ key ] }>{ key }</option>
																) )
															}
															</select>
															</div>
															</div> }
															</div>
															<div className="category">
															<h3 onClick={ () => toggleCategory( 'material' ) }>Material { openCategory === 'material' ? <BsFillArrowUpSquareFill /> : <BsFillArrowDownSquareFill /> }</h3>
															{ openCategory === 'material' && <div className="features-list">
															<div>
															<p>Metalness: <span id="metalness-value"></span></p>
															<input
															id="metalness"
															type="range"
															min="0"
															max="1"
															step="0.01"
															value={ currentModel.Material[ "metalness" ] }
															onChange={ ( e ) =>
																{
																	materialHandler( "metalness", e.target.value )
																} }
																/>
																</div>
																<div>
																<p>Roughness: <span id="roughness-value"></span></p>
																<input id="roughness"
																type="range"
																min="0"
																max="1"
																step="0.01"
																value={ currentModel.Material[ "roughness" ] }
																onChange={ ( e ) =>
																	{
																		materialHandler( "roughness", e.target.value )
																	} }
																	/>
																	</div>
																	<div>
																	<p className='d-flex justify-content-between'>Base Color: <input type="color" name="" id="" onChange={ ( e ) => colorHandler( "base", e.target.value ) } /></p>
																	<p className='d-flex justify-content-between'>Emissive Factor: <input type="color" name="" id="" onChange={ ( e ) => colorHandler( "emissive", e.target.value ) } /></p>
																	</div>
																	</div> }
																	</div>
																	<div className="category">
																	<h3 onClick={ () => toggleCategory( 'texture' ) }>Texture { openCategory === 'texture' ? <BsFillArrowUpSquareFill /> : <BsFillArrowDownSquareFill /> }</h3>
																	{ openCategory === 'texture' && <div className="features-list">
																	<div className='texture-upload'>
																	<input type="file" id="actual-btn" multiple onChange={ ( event ) => uploadTextureImageHandler( event ) } hidden />
																	
																	<label htmlFor="actual-btn">Choose File</label>
																	<br />
																	<p>{ assetUploadPercentage != 0 && assetUploadPercentage !== 100 && `Uploaded ${assetUploadPercentage}%` }</p>
																	<div class="texture-box container text-center">
																	<div class="texture-box-row row">
																	{
																		currentModelDetail!==undefined&&currentModelDetail!==null&&currentModelDetail["Textures"]!==undefined&&Object.keys(currentModelDetail["Textures"])?.map((texture)=>{
																			return <div class="texture-box-col col-sm-6">
																			<img src={currentModelDetail["Textures"][texture]}/>
																			</div>
																		})
																	}
																	</div>
																	</div>
																	</div>
																	</div> }
																	</div>
																	<div className="category">
																	<h3 onClick={ () => toggleCategory( 'colors' ) }>Colors { openCategory === 'colors' ? <BsFillArrowUpSquareFill /> : <BsFillArrowDownSquareFill /> }</h3>
																	{ openCategory === 'colors' && <div className="colors-list">
																	<div className='color-input'>
																	<input
																	type="color"
																	value={currentColor}
																	onChange={(e) => {setCurrentColor(e.target.value)}}
																	/>
																	<button onClick={addColorToList}>Add Color</button>
																	</div>
																	<div className="row">
        {colors.length>0&&<h6>Selected Colors:</h6>}
        {colors.map((color, index) => (
          <div className="col-md-4" key={index}>
            <div
              className="color-box"
              style={{ height:"30px",width:"40px",backgroundColor: color, margin: "5px", position: "relative", border: "1px solid black" }}
            >
				<div style={{cursor: "pointer",position: "absolute", top: -10, right:-5, background:"white", padding: "2px", borderRadius: "50%"}}> <BiTrash onClick={() => removeColorFromList(color)} color="red" /></div>
			</div>
          </div>
        ))}
		{colors.length>0&&<button onClick={saveColorHandler}>Save Color</button>}
		
		<div className="row">
		{
																		currentModelDetail!==undefined&&currentModelDetail!==null&&currentModelDetail["Colors"]!==undefined&&currentModelDetail["Colors"]?.map((color,index)=>{
																			return <div className="col-md-4" key={index}>
																			<div
																			  className="color-box"
																			  style={{ height:"30px",width:"40px",backgroundColor: color, margin: "5px", position: "relative",border: "1px solid black" }}
																			>
																			</div>
																		  </div>
																		})
																	}</div>
      </div>
																		</div> }
																		</div>
																		{/* <div className='category'>
																		<h3 onClick={ () => toggleCategory( 'user-options' ) }>User Options { openCategory === 'user-options' ? <BsFillArrowUpSquareFill /> : <BsFillArrowDownSquareFill /> }</h3>
																		{ openCategory === 'user-options' && <div className="features-list">
																		<br />
																		<div>
																		<p className='d-flex justify-content-between'>Add Color: <input
																		type="color"
																		onMouseOutCapture={
																			( e ) => userColorHandler( e.target.value )
																		} /></p>
																		</div>
																		</div> }
																	</div> */}
																	<div className="actions" style={ { textAlign: "center" } }>
																	<Button
																	id='saveModelButton'
																	size="lg"
																	onClick={ () =>
																		{
																			document.getElementById( "saveModelButton" ).innerHTML = "Saving..."
																			const modelViewer = document.getElementById( 'model-viewer' );
																			console.log( modelViewer.innerHTML )
																			console.log( currentModel )
																			console.log( { ...currentModel, ModelViewer: modelViewer.innerHTML } )
																			setCurrentModel( { ...currentModel, ModelViewer: modelViewer.innerHTML } )
																			console.log( currentModel )
																			updateThreedModels( { ...currentModel, ModelViewer: modelViewer.innerHTML } )
																			setTimeout( () =>
																			{
																				document.getElementById( "saveModelButton" ).innerHTML = "Saved";
																				setTimeout( () =>
																				{
																					document.getElementById( "saveModelButton" ).innerHTML = "Save";
																				}, 1000 )
																			}, 1000 )
																		} }
																		>Save</Button>
																		</div>
																		</div >
																	}</div>
																	)
																}
																
																const mapStateToProps = ( state ) =>
																{
																	return {
																		hotspot: state?.modelEditor?.modelEditor?.Hotspot,
																		assetUploadPercentage: state?.modelEditor?.assetUploadPercentage,
																		currentModelDetail: state?.modelEditor?.currentEditorModel,
																	}
																}
																
																export default connect( mapStateToProps, { uploadTextureImage,uploadModelColors } )( ModelFeatures )
																
																