import modelImage from '../../../../../images/3dmodel.png';
import { useHistory } from 'react-router-dom';
import './RecentModels.css'

const RecentModels = ( { models } ) =>
{
  const history = useHistory()
  const modelClickHandler = ( model ) =>
  {
    history.push( {
      pathname: '/create-project',
      selectedModel: model
    } )
  }
  return (
    <div className='models-wrapper'>
      {
        models !== null && models.map( ( model, index ) =>
        {
          return <div className='product-wrapper' key={ index } onClick={ () => modelClickHandler( model ) }>
            <div className='product-card'>
              <img src={ modelImage } alt='Coat' />
              <h3>{ model[ "Model_Name" ] }</h3>
              <h5>{ model.Description.length > 30 ? model.Description.slice( 0, 30 ) + "..." : model.Description }</h5>
            </div>
          </div>
        } )
      }
    </div>
  );
};

export default RecentModels;
