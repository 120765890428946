import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './ProjectCard.css'

const ProjectCard = ( { id, projectId, projectName, thumbnail, description, userId, modelId, templateId, status, draft, onDelete, isITProject, itProject } ) =>
{
  const history = useHistory()

  const onEdit = () =>
  {
    history.push( `/editor/${id}` );
  };

  const onView = () =>
  {
    if ( isITProject === true )
      history.push( { pathname: `/image-tracking/${id}`, currentProject: itProject } )
    else
      history.push( `/viewproject/${id}` )
  }

  return (
    <div className='project-card-wrapper'>
      <h1>{ projectName }</h1>
      <div className='project-status' style={ { "display": "flex" } }>
        <div className='status'
          style={ { backgroundColor: status.toLowerCase() === 'active' ? "#65874f" : "#963f5d", color: status.toLowerCase() === 'active' ? "#fff" : "black" } }>{ status.toUpperCase() }</div>
        <div className='draft' style={ { backgroundColor: draft === true ? "#95B3D7" : "#0070C0" } }>{ draft === true ? "DRAFT" : "PUBLISHED" }</div>
      </div>
      <br />
      <p>{ description }</p>
      <div>

        { isITProject !== true && <button onClick={ () => onEdit() }>Edit</button> }

        { isITProject !== true && <button onClick={ () => onView() }>View</button> }

        { isITProject === true && <button onClick={ () => onView() }>View</button> }

        <button onClick={ () => onDelete( id ) }> Delete</button>
      </div>
    </div>
  );
};

export default ProjectCard;
