/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */

export default ( dc, config ) =>
{
    const defaultType = dc.getType( "default" );
    const {models} = config;
    const components = []
    models?.map((model)=>{
        if(model["Textures"]!==undefined&&model["Textures"]!==null){
            console.log(model["Textures"])
            Object.keys( model["Textures"]).map((textureKey)=>{
                components.push({ type: 'option', content: textureKey, attributes: { value: model["Textures"][textureKey] } })
            })
            
        }
    })
    
    dc.addType('option', {
        isComponent: el => el.tagName == 'OPTION',
        isTab1: true,
        
        model: {
            defaults: {
                tagName: 'option',
                layerable: false,
                droppable: false,
                draggable: false,
                highlightable: false,
            },
        },
    });
    
    dc.addType('texture-select', {        
        model: {
            defaults: {
                name: 'texture-select',
                tagName: 'select',
                droppable: false,
                highlightable: false,
                components: [],
                traits: [{
                    name: 'Model',
                    type: 'texture-select',
                    originalTab: 'Textures' // Add this custom attribute
                },],
                script:  function (){
                    // `this` is bound to the component element
                    console.log('the element', this);
                    this.addEventListener('change',  (event)=>{
                        const selectedValue = event.target.value
                        console.log(document)
                        const threedmodels = Array.from(document.getElementsByClassName( "3dmodel" ))
                        if ( threedmodels.length > 0 ){
                            console.log(threedmodels)
                            for ( var i=0;i<threedmodels.length;i++){
                                const threedmodel  = threedmodels[i]
                                console.log(threedmodel)
                                const modelMaterial = threedmodel?.model?.materials[ 0 ]
                                console.log(modelMaterial)
                                if ( modelMaterial !== undefined && modelMaterial !== null ){
                                    // Creates a new texture.
                                    threedmodel.createTexture( selectedValue ).then((texture)=>{
                                        // Applies the new texture to the specified channel.
                                        modelMaterial.pbrMetallicRoughness[ 'baseColorTexture' ].setTexture( texture );
                                    }).catch((error)=>{
                                        console.log(error)
                                    });
                                    
                                }
                            }
                        }
                    })
                }
            },
        },
    });
    ;
};
