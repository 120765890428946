export const GoogleFonts = [
	{
		"value": "'Acme', sans-serif",
		"name": "Acme",
	},
	{
		"value": "'Aladin', cursive",
		"name": "Aladin"
	},
	{
		"value": "'Alata', sans-serif",
		"name": "Alata"
	},
	{
		"value": "'Alegreya Sans', sans-serif",
		"name": "Alegreya Sans"
	},
	{
		"value": "'Alegreya', serif",
		"name": "Alegreya"
	},
	{
		"value": "'Alice', serif",
		"name": "Alice"
	},
	{
		"value": "'Allerta Stencil', sans-serif",
		"name": "Allerta Stencil"
	},
	{
		"value": "'Amatic SC', cursive",
		"name": "Amatic SC"
	},
	{
		"value": "'Anton', sans-serif",
		"name": "Anton"
	},
	{
		"value": "'Archivo Black', sans-serif",
		"name": "Archivo Black"
	},
	{
		"value": "'Archivo Narrow', sans-serif",
		"name": "Archivo Narrow"
	},
	{
		"value": "'Archivo', sans-serif",
		"name": "Archivo"
	},
	{
		"value": "Arial Black",
		"name": "Arial Black"
	},
	{
		"value": "'Arimo', sans-serif",
		"name": "Arimo"
	},
	{
		"value": "'Arvo', serif",
		"name": "Arvo"
	},
	{
		"value": "'Asap Condensed', sans-serif",
		"name": "Asap Condensed"
	},
	{
		"value": "'Audiowide', cursive",
		"name": "Audiowide"
	},
	{
		"value": "'Baskervville', serif",
		"name": "Baskervville"
	},
	{
		"value": "'Bebas Neue', cursive",
		"name": "Bebas Neue"
	},
	{
		"value": "'Bitter', serif",
		"name": "Bitter"
	},
	{
		"value": "'Blinker', sans-serif",
		"name": "Blinker"
	},
	{
		"value": "'Bowlby One SC', cursive",
		"name": "Bowlby One SC"
	},
	{
		"value": "'Cabin', sans-serif",
		"name": "Cabin"
	},
	{
		"value": "'Cambay', sans-serif",
		"name": "Cambay"
	},
	{
		"value": "'Caveat', cursive",
		"name": "Caveat"
	},
	{
		"value": "Century Gothic",
		"name": "Century Gothic"
	},
	{
		"value": "'Chivo', sans-serif",
		"name": "Chivo"
	},
	{
		"value": "'Comfortaa', cursive",
		"name": "Comfortaa"
	},
	{
		"value": "'Comic Neue', cursive",
		"name": "Comic Neue"
	},
	{
		"value": "Courier New Courier, monospace",
		"name": "Courier New Courier"
	},
	{
		"value": "'Courier Prime', monospace",
		"name": "Courier Prime"
	},
	{
		"value": "'Cousine', monospace",
		"name": "Cousine"
	},
	{
		"value": "'Crete Round', serif",
		"name": "Crete Round"
	},
	{
		"value": "'Cuprum', sans-serif",
		"name": "Cuprum"
	},
	{
		"value": "'Dancing Script', cursive",
		"name": "Dancing Script"
	},
	{
		"value": "'Dosis', sans-serif",
		"name": "Dosis"
	},
	{
		"value": "'EB Garamond', serif",
		"name": "EB Garamond"
	},
	{
		"value": "'Electrolize', sans-serif",
		"name": "Electrolize"
	},
	{
		"value": "'Exo', sans-serif",
		"name": "Exo"
	},
	{
		"value": "'Fauna One', serif",
		"name": "Fauna One"
	},
	{
		"value": "'Faustina', serif",
		"name": "Faustina"
	},
	{
		"value": "'Fjalla One', sans-serif",
		"name": "Fjalla One"
	},
	{
		"value": "'Francois One', sans-serif",
		"name": "Francois One"
	},
	{
		"value": "'Grand Hotel', cursive",
		"name": "Grand Hotel"
	},
	{
		"value": "'Grandstander', cursive",
		"name": "Grandstander"
	},
	{
		"value": "'Great Vibes', cursive",
		"name": "Great Vibes"
	},
	{
		"value": "'Gruppo', cursive",
		"name": "Gruppo"
	},
	{
		"value": "'Hanuman', serif",
		"name": "Hanuman"
	},
	{
		"value": "Helvetica Neue,Helvetica,Arial,sans-serif",
		"name": "elvetica"
	},
	{
		"value": "'Hind', sans-serif",
		"name": "Hind"
	},
	{
		"value": "'Homemade Apple', cursive",
		"name": "Homemade Apple"
	},
	{
		"value": "'Inconsolata', monospace",
		"name": "Inconsolata"
	},
	{
		"value": "'Indie Flower', cursive",
		"name": "Indie Flower"
	},
	{
		"value": "'Inter', sans-serif",
		"name": "Inter"
	},
	{
		"value": "'Josefin Sans', sans-serif",
		"name": "Josefin Sans"
	},
	{
		"value": "'Julee', cursive",
		"name": "Julee"
	},
	{
		"value": "'Kanit', sans-serif",
		"name": "Kanit"
	},
	{
		"value": "'Kite One', sans-serif",
		"name": "Kite One"
	},
	{
		"value": "'Klee One', cursive",
		"name": "Klee One"
	},
	{
		"value": "'Lato', sans-serif",
		"name": "Lato"
	},
	{
		"value": "'Lexend Deca', sans-serif",
		"name": "Lexend Deca"
	},
	{
		"value": "'Lexend', sans-serif",
		"name": "Lexend"
	},
	{
		"value": "'Libre Franklin', sans-serif",
		"name": "Libre Franklin"
	},
	{
		"value": "'Lobster', cursive",
		"name": "Lobster"
	},
	{
		"value": "'Lora', serif",
		"name": "Lora"
	},
	{
		"value": "'Manrope', sans-serif",
		"name": "Manrope"
	},
	{
		"value": "'Manuale', serif",
		"name": "Manuale"
	},
	{
		"value": "'Martel Sans', sans-serif",
		"name": "Martel Sans"
	},
	{
		"value": "'Marvel', sans-serif",
		"name": "Marvel"
	},
	{
		"value": "'Maven Pro', sans-serif",
		"name": "Maven Pro"
	},
	{
		"value": "'Merriweather', serif",
		"name": "Merriweather"
	},
	{
		"value": "'Monda', sans-serif",
		"name": "Monda"
	},
	{
		"value": "'Monoton', cursive",
		"name": "Monoton"
	},
	{
		"value": "'Montserrat', sans-serif",
		"name": "Montserrat"
	},
	{
		"value": "'Mukta Vaani', sans-serif",
		"name": "Mukta Vaani"
	},
	{
		"value": "'Mukta', sans-serif",
		"name": "Mukta"
	},
	{
		"value": "'Niramit', sans-serif",
		"name": "Niramit"
	},
	{
		"value": "'Nothing You Could Do', cursive",
		"name": "Nothing You Could Do"
	},
	{
		"value": "'Noto Sans Mono', monospace",
		"name": "Noto Sans Mono"
	},
	{
		"value": "'Noto Sans', sans-serif",
		"name": "Noto Sans"
	},
	{
		"value": "'Noto Serif', serif",
		"name": "Noto Serif"
	},
	{
		"value": "'Nunito', sans-serif",
		"name": "Nunito"
	},
	{
		"value": "'Open Sans', sans-serif",
		"name": "Open Sans"
	},
	{
		"value": "'Oswald', sans-serif",
		"name": "Oswald"
	},
	{
		"value": "'Outfit', sans-serif",
		"name": "Outfit"
	},
	{
		"value": "'Oxygen', sans-serif",
		"name": "Oxygen"
	},
	{
		"value": "'Pacifico', cursive",
		"name": "Pacifico"
	},
	{
		"value": "'Passion One', cursive",
		"name": "Passion One"
	},
	{
		"value": "'Pathway Gothic One', sans-serif",
		"name": "Pathway Gothic One"
	},
	{
		"value": "'Philosopher', sans-serif",
		"name": "Philosopher"
	},
	{
		"value": "'Play', sans-serif",
		"name": "Play"
	},
	{
		"value": "'Playfair Display', serif",
		"name": "Playfair Display"
	},
	{
		"value": "'Poppins', sans-serif",
		"name": "Poppins"
	},
	{
		"value": "'Prompt', sans-serif",
		"name": "Prompt"
	},
	{
		"value": "'PT Sans Caption', sans-serif",
		"name": "PT Sans Caption"
	},
	{
		"value": "'PT Sans Narrow', sans-serif",
		"name": "PT Sans Narrow"
	},
	{
		"value": "'PT Sans', sans-serif",
		"name": "PT Sans"
	},
	{
		"value": "'PT Serif', serif",
		"name": "PT Serif"
	},
	{
		"value": "'Quantico', sans-serif",
		"name": "Quantico"
	},
	{
		"value": "'Raleway', sans-serif",
		"name": "Raleway"
	},
	{
		"value": "'Red Hat Text', sans-serif",
		"name": "Red Hat Text"
	},
	{
		"value": "'Roboto Condensed', sans-serif",
		"name": "Roboto Condensed"
	},
	{
		"value": "'Roboto Mono', monospace",
		"name": "Roboto Mono"
	},
	{
		"value": "'Roboto Serif', serif",
		"name": "Roboto Serif"
	},
	{
		"value": "'Roboto Slab', serif",
		"name": "Roboto Slab"
	},
	{
		"value": "'Roboto', sans-serif",
		"name": "Roboto"
	},
	{
		"value": "'Ropa Sans', sans-serif",
		"name": "Ropa Sans"
	},
	{
		"value": "'Rubik', sans-serif",
		"name": "Rubik"
	},
	{
		"value": "'Saira Condensed', sans-serif",
		"name": "Saira Condensed"
	},
	{
		"value": "'Saira Extra Condensed', sans-serif",
		"name": "Saira Extra Condensed"
	},
	{
		"value": "'Saira Semi Condensed', sans-serif",
		"name": "Saira Semi Condensed"
	},
	{
		"value": "'Saira', sans-serif",
		"name": "Saira"
	},
	{
		"value": "'Sedgwick Ave Display', cursive",
		"name": "Sedgwick Ave Display"
	},
	{
		"value": "'Sedgwick Ave', cursive",
		"name": "Sedgwick Ave"
	},
	{
		"value": "'Sintony', sans-serif",
		"name": "Sintony"
	},
	{
		"value": "'Slabo 27px', serif",
		"name": "Slabo 27px"
	},
	{
		"value": "'Sora', sans-serif",
		"name": "Sora"
	},
	{
		"value": "'Source Code Pro', monospace",
		"name": "Source Code Pro"
	},
	{
		"value": "'Source Sans Pro', sans-serif",
		"name": "Source Sans Pro"
	},
	{
		"value": "'Spectral', serif",
		"name": "Spectral"
	},
	{
		"value": "'Spinnaker', sans-serif",
		"name": "Spinnaker"
	},
	{
		"value": "'Titan One', cursive",
		"name": "Titan One"
	},
	{
		"value": "Times New Roman",
		"name": "imes New Roman"
	},
	{
		"value": "'Titillium Web', sans-serif",
		"name": "Titillium Web"
	},
	{
		"value": "'Ubuntu', sans-serif",
		"name": "Ubuntu"
	},
	{
		"value": "'Urbanist', sans-serif",
		"name": "Urbanist"
	},
	{
		"value": "'Varela Round', sans-serif",
		"name": "Varela Round"
	},
	{
		"value": "Verdana, Geneva, sans-serif",
		"name": "erdana"
	},
	{
		"value": "'Volkhov', serif",
		"name": "Volkhov"
	},
	{
		"value": "'Vollkorn', serif",
		"name": "Vollkorn"
	},
	{
		"value": "'Work Sans', sans-serif",
		"name": "Work Sans"
	},
	{
		"value": "'Zilla Slab Highlight', cursive",
		"name": "Zilla Slab Highlight"
	},
	{
		"value": "'Zilla Slab', serif",
		"name": "Zilla Slab"
	}
]







