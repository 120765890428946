import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import "./CreateITPage.css"
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { getAllModelsByUser } from '../../../actions/Models';
import TourStep from '../../Components/Tour/TourStep';
import { createProjectFunction } from '../../../actions/Projects';
import { createITProject } from '../../../actions/ITProject';
import Spinner from '../../../Utilities/Spinner/Spinner';
import { useMemo } from 'react';
import Swal from 'sweetalert2';
import { BiLinkAlt } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';

const CreateITProject = ( { user, getAllModelsByUser, createITProject, models, tour, projectsLoader } ) =>
{
	const [ modelsData, setModelData ] = useState( [] )
	const history = useHistory();
	const location = useLocation()
	const steps = [
		{
			element: "#create-it-project-name",
			intro: "Name your project"
		},
		{
			element: "#create-it-project-description",
			intro: "Describe your project"
		},
		{
			element: "#create-it-project-model",
			intro: "You can choose your models or go for the one we created just for you!"
		},
		{
			element: "#create-it-project-upload",
			intro: "You can even upload your own model here!"
		},
	]

	const socialMedialImages = {
		Linkedin: "https://xarwin-assests-spaces-prod.fra1.digitaloceanspaces.com/Xarwin/linkedin.png",
			Instagram: "https://xarwin-assests-spaces-prod.fra1.digitaloceanspaces.com/Xarwin/instagram.png",
			Facebook: "https://xarwin-assests-spaces-prod.fra1.digitaloceanspaces.com/Xarwin/facebook.png",
			Youtube: "https://xarwin-assests-spaces-prod.fra1.digitaloceanspaces.com/Xarwin/youtube.png",
			Whatsapp: "https://xarwin-assests-spaces-prod.fra1.digitaloceanspaces.com/Xarwin/whatsapp.png",
			Website: "https://xarwin-assests-spaces-prod.fra1.digitaloceanspaces.com/Xarwin/website.png",
	}

	const fetchData = useMemo( () =>
	{
		return async () =>
		{
			await getAllModelsByUser();
			setModelData( models )
		};
	}, [] );

	useEffect( () =>
	{
		fetchData()
	}, [ fetchData ] )

	const [addSocialMediaLinks,setAddSocialMediaLinks] = useState(false)
	const [ projectData, setProjectData ] = useState( {
		User_Id: user.uid,
		Project_Name: "",
		Description: "",
		Type: "Video",
		Tracker: null,
		Video: null,
		SocialMediaLinks: {
			Linkedin: null,
			Instagram: null,
			Facebook: null,
			Youtube: null,
			Whatsapp: null,
			Website: null,
		}
	} );

	const socialMediaChangeHandler = (mediaLink,socialLinkKey)=>{
		const temp = projectData["SocialMediaLinks"]
		temp[socialLinkKey]  = mediaLink === ""?null:mediaLink
		setProjectData({...projectData,SocialMediaLinks:temp})
	}

	const onChange = ( value ) =>
	{
		setProjectData( ( prev ) =>
		{
			return { ...prev, ...value };
		} );
	};

	const handleSubmit = ( e ) =>
	{
		e.preventDefault();
		console.log( projectData )
		if ( projectData[ "Project_Name" ] === '' || projectData[ "Description" ] === '' || projectData[ "Tracker" ] === null || ( projectData[ "Type" ] === 'Video' && projectData[ "Video" ] === null ) || ( projectData[ "Type" ] === 'Model' && projectData[ "Model_Id" ] === null ) )
		{
			Swal.fire( "Please Fill all Fields!" )
		} else
		{
			createITProject( projectData, history )
		}

	};

	const uploadModel = () =>
	{
		history.push( '/add-model' )
	}


	return (
		<div>
			{ tour != "false" && <TourStep steps={ steps } tourReducer={ tour } /> }
			<form className='create-it-project-container'>
				<div className="create-it-project-form">
					<div id="create-it-project-name" className=" align-items-center">
						<label className='col-md-4'>Enter a name for your Project*</label>
						<input
							className="col-sm-12 col-md-8 input-field"
							onChange={ ( e ) => onChange( { Project_Name: e.target.value } ) }
							type="text"
							placeholder='Ex: Wooden Chair'
							required={ true } />
					</div>
					<div id="create-it-project-description" className=" align-items-center">
						<label className='col-md-4'>How do you describe your Project?*</label>
						<input
							className="col-sm-12 col-md-8 input-field"
							onChange={ ( e ) => onChange( { Description: e.target.value } ) }
							type="text"
							placeholder='Furniture made with teak wood for living room' required={ true } />
					</div>
					<div id="create-it-tracker-choose" className="file_box row align-items-center">
						<label className="col-md-4">Add Your Tracker Image</label>
						<div className="col-md-8">
							<input type="file" name="" id="image" onChange={ ( e ) => onChange( { Tracker: e.target.files[ 0 ] } ) } accept="image/png, image/jpeg" />
						</div>
					</div>
					<div id="create-model-choose" className="row align-items-center">
						<label className='col-md-4'>What would you like to display?*</label>
						<div className="col-md-8">
							<input
								type="checkbox"
								checked={ projectData[ "Type" ] === "Video" ? true : false }
								onChange={ () =>
								{
									if ( projectData[ "Type" ] === "Model" )
									{
										onChange( { Type: "Video" } )
									}
								} } /> Video &nbsp; &nbsp; &nbsp;
							<input
								type="checkbox"
								checked={ projectData[ "Type" ] === "Model" ? true : false }
								onChange={ () =>
								{
									if ( projectData[ "Type" ] === "Video" )
									{
										onChange( { Type: "Model" } )
									}
								} } /> Model &nbsp; &nbsp; &nbsp;

						</div>
					</div>
					{ projectData[ "Type" ] === "Model" && <div id="create-it-model-choose" className="row align-items-center">
						<label className='col-md-4'>Display a Model</label>
						<div className="col-md-8 model_box">
							<select
								className="input-field"
								type="select"
								id="model"
								value={ projectData.model }
								onChange={ ( e ) => onChange( { Model_Id: e.target.value } ) }
							>
								<option>--select an option--</option>
								{
									modelsData.map( ( model ) => (

										<option
											key={ model._id }
											value={ model._id }
											id={ model._id }
										>{ model.Model_Name }</option>

									) )
								}
							</select>
							<label htmlFor="model"> Or </label><br />
							<button id="create-it-project-upload" onClick={ () => uploadModel() }>Upload Model</button>
						</div>
					</div> }
					{ projectData[ "Type" ] === "Video" && <div id="create-it-tracker-choose" className="file_box row align-items-center">
						<label className="col-md-4">Add Your Tracker Display Video</label>
						<div className="col-md-8">
							<input type="file" name="" id="image" onChange={ ( e ) => onChange( { Video: e.target.files[ 0 ] } ) } accept="video/mp4" />
						</div>
					</div> }
					<br />
					<div id="create-model-choose" className="row align-items-center">
						<label className='col-md-4'>Want To Add Your Social Media Links?</label>
						<div className="col-md-8">
							<input
								type="checkbox"
								checked={ addSocialMediaLinks===true?true:false }
								onChange={ () =>
								{
									setAddSocialMediaLinks(true)
								} } /> Yes &nbsp; &nbsp; &nbsp;
							<input
								type="checkbox"
								checked={ addSocialMediaLinks===true?false:true }
								onChange={ () =>
								{
									setAddSocialMediaLinks(false)
								} } /> No &nbsp; &nbsp; &nbsp;

						</div>
					</div>			
					{addSocialMediaLinks&&<div className='social-media mt-3'>
						<div className='row'>
							<div className='col-md-4 col-xs-12'>
								<label>Now, let's add your social media accounts to your page.</label>
								<div className='social-media-box mt-3'>
									{
										Object.keys(socialMedialImages).map((socialMediaKey)=>{
											return <div className='d-flex align-items-center mb-3'>
											<div className="social-box-input">
												<div className="input-group social-box-input-group">
													<div className="input-group-prepend">
													<span className="input-group-text" id="inputGroupPrepend3"><img src={socialMedialImages[socialMediaKey]} /></span>
													</div>
													<input 
													type="text" 
													className="form-control"
													 placeholder={socialMediaKey==="Whatsapp"?"Whatsapp Number":socialMediaKey.toUpperCase()+" Link"} 
													value={projectData["SocialMediaLinks"][socialMediaKey]===null?"":projectData["SocialMediaLinks"][socialMediaKey]}
													aria-describedby="inputGroupPrepend3" 
													required 
													onChangeCapture={(e)=>socialMediaChangeHandler(e.target.value,socialMediaKey)}/>
												</div>
											</div>
										</div>
										})
									}
								</div>
							</div>
							<div className='col-md-8 col-xs-12 social-media-input-data'>
									<div className='row social-media-input-data-box'>
										{
											Object.keys(projectData["SocialMediaLinks"]).map((socialLinkKey)=>{
												if(projectData["SocialMediaLinks"][socialLinkKey]!==null){
													return <div className='col-md-5 col-xs-12 m-2'>
														<div className='d-flex align-items-center justify-content-between'>
															<div className='d-flex align-items-center'>
																<img src={socialMedialImages[socialLinkKey]} />
																<h4>{socialLinkKey.toLocaleUpperCase()}</h4>
															</div>
															<IoClose style={{cursor: "pointer"}} color='red' size={15} onClick={()=>socialMediaChangeHandler("",socialLinkKey)}/>
														</div>
														<br />
														<p className='text-wrap'>{projectData["SocialMediaLinks"][socialLinkKey]}</p>
													</div>
												}
											})
										}
									</div>
							</div>
						</div>
					</div>}

					{ projectsLoader === false ? <div className="actions">
						<button
							type="submit"
							className='createButton'
							onClick={ handleSubmit }
						>Create Project</button>
					</div> : <div style={ {
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					} }>
						<Spinner />
						{/* <p>Uploading { uploadModelPercentage }</p> */ }
					</div> }
				</div>
			</form>
		</div>
	);
};

const mapStateToProps = ( state, ownProps ) =>
{
	return {
		models: state?.model?.models,
		tour: state?.tour?.tour,
		projectsLoader: state?.itProject?.itProjectsLoader,
		user: state?.user?.userData,
		templates: state?.project?.templates,
	}
}

export default connect( mapStateToProps, { getAllModelsByUser, createProjectFunction, createITProject } )( ( withRouter( CreateITProject ) ) );

