import { useHistory } from "react-router-dom";
import { toggleSidebar } from "../../../Utilities/Editor/geditor_utils";
import './TopNav.css'
import Spinner from '../../../Utilities/Spinner/Spinner'
import { connect } from "react-redux";
const TopNav = ( { loader } ) =>
{
	const handleClick = () =>
	{
		toggleSidebar( false );
	};
	const history = useHistory();

	return (
		<nav className="topnav">
			{ loader === false ? <div className="container">
				<div className="actions panel__editor"></div>
			</div> : <Spinner /> }
			<div className="container">
				<div className="actions panel__basic-actions"></div>
				<div className="actions panel__template-actions"></div>
			</div>
		</nav>
	);
}

const mapStateToProps = ( state ) =>
{
	// console.log( state )
	console.log( document.getElementById( 'model-viewer' ) )
	return {
		loader: state?.modelEditor?.editorLoader,
	}
}

export default connect( mapStateToProps )( TopNav )

