export default ( editor, opts = {} ) =>
{
    const trm = editor.TraitManager;
    console.log(opts)
    const {models} = opts
    
    trm.addType('texture-select', {
        events:{
            keyup: 'onChange',
        },
        
        onValueChange() {
            const { model, target } = this;
            console.log(model)
            console.log(target)
            console.log(target.components().filter((component)=>{
                return component.components().filter((comp)=>  comp)
            }))
            const optionsStr = model.get('value').trim();
            console.log(optionsStr)
            if(optionsStr!=="None"){
                const optComps = [{
                    type: 'option',
                    content: '----------------',
                    attributes: { value: 'None' },
                }];
                const selectedModel = models.find(model=>model._id===optionsStr)
                Object.keys(selectedModel["Textures"]).map((textureKey)=>{
                    optComps.push({
                        type: 'option',
                        content: textureKey,
                        attributes: { value: selectedModel["Textures"][textureKey] },
                    });
                })
                target.components().reset(optComps);
            }
        },
        
        getInputEl() {
            if (!this.$input) {
                const optionsArr = [{value: 'None',label: "---------"}];
                const options = this.target.components();
                
                for (let i = 0; i < models?.length; i++) {
                    if(models[i]["Textures"]!==undefined&&models[i]["Textures"]!==null){
                        optionsArr.push({value: models[i]["_id"],label: models[i]["Model_Name"]});
                    }
                }
                
                const el = document.createElement('select');
                // Loop through the options and add them to the select element
                optionsArr.forEach((option) => {
                    const optionElement = document.createElement('option');
                    optionElement.value = option.value;
                    optionElement.text = option.label;
                    el.appendChild(optionElement);
                });
                this.$input = el;
            }
            
            return this.$input;
        },
    });
    
    trm.addType('color-select', {
        events:{
            keyup: 'onChange',
        },
        
        onValueChange() {
            const { model, target } = this;
            console.log(model)
            console.log(target)
            console.log(target.components().filter((component)=>{
                return component.components().filter((comp)=>  comp)
            }))
            const optionsStr = model.get('value').trim();
            console.log(optionsStr)
            if(optionsStr!=="None"){
                const optComps = [{
                    type: 'option',
                    content: '----------------',
                    attributes: { value: 'None' },
                }];
                const selectedModel = models.find(model=>model._id===optionsStr)
                selectedModel["Colors"]?.map((colorKey)=>{
                    optComps.push({
                        type: 'option',
                        type: 'option', content: colorKey,
                        attributes: { value: colorKey, style: `background-color: ${colorKey};color: ${colorKey}` },
                    });
                })
                target.components().reset(optComps);
            }
        },
        
        getInputEl() {
            if (!this.$input) {
                const optionsArr = [{value: 'None',label: "---------"}];
                const options = this.target.components();
                
                for (let i = 0; i < models?.length; i++) {
                    if(models[i]["Textures"]!==undefined&&models[i]["Textures"]!==null){
                        optionsArr.push({value: models[i]["_id"],label: models[i]["Model_Name"]});
                    }
                }
                
                const el = document.createElement('select');
                // Loop through the options and add them to the select element
                optionsArr.forEach((option) => {
                    const optionElement = document.createElement('option');
                    optionElement.value = option.value;
                    optionElement.text = option.label;
                    el.appendChild(optionElement);
                });
                this.$input = el;
            }
            
            return this.$input;
        },
    });
};
