import axios from 'axios';
import config from './config';

export const getAllProjectsAnalytics = () => async ( dispatch, getState ) =>
{
	dispatch( { type: "DASHBOARD_LOADER" } )
	const user = getState()?.user?.userData;
	console.log( user )
	console.log( user.stsTokenManager.accessToken )
	// const token = await user?.getIdToken()
	axios.get( `${config.serverUrl}/users/analytics/projects/${user.uid}`, {
		headers: {
			'Authorization': "Bearer " + user.stsTokenManager.accessToken,
		}
	} ).then( ( res ) =>
	{
		console.log( res )
		const analytics = res.data.analytics
		dispatch( { type: "DASHBOARD_UPDATE", payload: { analytics: analytics } } )
	} )
		.catch( ( err ) =>
		{
			console.log( err )
			dispatch( { type: "DASHBOARD_LOADER" } )
		} )
}
