import React from 'react'
import './ChooseSubscription.css'
import AllPlans from '../../SubscriptionPlans/AllPlans/Plans'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ChooseSubscription = () =>
{
	const history = useHistory()
	const proceedHandler = () =>
	{
		history.push( "/dashboard" )
	}
	return (
		<div className='choose-subscription'>
			<div className="all-subscriptions">
				<AllPlans />
				<div className="actions">
					<p>You can even change your subscriptions later!</p>
					<button className="proceed" onClick={ proceedHandler }>Proceed with current subscription</button>
				</div>
			</div>
		</div>
	)
}

export default ChooseSubscription