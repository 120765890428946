import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Verify from '../Dashboard/pages/Auth/Verification/Verify';

const PrivateRoute = ( { component: Component, user, ...rest } ) =>
{

    return <Route { ...rest } render={
        props =>
        {
            if ( user !== null && user.email !== null && user.email !== undefined && user.emailVerified === true )
            {
                return <Component />
            } else if ( user !== null && user.email !== null && user.email !== undefined && user.emailVerified === false )
            {
                return <Verify />
            } else
            {
                return <Redirect to={ '/login' } />
            }
        }
    } />

}


const MapStateToProps = ( state ) =>
{
    return {
        user: state?.user?.userData
    };
};



export default connect( MapStateToProps, null )( PrivateRoute );